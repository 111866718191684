import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { Container } from "../../common/Grid";

export const HeroContainer = styled.div`
  position: relative;

  .slick-dots {
    bottom: 8px !important;
  }

  > div > div {
    border: none;

    img {
      max-height: 580px;
      filter: grayscale(6%) brightness(96.4%);
    }
  }

  .slick-next {
    display: none !important;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 580px;
    margin: 0 0 21px 0;
    .slick-dots {
      bottom: 40px !important;
    }
  }
`;

export const HeroCallToAction = styled.div`

  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;

  * {
    color: ${({ theme }) => theme.palette.white };
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 100px;

    h1 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 30px;
      line-height: 1.2;
      text-transform: none;
      font-weight: 500;

      @media (${BREAKPOINTS_DM.silver_768}) {
        font-size: 63px;
      }
    }

    h3 {
      margin: 5px 0 10px 0;
      P {
        margin: 0;
      }
    }

    p {
      margin-bottom: 20px;
    }
    a {
      text-shadow: none;
      text-transform: none;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: .3px;
      padding: 6px 30px;
      border-radius: 17px;
      line-height: 1;
      white-space: normal;
      border: 2px solid #fff;
      color: #fff;

      :hover {
        color: #adadad;
        background: #fff;
      }
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    bottom: 0;
    top: unset;
  }
`;

export const PromotionalOfferContainer = styled(Container)`
  flex-direction: column;
  padding: 40px 15px;
  margin: 50px auto 40px;

  > div {
    > h1 {
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color: #222;;
    }
  }

  > a {
    align-self: center;
    margin-top: 25px;
    display: inline-block;
    padding: 6px 30px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: .3px;
    color: #adadad;
    line-height: 1;

    border-radius: 18px;
    border: 2px solid #adadad;
    cursor: pointer;
    text-shadow: none;

    &:hover {
      background-color: #adadad;
      color: white;
    }
  }
  
`;

export const RichLinkTopParallaxWrapper: any = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 60px 0;

  * {
    color: ${({ theme }) => theme.palette.white };
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  h3 {
    b {
      font-size: 18px;
      font-weight: 800;
    }
  }

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('${({ src }) => src}');
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(70%) grayscale(0.2);
    @media (${BREAKPOINTS_DM.silver_768}) {
      background-position: center calc(var(--background-paralax) * 0.6 + 50px);
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      background-position: center calc(var(--background-paralax) * 0.8 + 20px);
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      background-position: center var(--background-paralax);
    }
  }
`;

export const RichLinkBottomParallaxWrapper: any = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 60px 0;

  * {
    color: ${({ theme }) => theme.palette.white };
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  h3 {
    b {
      font-size: 18px;
      font-weight: 800;
    }
  }

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('${({ src }) => src}');
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(70%) grayscale(0.2);
    @media (${BREAKPOINTS_DM.silver_768}) {
      background-position: center calc(var(--background-paralax) * 0.5 + 150px);
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      background-position: center calc(var(--background-paralax) * 0.55 + 70px);
    }
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      background-position: center var(--background-paralax);
    }
  }
`;

export const RichLinkContainer = styled(Container)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 60px 15px;
  color: ${({ theme }) => theme.palette.white };
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  font-size: 13px;

  > div {
    width: 100%;
    padding: 0 15px;
    text-align: center;

    > h3 {
      font-size: 18px;

    }

    > div {
      padding: 10px 0 30px 0;
    }

    > a {
      display: inline-block;
      padding: 10px 30px;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: .3px;
      color: #fff;
      line-height: 1;
      background-color: #adadad;
      border-radius: 5px;
      border: 2px solid #adadad;
      cursor: pointer;
      text-shadow: none;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      width: calc(100% / 12 * 6);
    }
  }
`;

export const NewsContainer = styled(Container)`
  flex-direction: column;
  padding: 40px 15px;
  margin: 25px auto 40px;
  
  > div {
    flex: 1;
    > h1 {
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color: #222;;
    }
  }

  > h2 {
    padding: 20px 15px;
    text-align: center;
    font-size: 24px;
    color: #222;
  }

  > a {
    align-self: center;
    margin-top: 25px;
    display: inline-block;
    padding: 6px 30px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: .3px;
    color: #adadad;
    line-height: 1;

    border-radius: 18px;
    border: 2px solid #adadad;
    cursor: pointer;
    text-shadow: none;

    &:hover {
      background-color: #adadad;
      color: white;
    }
  }
`;

export const FurtherAwardsContainer = styled.div`
`;

export const TextELement: any = styled.div`
  
  h3 {
    color: rgb(34, 34, 34);
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 33.6px;
    margin: 5px 0 10px;
    text-align: center;
  }
`;

export const RichTextWrapper: any = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 0 auto 50px;
  text-align: center;
  padding: 0 15px 30px;
`;
