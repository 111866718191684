import { Context, getIfNewsShouldBeDisplayByDate } from "src/common";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { Fragment, FunctionComponent, useContext } from "react";
import SlickSlider, { Settings } from "react-slick";

import SliderItem from "./SliderItem/SliderItem";
import { SNewsSlider } from "./styles/SNewsSlider";
import SSlickBase from "./styles/SSlickBase";
import SSlickTheme from "./styles/SSlickTheme";

interface IProps {
    newsCustom?: boolean;
}

const NewsSlider: FunctionComponent<IProps> = ({ newsCustom }) => {
    const p = useContext(Context);
    const newsSlug = p?.NewsSlug;

    const currentNews = newsCustom ? p?.PageLanding?.NewsCustom : p?.PageLanding?.News;

    const news = currentNews?.filter((n) =>
        getIfNewsShouldBeDisplayByDate({
            startDate: n?.startDate,
            endDate: n?.endDate,
        })
    );

    if (!news || news?.length <= 0) {
        return null;
    }

    const sliderSettings: Settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
        prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    };

    return (
        <SNewsSlider className={`news-slider ${newsCustom ? "custom" : "root"}`}>
            <div
                css={`
                    ${SSlickBase} ${SSlickTheme}
                `}
            >
                <SlickSlider {...sliderSettings}>
                    {news.map((newsItem, i) => (
                        <Fragment key={`SlickSlider${i}`}>
                            <Link href={`/${newsSlug}/${newsItem?.id}`}>
                                <a>
                                    <SliderItem {...newsItem} />
                                </a>
                            </Link>
                        </Fragment>
                    ))}
                </SlickSlider>
            </div>
        </SNewsSlider>
    );
};

export default NewsSlider;
