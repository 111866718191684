import styled,{ css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../../constants/colors";

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  border-radius: 16px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 92%;
  }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    aling-items: center;
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    height: 180px;
    > a {
      text-align: left;
      color: #5E5E5D;;
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      &:hover {
        text-decoration: underline;
      }
    }
    h1 {
      font-size: 25px;
      line-height: 34px;
      color: #F19024;
    }
    h2 {
      line-height: 28px;
      font-size: 14px;
      font-weight: 400;
    }
`;


export const ImgWrapper: any = styled.div`
  width: 100%;
  height: 237px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    img {
      height: 237px;
    }
  }
`;


export const RichTextWrapper: any = styled.div`
  p {
    font-size: 14px;
    color: #5E5E5D;
    line-height: 28px;
    font-weight: 100;
  }

  .c-empty {
    height: 0px;
  }

  h1,h2,h3,h4 {
    color: #5E5E5D;
  }
`;