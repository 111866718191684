import React, { FunctionComponent, useContext } from "react";
import SlickSlider from "react-slick";
import { IAsset, Context } from "src/common";

import Img from "../../../BaseComponents/Img";
import colors from "../../../constants/colors";
import PromotialOffertSlider from "../../common/PromotionalOfferSlider";
import { CarouselContent, TextContent, ButtonsContainer, CarouselButton, ButtonContent, Wrapper } from "./Slider.style";
export interface IsContent
{
  carouselContent: object;
}

const Content: any = ({ content, onSetShowModal }) => (
  <CarouselContent>
    <TextContent>
      {content.headline}
    </TextContent>
    <ButtonsContainer>
      <CarouselButton textColor={colors.green} bgColor={colors.white}>
        <ButtonContent onClick={() => onSetShowModal(true)}>
          <a>
            Beratungstermin vereinbaren
          </a>
        </ButtonContent>
      </CarouselButton>
      <CarouselButton textColor={colors.white} bgColor={colors.green}>
        <ButtonContent>
          <a href={"/" + content.linkTo?.[0]?.slug}>
            Ausstellungsverkauf
          </a>
        </ButtonContent>
      </CarouselButton>
    </ButtonsContainer>
  </CarouselContent>
);

export interface ISlider
{
  elements: IAsset[];
  items: IAsset[];
  onSetShowModal?: (boolean) => void;
}

const Slider: FunctionComponent<ISlider> = props =>

{
  const promotionalOffert = useContext(Context).PageLanding.promotionalOffer;

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    speed: 700,
    arrows: false,
  };
  // @ts-ignore
  const content = props?.items.find(item => item?.__typename === "RichLinkItem");
  return (
    <>
      <Wrapper>
        <SlickSlider {...sliderSettings}>
          {props?.elements.map((element, i) => (
            <Img quality={1600} key={i} src={[{ src: element?.src }]} alt={element?.description ?? "image"}/>
          ))}
        </SlickSlider>
        {content &&
        <Content content={content} onSetShowModal={props.onSetShowModal && props.onSetShowModal}/>
        }
      </Wrapper>

      {promotionalOffert && (
        <PromotialOffertSlider items={promotionalOffert}/>
      )}
    </>
  );
};

export default Slider;
