import styled from "styled-components";

import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container: any = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-bottom: 30px;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 30px;
  }
`;

export const Wrapper: any = styled.div`
  ${SSlickBase};
  ${SSlickTheme};
  background: #fff;
  margin: 20px auto 30px;
  width: 100%;
  border: 15px solid #92c2d6;
  .slick-dots {
    bottom: -40px;
    opacity: 1;

    li {
      width: 13px;
      height: 13px;
      opacity: 1;

      & > button {
        width: 16px;
        height: 16px;
        opacity: 1;

        &:before {
          width: 16px;
          height: 16px;
          color: transparent;
          opacity: 1;
          background-color: #575656;
          border-radius: 50%;
        }
      }

      &.slick-active > button:before {
        background-color: #92c2d6;
        opacity: 1;
      }
    }
  }
  .slick-list {
    height: 290.84px;
  }
`;
