import { css } from "styled-components";

import colors from "../../../../constants/colors";

export const wrapper: any = css`
    width: 100%;
    height: 100%;
    .slick-track {
      height: 351px!important;
    }
    .slick-dots {
        position: relative !important;
        bottom: auto !important;
        li {
          margin: 0;
        }
        li button:before {
          // background-color: #d0c5c8;
          border-radius: 50%;
          background-color: black;
          border: none;
          opacity: 1 !important;
          width: 10px !important;
          height: 10px !important;
        }
        li.slick-active button:before {
          background-color: ${colors.yellow};
        }
    }
`;
