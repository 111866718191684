import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { keyframes } from "styled-components";

export const FadeSliderWrapper: any = styled.div`
  width: 100%;
  div {
    border: none;
  }
`;

export const HeroWrapper: any = styled.div`
  width: 100%;
  height: 589.922px;
  position: relative;

  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("/images/gk_home_header_text.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: bottom center;
    z-index: 1;
    bottom: -50px;
  }

  @media (${BREAKPOINTS_DM.silver_768}){
    
    &::after{
      background-size: 70%;
    }
  }
`;
