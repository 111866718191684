import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

import colors from "../../../constants/colors";

export const imageWrapper: any = css`
    width: 40%;
    float: left;
    padding-right: 30px;

    div > img{
      width: 100%;
      height: auto;
    }
`;
export const wrapper: any = css`
    width: 100%;
    padding: 0 !important;
    background-color: white;
    > div {
      padding: 27px;
    }
`;

export const isStickedToComponentAbove: any = css`
    > div {
      border-top: none !important;
    }
    &:before {
      display: none;
    }
`;

export const headlineWrapper: any = css`
    display: flex;
    align-items: center;
    margin: 55px 0px 15px;
    > div {
      width: 80px;
      min-width: 80px;
      height: 80px;
      min-height: 80px;
      margin-right: 16px;
    }
    
    h2 {
      width: 100%;
      text-align: center;
      color: ${colors.dark_gray};
      font-size: 35px;
      font-family: Roboto,sans-serif;
      line-height: .8;
      margin-top: 5px;
      font-weight: 600;
    }
`;

export const richText: any = css`
    width: 95%;
    text-align: center;
    margin: auto;
    em {
      letter-spacing: 0.5px;
      font-style: italic;
      font-size: 22px;
      line-height: 1.2;
      color: #606060!important;
      font-family: Roboto,sans-serif;
      font-weight: 300;

    }
    a {
      color: ${props => props.theme.primary};
      &:hover {
        text-decoration: underline;
      }
    }
    a p {
      color: #606060!important;
      &:hover {
        text-decoration: underline;
      }
    }
    p{
      min-height: 8px;
    }
    h2, h3, h4 {
      color: ${props => props.theme.primary};
      font-size: 20px;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 55%;
    }
    
`;

export const clearFix: any = css`
  clear: both;
`;
