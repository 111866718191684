import React, { FC, useContext, useState, useEffect } from "react";
import {
  Context,
  ITextElement,
  ICallToAction,
  IRichLinkItem
} from "src/common";

import FurtherAwards from "../../common/FurtherAwards";
import { Section, Container } from "../../common/Grid";
import Hero from "../../common/Hero";
import NewSlider from "../../common/NewSlider";
import PlanningDateModal from "../../common/PlanningDateModal";
import PromotialOffertSlider from "../../common/PromotionalOfferSlider";
import { RichTextGeneral } from "../../common/RichText";

import {
  HeroContainer,
  HeroCallToAction,
  PromotionalOfferContainer,
  RichLinkTopParallaxWrapper,
  RichLinkBottomParallaxWrapper,
  RichLinkContainer,
  NewsContainer,
  FurtherAwardsContainer,
  TextELement,
  RichTextWrapper,
} from "./LandingContent.style";

const LandingContent: FC = () => 
{
  const [isShowModal, setShowModal] = useState(false);
  const props = useContext(Context);
  const { PageLanding: page } = props;
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  
  useEffect(() => 
  {
    const listener: any = () => 
    {
      document.documentElement.style.setProperty("--background-paralax", `${window.scrollY * - 0.15}px`);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  const [heroText, textElement] = page?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];
  const [callToAction] = page?.elements?.filter(e => e?.__typename === "CallToAction") as ICallToAction[];
  const [richLink, richLink1] = page?.elements?.filter(e => e?.__typename === "RichLinkItem") as IRichLinkItem[];
  const promotionalOffert = page.promotionalOffer;

  return (
    <Section>
      <HeroContainer>
        <Hero />
        <HeroCallToAction>
          <Container>
            <h1>{heroText?.headline}</h1>
            <h3><RichTextGeneral textContent={callToAction?.TextAboveButton} /></h3>
            <RichTextGeneral textContent={callToAction?.TextBelowButton} />
            <a onClick={() => setShowModal(!isShowModal)}>{callToAction?.ButtonLabel || "Beratunstermin vereinbaren"}</a>
          </Container>
        </HeroCallToAction>
      </HeroContainer>
      {textElement && (
        <TextELement>
          <RichTextWrapper>
            <h3>{textElement?.headline}</h3>
            <RichTextGeneral textContent={textElement.text} />
          </RichTextWrapper>
        </TextELement>
      )}

      <RichLinkTopParallaxWrapper src={richLink?.image?.[0].src}>
        <RichLinkContainer>
          <div>
            <h3><b>{richLink?.headline}</b></h3>
            <RichTextGeneral textContent={richLink?.richDesc} />
            <a href={richLink?.linkTo?.[0].slug}>Mehr erfahren</a>
          </div>
        </RichLinkContainer>
      </RichLinkTopParallaxWrapper>

      <PromotionalOfferContainer>
        <RichTextGeneral textContent={heroText?.text}/>
        <PromotialOffertSlider items={promotionalOffert}/>
        <a href="/aktionsbaustein">Alle Angebote</a>
      </PromotionalOfferContainer>

      <RichLinkBottomParallaxWrapper src={richLink1?.image?.[0].src}>
        <RichLinkContainer>
          <div>
            <h3><b>{richLink1?.headline}</b></h3>
            <RichTextGeneral textContent={richLink1?.richDesc} />
            <a href={richLink1?.linkTo?.[0].slug}>Mehr erfahren</a>
          </div>
        </RichLinkContainer>
      </RichLinkBottomParallaxWrapper>

      <NewsContainer>
        <h2>Unsere Neuigkeiten</h2>
        <NewSlider />
        <br/><br/>
        {isNewsCustom && <NewSlider newsCustom={isNewsCustom} /> }

        <a href="/meldungen-1">Mehr Neuigkeiten</a>
      </NewsContainer>

      <FurtherAwardsContainer>
        <FurtherAwards />
      </FurtherAwardsContainer>

      {isShowModal && (
        <PlanningDateModal onSetShowModal={setShowModal} />
      )}
    </Section>
  );
};

export default LandingContent;
