import { css } from "styled-components";

export const wrapper: any = css`
  width: 100%;
  margin: 20px 0 90px;

  .slick-dots {
    bottom: -60px;
  }
`;
