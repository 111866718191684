import React, { FC, useContext, useState } from "react";
import {
  Context,
  ITextElement,
  ICallToAction,
  LogoBannerHorizontal,
  LandingBannerLeft,
  IPageLanding_Elements,
  IRichLinkItem,
  Img,
  IPageLanding_Events,
  ILandingEvent,
} from "src/common";

import { Section, Container, ContainerLarge } from "../../common/Grid";
import Hero from "../../common/Hero";
import NewSlider from "../../common/NewSlider";
import PlanningDateModal from "../../common/PlanningDateModal";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import {
  HeroContainer,
  HeroContent,
  CallToActionContainer,
  AboutUsContainer,
  BrandsContainer,
  KnowMoreContainer,
  NewsContainer,
  FurtherAwardsContainer,
  bannerLeftCss,
} from "./LandingContent.style";
import BrandBannerVertical from "../../common/BrandBannerVertical";

const selectHeroLinks = (elements: IPageLanding_Elements[]): IRichLinkItem[] => elements?.
  filter(e => e.__typename === "RichLinkItem") as IRichLinkItem[];

const LandingContent: FC = () => {
  const props = useContext(Context);
  const { PageLanding: page } = props;

  const heroRichLinks = selectHeroLinks(page?.elements);

  return (
    <Section>
      <Container>
        <CallToActionContainer>
          {heroRichLinks?.map((link, i) => (
            <a key={`hero-link-${i}`} href={link.linkTo?.[0]?.slug}>
              {!!link.image?.length && <Img src={link.image} />}
              <h2>
                {link.headline}
              </h2>
              {link.richDesc && <RichTextGeneral textContent={link.richDesc} fragment />}
              { link.linkTo?.[0]?.slug && <span className="note">Klicken, um mehr zu erfahren</span>}
            </a>
          ))}
        </CallToActionContainer>
      </Container>
      <LandingBannerLeft overrideStyles={bannerLeftCss} />
    </Section>
  );
};

export default LandingContent;
