import { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  background: #6b6259;
  margin-top: 20px;
  margin-bottom: 40px;
  P {
    span {
      color: white;
    }
  }

  .slick-dots {
      background-color: #736c65;
      top: 340px;
      li {
        width: 5px;
        height: 5px;
        & > button {
          width: 5px;
          height: 5px;
          &:before {
            width: 8px;
            height: 8px;
            border: 1px solid #585858;
            color: transparent;
            opacity: 1;
          }
        }
        
        &.slick-active > button:before  {
          width: 8px;
        height: 8px;
          background-color: #585858;
        }
      }
      @media (${BREAKPOINTS_DM.silver_768}) {
        top: 150px;
      }
  }
`;