import React, { FC } from "react";

import { Container } from "../Grid";
import { PageHeadingWrapper, HeaderContainer } from "./PageHeading.style";
const PageHeading: FC<{ imageUrl: string; headLine: string }> = ({ imageUrl, headLine }) => (
  <PageHeadingWrapper src={imageUrl}>
    <HeaderContainer>
      <Container>
        <h1>
          {headLine}
        </h1>
      </Container>
    </HeaderContainer>
  </PageHeadingWrapper>
);

export default PageHeading;