import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import { SlideWrapper, ImgWrapper, Counter, Content, ContentWrapper, Spacer, Wrapper } from "./NewSlide.style";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

const NewSlide: FunctionComponent<IProps> = props =>
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <Wrapper>
      <SlideWrapper withImage={!!props.img}>
        {props.img && (
          <ImgWrapper>
            <Img quality={600} lazyload={false} src={props.img} alt={props.imgAlt} />
          </ImgWrapper>
        )}
        <Counter>
          <h3>Küchennachricht {props?.index + 1} / {props?.totalCount}</h3>
        </Counter>
        <ContentWrapper>
          <a href={props?.link}>
            {text ? (
              <Content>
                <RichText textContent={text}>
                  {props.title && <h3>{props.title}</h3>}
                </RichText>
              </Content>
            ) : (
              <Content>
                {props.title && <h3>{props.title}</h3>}
              </Content>
            )}
          </a>
        </ContentWrapper>
      </SlideWrapper>
      <Spacer/>
    </Wrapper>
  );
};

export default NewSlide;
