import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";
import { theme } from "../../../constants/themes";

export const Wrapper: any = styled.div`
  width: 100%;
`;

export const ContentWrapper: any = styled.div`
  width: 100%;
  min-height: 150px;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 30px 50px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 100px;
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    max-width: ${theme.maxWidth}px;
    margin: auto;
  }
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 10px 0;
  img {
    filter: grayscale(1);
    width: 100px;
    margin: auto;
  }
    width: 50%;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 25%;
  }
`;

export const BorderTop: any = styled.div`
  width: 100%;
  height: 15px;
  background-image: url("images/custom_border.png");
`;

export const BorderBottom: any = styled.div`
  width: 100%;
  height: 5px;
  background-position: center;
  background-image: url("images/custom_border.png");
  margin-bottom: 26px;
`;
