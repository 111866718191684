import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import { Divider } from "../../Grid";
import RichText from "../../RichText/RichText";
import { Slide, ContentWrapper, ImageLink } from "./Item.style";

interface IProps {
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

const Item: FunctionComponent<IProps> = (props) => {
  return (
    <Slide withImage={!!props.img}>
      <Divider />
      {props.img && (
        <ImageLink href={props?.link}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </ImageLink>
      )}
      <ContentWrapper>
        <a href={props?.link}>
          {props.title && <h3>{props.title}</h3>}
          {props.text && <RichText textContent={props.text} />}
        </a>
        <a href={props?.link} data-more>
          <img src="/images/kw_icon_arrow_right.png" />
          mehr
        </a>
      </ContentWrapper>
    </Slide>
  );
};

export default Item;
