import React, { FunctionComponent, useContext } from "react";
import Slider from "react-slick";
import { INews, IAsset, Context, getIfNewsShouldBeDisplayByDate } from "src/common";

import SSlickBase from "../../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../../utils/slick/SSlickTheme";
import * as styles from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

export const NewSlider: FunctionComponent<{newCustom?: boolean}> = ({ newCustom }) => 
{
  const p = useContext(Context);
  const news = newCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
  const newsSlug = p.NewsSlug;
  
  if(!news || news.length <= 0) 
  {
    return null;
  }

  const newsToRender = news?.filter(newsItem =>
    getIfNewsShouldBeDisplayByDate({
      startDate: newsItem?.startDate,
      endDate: newsItem?.endDate
    })
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  

  return (
    <div css={`${SSlickBase}${SSlickTheme}${styles.wrapper}`}>
      <Slider {...sliderSettings}>
        {newsToRender?.map((newsItem, i) => (
          <NewSlide
            key={i}
            index={i}
            totalCount={newsToRender.length}
            img={(newsItem?.extras as IAsset[])?.[0]}
            imgAlt={(newsItem?.extras as IAsset[])?.[0]?.description}
            title={newsItem?.headline}
            text={newsItem?.text}
            link={newsSlug + "/" + newsItem?.id}
          />
        ))}
      </Slider>
    </div>
  );
};
