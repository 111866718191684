import { Context } from "src/common";
import { IGenLandingEvent } from "src/common/types_dealer_gen";
import UniversalComponentsRenderer, {
    TUniversalComponentRouter,
} from "src/templates/t27/BaseComponents/UniversalComponentsRenderer/UniversalComponentsRenderer";

import React, { FunctionComponent, useContext, Fragment } from "react";
import { css } from "styled-components";

import FurtherAwards from "../../../common/FurtherAwards/FurtherAwards";
import { LandingEvent } from "../landingEvent/LandingEvent";
import NewsSlider from "../NewsSlider/NewsSlider";
import ContainerT27 from "src/templates/t27/BaseComponents/container/ContainerT27";
import SectionSeparator from "src/templates/t27/BaseComponents/section-separator/SeparatorGreyLine";
import { SSiteContentTextWrapper } from "./styles/SSiteContentTextWrapper";
import { SSiteContentColumnsWrapper } from "./styles/SSiteContentColumnsWrapper";

export const SiteContent: FunctionComponent = () => {
    const p = useContext(Context);
    const elements = p?.PageLanding?.elements as TUniversalComponentRouter[];
    const areThereMoreThanOneElement = elements?.length > 2;

    const startChunkWithHalfWidth = elements?.slice(2, 3);
    const endChunk = elements?.slice(3);

    const hasFurtherAwards = p?.CompanyInformationPrivate?.furtherAwards?.length > 0;

    const landingEvents = p?.PageLanding?.events as IGenLandingEvent[];
    const isNewsCustom = p?.PageLanding?.NewsCustom?.length > 0;

    return (
        <ContainerT27
            overwriteStyle={css`
                position: relative;
                background-color: var(--back-1);
            `}
        >
            <SSiteContentColumnsWrapper areThereMoreThanOneElement={areThereMoreThanOneElement}>
                {areThereMoreThanOneElement && (
                    <SSiteContentTextWrapper>
                        <UniversalComponentsRenderer
                            components={startChunkWithHalfWidth}
                            hasContainerPaddingWrapper={false}
                        />
                    </SSiteContentTextWrapper>
                )}
                <div>
                    {landingEvents &&
                        landingEvents.map((event, i) => (
                            <LandingEvent
                                key={i}
                                event={event}
                                className={i === landingEvents.length - 1 ? "last-item" : "item"}
                            />
                        ))}
                    <NewsSlider />
                    {isNewsCustom && <NewsSlider newsCustom={isNewsCustom} />}
                    {hasFurtherAwards && <FurtherAwards />}
                </div>
            </SSiteContentColumnsWrapper>
            {endChunk && endChunk.length > 0 && (
                <>
                    <SectionSeparator hasContainerPaddingWrapper={false} />
                    <UniversalComponentsRenderer hasContainerPaddingWrapper={false} components={endChunk} />
                </>
            )}
        </ContainerT27>
    );
};
