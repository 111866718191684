import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 134px auto;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 auto;
    padding: 0;
  }
`;

export const contentWrapper: any = css`
  display: flex;
  flex-direction: column;
  .kitchen-compass {
    display: block;
    margin-top: 20px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {

    flex-direction: row;
  }
`;

export const leftContent: any = css`
  width: 100%;
  padding: 10px;
  @media (${BREAKPOINTS_DM.silver_768}){
    padding: 0;
    width: 50%;
  }
  h1 {
    font-weight: 400;
    line-height: 28px;
    letter-spacing: .02em;
    font-size: 24px;
  }
  div > p > span {
    font-size: 15px;
  }
`;

export const sideBar: any = css`
  width: 100%;
  max-width: initial;
  padding-left: 10px;
  padding-right: 10px;

  @media (${BREAKPOINTS_DM.silver_768})
  {
    width: 50%;
    max-width: 500px;
    padding-left: 21px;
    padding-right: 0;
  }
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`;

export const HeroContainer = styled.div`
  border: 5px solid ${({ theme }) => theme.palette.textColor};
  position: relative;
  margin: 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 0 21px 0;
  }
`;

export const ProductLandingBannerWrapper: any = styled.div`
h3 {
    color: #f6931e;
}

h1.plb-title {
    background: #f6931e;
}

.plb-price-container {
    color:  #f6931e;
}
.plb-price-badge {
    background:  #f6931e;
    ::before {
        background: #f6931e;
    }
    ::after {
        background: #f6931e;
    }
}
hr {
    border-top: 1px solid #f6931e;
}

.plb-c0 {
    background: #f6931e;
}
`;
