import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container, ContainerLarge } from "../../common/Grid";

export const sideBar: any = css`
  width: 100%;
  max-width: initial;
  padding-left: 10px;
  padding-right: 10px;

  @media (${BREAKPOINTS_DM.silver_768})
  {
    width: 50%;
    max-width: 500px;
    padding-left: 21px;
    padding-right: 0;
  }
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`;

export const bannerLeftCss: any = css`
  font-family: "Open Sans";

  h1 {

    padding: 15px 0;
    margin: 0;

    &, * {
      font-size: 32px;
      font-weight: 300;
      color: white;
    }
  }

  p {
    &, span, * {
      font-size: 18px;
      line-height: 1.25rem !important;
      color: ${({ theme }) => theme.palette.textColor };
    }
  }

`;

export const HeroContainer = styled.div`
  position: relative;

  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 812px;
    margin: 0;
  }

  [data-slider-wrapper] > div {
    border: none;

    img {
      filter: grayscale(6%) brightness(96.4%);
    }
  }

  .slick-next {
    display: none !important;
  }
`;

export const HeroContent: any = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100px;

  ${Container} {
    display: block;
    h1 {
      font-family: Roboto, sans-serif;
      font-size: 53px;
      line-height: 1.2;
      color: #fff;
      text-transform: none;
      font-weight: 700;
      text-shadow: 0 1px 2px rgb(0 0 0 / 60%);

      p, span {
        font-family: Roboto, sans-serif;
      }
    }

    > p {
      font-family: Roboto, sans-serif;
      font-size: 21px;
      color: #fff;
      text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
      margin-bottom: 60px;
    }


    a.button {
      padding: 17px 25px;
      display: inline-block;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      color: white;
      line-height: 1;

      border: 2px solid #ddd;
      background-color: #222;

      :hover {
        border-color: #fff;
      }
    }
  }

  article {
    position: absolute;
    width: 190px;
    height: 190px;
    top: -330px;
    right: 30px;

    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 24px;

    background: transparent;
    transform: rotate(7deg);

    b {
      line-height: 18px;
    }

    small > p {
      font-family: Roboto, sans-serif;
      margin: 0;
    }

    a {

    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;


export const CallToActionContainer = styled.div`
  width: 100%;
  padding-top: 28px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: grid; // 8 x 3
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 322px);
    column-gap: 28px;
    row-gap: 28px;
  }

  a {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.primary};
    background-clip: content-box;

    height: 300px;
    padding: 14px 0;

    @media (${BREAKPOINTS_DM.silver_768}) {
      height: auto;
      padding: 0;
    }

    span.note {
      display: block;
      position: absolute;
      bottom: calc(5px + 14px); // + 14px padding
      right: 5px;
      font-size: 12px;
      opacity: .9;
      z-index: 10;
      color: #fff;
      @media (${BREAKPOINTS_DM.silver_768}) {
        bottom: 5px; 
      }
    }

    :hover {
      > div {
        opacity: 0;
      }
    }

    > div {
      position: absolute;
      z-index: 1;
      height: calc(100% - 28px);
      width: 100%;
      img {
        object-fit: cover;
      }
      transition: opacity .3s;

      @media (${BREAKPOINTS_DM.silver_768}) {
        height: 100%;
      }

    }

    > h2 {
      z-index: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: white;
      margin-top: 0;
      margin-bottom: 21px;
      text-transform: uppercase;
      text-align: center;
      padding-left: 14px;
      padding-right: 14px;
    }

    > p {
      font-size: 14px;
      color: white;
      text-align: center;
      margin-bottom: 1.5rem;
      padding-left: 14px;
      padding-right: 14px;
    }

    :nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    :nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }

    :nth-child(3) {
      grid-column: 3;
      grid-row: 1 / span 2;
    }

    :nth-child(4) {
      grid-column: 1 / span 2;
    }

    :nth-child(5) {
      grid-column: 1;
      grid-row: 3;
    }

    :nth-child(6) {
      grid-column: 2 / span 2;
      grid-row: 3 / span 2;
    }

    :nth-child(7) {
      grid-column: 1;
      grid-row: 4;
    }

    :nth-child(8) {
      grid-column: 1;
      grid-row: 5;
    }

    :nth-child(9) {
      grid-column: 2;
      grid-row: 5;
    }

    :nth-child(10) {
      grid-column: 3;
      grid-row: 5 / span 2;
    }

    :nth-child(11) {
      grid-column: 1 / span 2;
      grid-row: 6;
    }

    :nth-child(12) {
      grid-column: 1;
      grid-row: 7;
    }

    :nth-child(13) {
      grid-column: 2 / span 2;
      grid-row: 7 / span 2;
    }

    :nth-child(14) {
      grid-column: 1;
      grid-row: 8;
    }
  }

`;

export const BrandsContainer = styled.div`
  width: 100%;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.palette.bgLight};

  img {
    filter: grayscale(100%);
    opacity: .8;
  }
`;

export const FurtherAwardsContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const BaseTextContainer = styled(Container)`
  flex-direction: column;
  padding: 50px 15px;

  .title, h1, h2 {
    margin-top: 20px;
    margin-bottom: 40px;

    &, span {
      ${({ theme }) => theme.textStyles.h1};
    }

    position: relative;

    :after {
      position: absolute;
      display: block;
      height: 2px;
      width: 30px;
      left: calc(50% - 15px);
      margin-top: 10px;
      background: ${({ theme }) => theme.palette.primary};
      content: '';
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
`;

export const MainTextContainer = styled(BaseTextContainer)`
  justify-content: center;
  align-items: center;

  .title, h1 {

    text-align: center;
  }
`;

export const AboutUsContainer = styled(MainTextContainer)`
  margin-top: 68px;
  padding: 60px 0 100px 0;
`;

export const KnowMoreContainer = styled(MainTextContainer)`

  > p {
    margin: auto;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
      text-align: center;
    }
  }

  .linksContainer {
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
      display: flex;
      justify-content: space-between;
    }

    a[data-richlink] {
      display: block;
      width: 100%;
      padding: 20px 0;

      @media (${BREAKPOINTS_DM.silver_768}) {
        width: calc(100% / 3);
        padding: 20px 15px;
      }

      > div:first-child {
        height: 216px;
      }
      > h3 {
        font-weight: 300;
        color: #555;
        text-align: center;

        :after {
          left: calc(50% - 15px);
        }
        @media (${BREAKPOINTS_DM.silver_768}) {
          text-align: left;
          :after {
            left: 0;
          }
        }
      }

      > div:last-child {
        h2,h3,h4 {
          &, span {
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            margin: 0 0 10px 0;
            color: ${({ theme }) => theme.palette.primary};
            :after {
              display: none;
            }
          }
        }

        > p {
          &, a {
            color: ${({ theme }) => theme.palette.textColor};
            text-decoration: none;
          }
        }
      }
    }
  }
`;

export const NewsContainer = styled(MainTextContainer)`
  flex-direction: column;
  padding: 80px 0;
  margin-bottom: 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;
