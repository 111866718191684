
import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img";
import { Wrapper, ContentWrapper, ImgWrapper, Button } from "./NewSlide.style";
interface IProps 
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

export const NewSlide: FunctionComponent<IProps> = props => 
{
 
  return (
    <Wrapper>
      {props.title && <h3>{props.title}</h3>}
      {props.img && (
        <ImgWrapper>
          <Img quality={600} src={props.img} alt={props.imgAlt}/>
        </ImgWrapper>
      )}
      <ContentWrapper>
        <a href={props?.link}>
          ...
        </a>
        <Button href={props?.link}>
          <p>
            Mehr erfahren
          </p>
        </Button>
      </ContentWrapper>
    </Wrapper>
  );
};
