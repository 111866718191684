import { css } from "styled-components";

export const wrapper: any = css`
    max-width: 1400px;
    margin: 20px auto 40px;
    // margin-top: 20px;
    // margin-bottom: 40px;
    .slick-dots li {
      width: 13px;
    }
    .slick-dots {
        bottom: -61px;
        li button:before {
          font-size: 5px;
          position absolute;
          text-align center;
          background-color: #cbcccd;
          border-radius: 50%;
          width: 7px;
          height: 7px;
        }
    }

    .slick-dots li.slick-active button:before
      {
          opacity: .75;
          background-color: #94C03E;
      }

    .slick-prev,
    .slick-next {
      z-index: 9;
      background-color: rgba(255,255,255,.5) !important;
      width: 30px !important;
      height: 30px !important;
      :before {
        opacity: 1;
        display: inline-block;
        content: "" !important;
        background-image: url(/images/sprite-sa3ca3ca63e.png);
        background-repeat: no-repeat;
        background-position: 0 -172px;
        height: 18px;
        width: 12px;
        color: transparent !important;
      }
  
      :hover {
        background-color: rgba(255,255,255,.5);
      }
    }
  
    .slick-next {
      right: 0 !important;
    }
  
    .slick-prev {
      left: 0 !important;
      :before {
        transform: rotate(180deg);
      }
    }
  
    .slick-slide{
      padding-top: 6px;
      padding-right: 6px;
      &:not(.slick-current) {
        opacity: .5;
      }
    }
    .slick-slider {
      height: 205px;
    }
    .slick-track {
      height: 205px;
    }
`;
