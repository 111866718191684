import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ImageWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(50% - 10px);
  }
`;
