import { css } from "styled-components";

import colors from "../../../../constants/colors";

export const outerWrapper: any = css`
    background-color: ${props => props.theme.secondary};
    margin-top: 1px;
`;

export const wrapper: any = css`
    width: 100%;
    height: 100%;
    .slick-dots {
        position: relative !important;
        bottom: auto !important;
        text-align: left !important;
        width: fit-content;
        margin: auto;
        li {
          display: inline-block !important;
          margin: 0;
        }
        li button:before {
          background-color: white;
          border-radius: 50%;
          border: none;
          opacity: 1 !important;
          width: 10px !important;
          height: 10px !important;
        }
        li.slick-active button:before {
          background-color: ${props => props.theme.primary};
        }
    }
`;
