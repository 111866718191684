// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 290.84px;
  }
`;
export const SlideWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ withImage }) => withImage && "justify-content: center;"}
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 290.84px;
  }
`;

export const ContentWrapper: any = styled.div`
  padding: 15px 0;
  margin-bottom: 8px;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }
`;

export const ImgWrapper: any = styled.div`
  object-fit: cover;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    height: 290.84px;
    max-width: 490px;
    margin-right: auto;
  }
`;

export const Counter: any = styled.div`
  > h3 {
    padding-left: 21px;
    color: #337ab7;
    font-size: 13px;
    line-height: 21px;
    font-weight: 300;
  }
`;

export const Content: any = styled.div`
  color: #000;
  line-height: 140%;
  overflow: hidden;
  font-size: 14px;
  padding-left: 21px;
  h3 {
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
  h2,
  h4,
  p {
    span {
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
    }
    font-weight: 300;
    font-size: 13px;
    color: inherit;
    text-decoration: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  h3 {
    &,
    > span {
      font-size: 23px;
      line-height: 32px;
      color: #85b2c3;
    }
  }
  h4 {
    color: #337ab7;
  }
  p,
  p > span {
    font-size: 15px;
    line-height: 24px;
    color: #85b2c3;
  }
  p,
  h3 {
    margin-right: 16px;
  }
`;
export const Spacer: any = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 7%;
  }
`;
