import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "src/common/constants/mediaquerys";

const Default = css`
  display: flex;
  gap: 10px;
  flex-direction: column;
  font-size: 25px;
  color: #736654;
  line-height: 28px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid #7d7261;
  padding: 20px;

  form {
    position: relative;
    border: 1px solid #7d7261;
  }

  input {
    width: 100%;
    height: 70px;
    font-size: 30px;
    padding-left: 20px;
    border: none;
  }

  input[type="submit"] {
    position: absolute;
    background: url(https://www.kuechengilde.de/images/icon/kg_icon_search.png)
      no-repeat;
    top: 10px;
    right: 10px;
    width: 55px;
    height: 51px;
    color: transparent;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SSearch = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

export default SSearch;
