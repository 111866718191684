import React, { FC, useContext, useState } from "react";
import { Context, getPage, Img, RichText } from "src/common";

import { Wrapper, SideBanner, OfferContainer, OfferItem, ImageWrapper, Description, Price } from "./PromotionalOfferSlider.style";

const PromotionalOffertSlider: FC = () => {
  const props = useContext(Context);
  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const promotionalOffers = props.PageLanding?.promotionalOffer;
  const item = promotionalOffers?.[current];

  if(!item) {
    return null
  }

  return (
    <Wrapper open={open}>
      <SideBanner onClick={() => setOpen(!open)} onMouseOver={() => setTimeout(() => setOpen(true), 300)}><a>Aktionsangebot</a></SideBanner>
      <OfferContainer>
        <OfferItem>
          <h3>{item.title ?? ""}</h3>
          <ImageWrapper>
            <Img src={item.images} />
          </ImageWrapper>

          <Description>
            <Price>nur {item.ourPrice}</Price>
            <hr />
            <h4>Unser Preis inklusive:</h4>
            <p>
              <RichText textContent={item.productDescription.content}/>
            </p>
          </Description>
        </OfferItem>
      </OfferContainer>
    </Wrapper>
  );
};

export default PromotionalOffertSlider;
