import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://60600-testpartner.gedk.de/
const PageLandingT25: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Footer />
     
    <LandingBannerLeft />
  </>
);

PageLandingT25.displayName = "PageLandingT25";

export { PageLandingT25 };
