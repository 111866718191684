import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

interface IProps {
    isClosed: boolean;
    isLoaded: boolean;
    hasImage: boolean;
}

const Default = css<IProps>`
    position: fixed;
    width: 88%;
    left: 0;
    z-index: 100;
    padding: 24px;
    transition: opacity 0.3s ease-in-out;
    background-color: var(--back-3);
    aspect-ratio: 1/1;
    top: 50%;
    transform: translateY(-50%);
    max-width: 380px;
    > a {
        /* display: block; */
        /* height: 100%; */
    }

    ${({ isClosed }) =>
        isClosed &&
        css`
            display: none;
        `};

    ${({ isLoaded }) =>
        isLoaded
            ? css`
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `};
`;

const Silver = css<IProps>`
    width: 51%;
    max-width: 380px;
`;

const Gold = css`
    max-width: 380px;
`;

const Platinum = css<IProps>`
    padding: 38px;
    width: 44%;
    max-width: 540px;
    top: 60%;
`;

const Diamond = css``;

export const SLandingBannerLeft = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
