import React, { FunctionComponent } from "react";

const LinkArrow: FunctionComponent<{size?: number}> = ({ size= 30 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 21" fill="none">
      <path d="M19 19L27 11L19 3" stroke="currentColor" strokeWidth="3"/>
      <path d="M2 0V11H26" stroke="currentColor" strokeWidth="3"/>
    </svg>
  );

export default LinkArrow;
