import React, { FC, useContext, useState } from "react";
import {
  Context,
  IRichLinkItem
} from "src/common";

import { PlanningDateModaL } from "../../common/PlanningDateModaL/PlanningDateModaL";
import Hero from "./Hero"; 
import {
  SectionWrapper,
  RichLinkContainer,
  RichLinkWrapper,
  RichLinkSection,
  Bottom,
} from "./LandingContent.style";
const LandingContent: FC = () =>
{
  const props = useContext(Context);
  const { PageLanding: page } = props;
  const richLink = page?.elements?.filter(e => e?.__typename === "RichLinkItem") as IRichLinkItem[];
  const [isShowModal, toogleShowModal] = useState(false);
  return (
    <SectionWrapper>
      <Hero />
      {isShowModal && <PlanningDateModaL onSetShowModal={toogleShowModal}/>}
      <RichLinkSection>
        {richLink?.map((richLinkItem, ind) => 
        {
          if(ind > 5) { return; }
          if(richLinkItem?.image[0]?.src) 
          {
            return (
              <RichLinkWrapper key={`richlink-${ind}`} third={ind <= 2} half={ind > 2 && ind < 5} full={ind > 4} src={richLinkItem?.image?.[0].src}>
                <div className="parent">
                  <div className="rich-link-content">
                    <RichLinkContainer>
                      <div className="content">
                        <h3>{richLinkItem?.headline}</h3>
                        <a href={richLinkItem?.linkTo?.[0].slug}><span>Mehr erfahren</span></a>
                      </div>
                    </RichLinkContainer>
                  </div>
                </div>
              </RichLinkWrapper>);
          }
          return (
            <RichLinkWrapper key={`richlink-${ind}`} third={ind <= 2} half={ind > 2 && ind < 5} full={ind > 4}>
              <div className="parent">
                <div className="rich-link-with-out-image">
                  <RichLinkContainer>
                    <div className="content">
                      <h3>{richLinkItem?.headline}</h3>
                      <div className="button" onClick={() => toogleShowModal(true)}><span>Beratungstermin vereinbaren</span></div>
                    </div>
                  </RichLinkContainer>
                </div>
              </div>
            </RichLinkWrapper>);
        }
      )}
        <Bottom>
          <div className="left-side"><h3>So kommen Sie zu uns</h3></div>
          <div className="right-side"><a className="button-bottom" href={props?.PageContact?.slug}>So kommen Sie zu uns</a></div> 
        </Bottom>
      </RichLinkSection>
      
    </SectionWrapper>
  );
};

export default LandingContent;
