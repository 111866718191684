// export const NewSlide: FunctionComponent<IProps> = props => {
//     return (
//         <div css={styles.wrapper}>
//             {props.img && <img css={styles.img} src={props.img} alt={props.imgAlt ? props.imgAlt : ""}/>}
//             <div css={styles.contentWrapper}>
//                 <p css={styles.counter}>Nachricht {props.index + 1} / {props.totalCount}</p>
//                 <a href={props.link ? props.link : undefined} css={styles.content}>
//                     {props.title && <h3>{props.title}</h3>}
//                     <RichText textContent={props?.text} />
//                 </a>
//             </div>
//         </div>
//     )
// };

import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import * as styles from "./NewSlide.style";
import { Wrapper, ImgWrapper, ContentWrapper, Button } from "./NewSlide.style";

interface IProps 
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

export const NewSlide: FunctionComponent<IProps> = props => 
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle) 
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <Wrapper>
      {props.img && (
        <ImgWrapper href={props?.link}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </ImgWrapper>
      )}
      <ContentWrapper>
        <a href={props?.link}>
          {text ? (
            <div css={styles.content}>
              <RichText textContent={text}>
                {props.title && <h3>{props.title}</h3>}
              </RichText>
            </div>
          ):(
            <div css={styles.content}>
              {props.title && <h3>{props.title}</h3>}
            </div>
          )}
        </a>
        <Button>
          <p className="button">Lesen</p><i className="fa fa-chevron-right"/>
        </Button>
      </ContentWrapper>

    </Wrapper>
  );
};
