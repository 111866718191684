import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

const Default = css``;

const Silver = css`
    > div {
        &.textElement__wrapper > div {
            width: 100%;
        }
    }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SSiteContentTextWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
