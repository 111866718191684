import styled from "styled-components";

export const Wrapper: any = styled.div`
  margin-bottom: 21px;
`;

export const EventItem: any = styled.div`
  display: flex;
  background-color: ${props => props.theme.palette.green};
  width: 100%;
  margin-bottom: 10px;
  margin-top: 21px;
`;

export const EventIcon: any = styled.img`
  margin: 10px;
  height: 60px;
  width: 60px;
`;

export const LinkWrapper: any = styled.a`
    padding: 5px 30px 5px 10px;
    color: ${props => props.theme.palette.textColor1};
    h3 {
        font-size: 15px;
        line-height: 1.4;
    }

    p {
      color: ${props => props.theme.palette.textColor1};
      display: inline;
      font-size: 13px;
      font-weight: 300;
      > span {
          font-weight: 300;
      }
    }

    .afterArrow:after {
        background-image: url("/images/sprite-s82c5aa31b4.png");
        height: 15px;
        width: 15px;
        display: inline-block;
        content: "";
        transform: translate(6px, 3px);
    }

    &:hover {
      text-decoration: underline;
    }
`;
