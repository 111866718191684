import React, { Fragment, FunctionComponent, useContext } from "react";
import { Img, Context } from "src/common";

import {
  LogoBannerList,
  LogoBannerItem,
  ImageWrapper,
  EmptyLogoBannerItem,
} from "./LogoBannerHorizontal.style";

const LogoBannerHorizontal: FunctionComponent<any> = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;

  if (!allBrands || !allBrands?.length) {
    return null;
  }

  const brandsToUse = allBrands.filter((company: any) => {
    if (
      !company?.logo ||
      company.logo.length === 0 ||
      !company.logo?.[0]?.src
    ) {
      return false;
    }

    return !!company?.logo;
  });

  const fieldsWithLogo = [
    0, 1, 1, 0, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0,
  ];

  let brandCount = 0;
  const companies = fieldsWithLogo.map((value, index) => {
    let company = null;
    if (value) {
      company = brandsToUse[brandCount];
      brandCount++;
    }

    return (
      <Fragment key={index}>
        {value === 0 || !company ? (
          <EmptyLogoBannerItem />
        ) : (
          <LogoBannerItem>
            <ImageWrapper>
              <Img src={company?.logo} alt={company?.name || ""} lazyload={false}/>
            </ImageWrapper>
          </LogoBannerItem>
        )}
      </Fragment>
    );
  });

  return <LogoBannerList>{companies}</LogoBannerList>;
};

export { LogoBannerHorizontal };
