// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  display: block;
  position: relative;
  padding: 0rem .9375rem;
  @media (${BREAKPOINTS_DM.silver_768}) {

  }
`;

export const ContentContainer: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 70px;
  background-color: rgba(255,255,255,.8);
  max-height: 240px;
  text-decoration: none;
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const Content: any = styled.div`
    color: black;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;

    > h3 {
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: #000;
      margin: 0;
    }
`;

export const ImageContainer: any = styled.a`
  display: block;
  width: 100%;
  height: 214px;
  object-fit: cover;
  @media (${BREAKPOINTS_DM.silver_768}) {

  }
`;

export const counter: any = css`
    margin-bottom: 15px;
    font-size: 13px;
`;
