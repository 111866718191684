import styled, { css } from "styled-components";

export const LogoBannerList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  @media (min-width: 480px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 28px;
  overflow: hidden;

  & > div {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #7d7261;
      mix-blend-mode: color;
    }
    img {
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const LogoBannerItem = styled.li`
  ${({ theme }) => theme.box}
  padding: 10px;
  list-style: none;
`;

export const EmptyLogoBannerItem = styled.div`
  height: 50px;
  width: 100%;
  background-color: #7d7261;
`;
