import React, { FC, useContext } from "react";
import { Context, IAsset, ISlider } from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import Img from "../../../../BaseComponents/Img";
import { FadeSliderWrapper, TextWrapper, HeroWrapper } from "./Hero.style";

const Hero: FC<{ headline?: string }> = ({ headline }) => {
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  const slider = props.PageLanding?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as ISlider[];
  let imageOrGallery;
  if (slider && !!slider?.[0]?.elements) {
    imageOrGallery = (
      <FadeSliderWrapper>
        <FadeSlider elements={slider?.[0]?.elements} />
      </FadeSliderWrapper>
    );
  } else if (imgArray?.[0]) {
    if (imgArray.length > 1) {
      imageOrGallery = (
        <FadeSliderWrapper>
          <FadeSlider elements={[...imgArray?.map((f) => f)]} />
        </FadeSliderWrapper>
      );
    } else if (imgArray?.length > 1) {
      imageOrGallery = (
        <FadeSliderWrapper>
          <FadeSlider elements={imgArray} />
        </FadeSliderWrapper>
      );
    } else {
      imageOrGallery = (
        <Img alt={imgArray?.[0]?.title} src={imgArray} height={440} />
      );
    }
  }

  return (
    <HeroWrapper>
      {imageOrGallery}
      <TextWrapper>
        <h3>{headline}</h3>
      </TextWrapper>
    </HeroWrapper>
  );
};

export default Hero;
