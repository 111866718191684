import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://60631-vorschau.kuechengilde.de/
const PageLandingT14: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Footer brands />
     
  </>
);

PageLandingT14.displayName = "PageLandingT14";

export { PageLandingT14 };
