import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
    ${SSlickBase}
    ${SSlickTheme}
    margin-top: 20px;
    padding: 0 10px;
    width: 100%;

    .slick-track{
      /* margin: 0 40px; */
    }
    padding: 0 40px;

    .slick-next {
      right: -25px !important;
      top: 30%;
    }
    .slick-prev {
      left: -35px !important;
      z-index: 1;
      top: 30%;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 91%;
      margin: auto;
      padding: 0;
      .slick-next {
        /* right: -25px; */
        top: 50%;
      }
      .slick-prev {
        /* left: -25px; */
        top: 50%;
      }
    }
    @media (${BREAKPOINTS_DM.diamond_1920}) 
    {
      max-width: 1220px;
    }

`;
