import React, { FC, useContext } from "react";
import {
  Context,
  ITextElement,
  IAsset,
  ISlider,
  LogoBannerHorizontal
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import { Section } from "src/templates/t17/BaseComponents/Grid";

import Img from "../../../BaseComponents/Img";

import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import * as styles from "./LandingContent.style";
import { SectionWrapper, BannerHorizontalWrapper } from "./LandingContent.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
        />
      );
    }
  }

  return imageOrGallery;
};

const LandingContent: FC = () =>
{
  const props = useContext(Context);
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  return (
    <SectionWrapper>
      <Section>
        <Hero />
        <div css={styles.contentWrapper}>
          <div css={styles.leftContent}>
            {(props.PageLanding?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[])?.map(
              (t, i) => (
                <RichTextGeneral
                  key={`rt0-${i}`}
                  textContent={t.text}
                  css={styles.mainText}
                >
                  <h1>{t?.headline}</h1>
                </RichTextGeneral>
              )
            )}
          </div>
          <div css={styles.sideBar}>
            <TeaserRight />
            <NewSlider />
            {isNewsCustom && <NewSlider newsCustom={isNewsCustom}/>}
          </div>

        </div>
      </Section>

      <BannerHorizontalWrapper>
        <LogoBannerHorizontal shadowOverlayVisible={true} />
      </BannerHorizontalWrapper>
    </SectionWrapper>
  );
};

export default LandingContent;
