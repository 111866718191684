import React, { Fragment, FunctionComponent, useContext } from "react";
import { Context, LandingBannerLeft } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";

import UniversalComponentRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentRenderer";
import { Footer } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import { NewSlider } from "./NewSlider/NewSlider";

const PageLandingT6: FunctionComponent = () => {
    const props = useContext(Context);
    let mainSlider;
    const elementsWithoutMainAsset = props.PageLanding?.elements.filter((e, index) => {
        if (index === 0 && e?.__typename === "Asset") {
            return false;
        }
        if (index === 0 && e?.__typename === "Slider") {
            mainSlider = e;
            return false;
        }
        return true;
    });

    return (
        <div>
            <div>
                <LandingBannerLeft />
            </div>
            <div>
                <ProductLandingBanner />
            </div>
            <div>
                <Header mainSlider={mainSlider} />
            </div>
            <div>
                <UniversalComponentRenderer items={elementsWithoutMainAsset} />
            </div>
            <div>
                {props?.PageLanding?.News && <NewSlider newsSlug={props?.NewsSlug} news={props.PageLanding?.News} />}
                {props?.PageLanding?.NewsCustom && (
                    <NewSlider newsSlug={props?.NewsSlug} news={props.PageLanding?.NewsCustom} />
                )}
            </div>
            <div>
                <Footer showLogos={true} />
            </div>
        </div>
    );
};

PageLandingT6.displayName = "PageLandingT6";
export { PageLandingT6 };
