import React, { FC, useContext, Fragment } from "react";
import {
  Context,
  IAsset,
  ISlider
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import Img from "../../../../BaseComponents/Img";
import {
  SliderWrapper,
} from "./Hero.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <SliderWrapper><FadeSlider elements={slider?.[0]?.elements} /></SliderWrapper>;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <SliderWrapper><FadeSlider elements={[...imgArray?.map(f => f)]} /></SliderWrapper>;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <SliderWrapper><FadeSlider elements={imgArray} />;</SliderWrapper>;
    }
    else
    {
      imageOrGallery = (
        <SliderWrapper>
          <Img
            alt={imgArray?.[0]?.description}
            src={imgArray}
            height={449}
            quality={1080} />
        </SliderWrapper>
      );
    }
  }

  return imageOrGallery || null;
};

export default Hero;
