import styled, { css } from "styled-components";

import colors from "../../../constants/colors";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const TextContainer: any = styled.div`
  color: #fff;
  text-decoration: none;
  padding: 0px 18px;
  background: rgba(0,0,0,.8);
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  height: 80px;
  width: 33.3%;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  visibility: hidden;
  margin-bottom: -80px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    visibility: visible;
    margin-bottom: 0px;
  }
  ::after { 
    border-style: solid;
    color: white;
    border-width: 3px 3px 0 0;
    content: '';
    display: inline-block;
    height: 55.5px;
    position: absolute;
    top: 10px;
    vertical-align: top;
    width: 55.5px;
    transform: scale(0.5,1) rotate(45deg);
    right: -10px;
    z-index: 2;
  }
`;

export const TextWrapper: any = styled.div`
  width: 50%;
  position: relative;
  margin-top: -80px;
  top: 100px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    top: 160px;
    width: 100%;
  }
`;
  
export const LastTextContainer: any = styled.div`
  color: #fff;
  text-decoration: none;
  padding: 0px 18px;
  background: rgba(0,0,0,.8);
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  width: 200%;
  height: 50px;
  font-size: 22px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  white-space: nowrap;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.3%;
    height: 80px;
  }
`;

export const textLight: any = css`
  font-size: 18px;
  font-weight: 100;
  font-family: Roboto, sans-serif;
  font-style: italic;
`;

export const textLightLast: any = css`
  font-size: 22px;
  font-weight: 100;
  font-family: Roboto, sans-serif;
  font-style: italic;
`;

export const ButtonContainer: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 200px;
  margin-bottom: 0px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    bottom: 170px;
  }
  
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    bottom: 160px;
  }
`;

export const Button: any = styled.div`
  width: fit-content;
  padding: 20px 33px;
  border-radius: 4px;
  background-color: ${colors.green};
  &:hover {
    {
      background-color: ${colors.light_green};
    }
  }
  `;

export const TextButton: any = styled.a`
  font-family: Roboto, sans-serif;
  font-size: 17.5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${colors.white};
`;

export const PromotionalOffertHeading: any = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadingText: any = styled.div`
  color: ${colors.dark_gray};
  font-family: Roboto, sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 16px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    font-size: 36px;
  }
`;
export const ImgWrapper: any = styled.div`
  width: 33.3%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
  }
`;
