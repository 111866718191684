import { css } from "styled-components";

import colors from "../../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../../constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  padding: 13px;
  flex-direction: column;
  border-radius: 16px;
`;

export const contentWrapper: any = css`
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    * {
      color: #3c3c3b !important;
    }
    h4 {
      color: ${colors.blue} !important;
      font-weight: 400;
      font-size: 19px;
      line-height: 110% !important;
    }
    a {
      display: block;
      margin: 10px 0 2px;
      text-decoration: underline;
    }
`;

export const img: any = css`
    width: 100%;
    height: 230px;
    img {
      width: 100%;
      height: 100%;
    }
`;

export const richText: any = css`
    overflow: hidden;
    max-height: 120px;
`;

