import React, { useContext, FunctionComponent, Fragment } from "react";
import { Context, ILandingEvent, RichText } from "src/common";

import {
  Wrapper,
  EventItem,
  EventIcon,
  LinkWrapper,
} from "./TeaserRight.style";
import { css } from "styled-components";

export const TeaserRight: FunctionComponent = () => {
  const props = useContext(Context);

  const eventsToRender = props?.PageLanding?.events?.filter((e: any) => {
    const eventItem: ILandingEvent = e;
    if (eventItem?.__typename !== "LandingEvent") {
      return false;
    } else if (!eventItem?.startDate && !eventItem?.endDate) {
      return true;
    } else if (!eventItem?.startDate && eventItem?.endDate) {
      return new Date() <= new Date(eventItem?.endDate);
    } else if (eventItem?.startDate && !eventItem?.endDate) {
      return new Date() >= new Date(eventItem?.startDate);
    } else {
      return (
        new Date() >= new Date(eventItem?.startDate) &&
        new Date() <= new Date(eventItem?.endDate)
      );
    }
  });

  const eList: ILandingEvent[] = eventsToRender as any;

  const events = eList?.map((e, index: number) => {
    const event = e;
    if (!event) {
      return;
    }

    return (
      <EventItem key={event?._meta?.id ? event._meta?.id : index}>
        {event?.icon ? (
          <Fragment>
            {event.icon?.length == 1 ? (
              <EventIcon
                src={event.icon[0]?.src}
                alt={event.icon[0].description}
              />
            ) : null}
            {event.icon?.length > 1 && (
              <div
                css={css`
                  display: flex;
                  gap: 20px;
                  img {
                    width: calc(${100 / event.icon.length}% - ${(event.icon.length -1) * 10}px)});
                  }
                `}
              >
                {event.icon.map((icon, i) => {
                  return (
                    <EventIcon key={i} src={icon?.src} alt={icon.description} />
                  );
                })}
              </div>
            )}
          </Fragment>
        ) : null}
        <LinkWrapper
          href={
            event?.linksTo?.[0]?.slug ? event?.linksTo?.[0]?.slug : undefined
          }
        >
          <h4>{event?.headline}</h4>
          <RichText textContent={event?.text} />
          {event?.linksTo?.[0]?.slug && (
            <a href={event?.linksTo?.[0]?.slug}>
              <img src="/images/kg_icon_link_arrow.jpeg" />
              mehr
            </a>
          )}
        </LinkWrapper>
      </EventItem>
    );
  });

  if (!events) {
    return null;
  }

  return <>{events}</>;
};

export default TeaserRight;
