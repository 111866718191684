import React, { FunctionComponent } from "react";
import Slider, { Settings } from "react-slick";
import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";

import { ImgWrapper } from "./FurtherAwards.style";
import * as styles from "./FurtherAwards.style";

const FurtherAwards: FunctionComponent<{awards: any[]}> = ({ awards }) => 
{
  const sliderSettings: Settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };
  return (
    <div css={`${SSlickBase}${SSlickTheme}${styles.wrapper}`}>
      <Slider {...sliderSettings}>
        {awards?.map((NewsItem, i) => (
          <ImgWrapper key={`further-key${i}`}>
            <img
              alt={NewsItem.description}
              src={NewsItem.src }
            />
          </ImgWrapper>
          
        ))}
      </Slider>
    </div>
  );

};

export default FurtherAwards;
