import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

import colors from "../../../constants/colors";

const gray = "#d3d3d3ff";

export const wrapper: any = css`
    position: fixed;
    bottom: 90px;
    transform: translateY(100%);
    overflow-x: hidden;
    background-color: white;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    box-shadow: 0 0 40px white;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: 650px;
        right: 13vh;
        max-width: 90vw;
        width: 570px;
    }
`;

export const topBar: any = css`
    color: white;
    padding: 15px 38px 18px 13px;
    cursor: pointer;
    height: 90px;
    background-color: ${colors.gray};
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 110%;
    }
    img {
      margin-left: 10px;
      width: 42px;
      height: auto;
      margin-top: 5px;
    }
    @media (min-width: 500px)
    {
        h3 {
          font-size: 20px;
          padding: 10px 90px 10px 0;
        }
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        img {
          width: 50px;
          margin-left: 15px;
        }
        h3 {
          font-size: 20px;
          padding: 10px 90px 10px 0;
        }
    }
`;

export const closeButton: any = css`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 11;
    cursor: pointer;
    fill: white;
    width: 15px;
    &:hover {
      opacity: .6;
    }
`;

export const contentArea: any = css`
    border: solid 1px ${gray};
    overflow-y: auto;
    max-height: 80vh;
    padding: 13px;
    border-bottom: none;
    p {
      border-bottom: solid ${gray} 1px;
      color: #000;
      font-family: arial;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 13px;
      padding-bottom: 20px;
    }
`;

export const formRow: any = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > div {
      width: 49%;
    }
    label {
      color: #000;
      cursor: pointer;
      display: block;
      font-family: arial;
      font-size: 14px;
      font-weight: 600;
    }
    input, select, textarea {
      width: 100%;
      height: 30px;
      padding: 3px 2px;
      display: block;
      border: solid ${gray} 1px;
      &::placeholder {
        font-size: 13px;
      }
    }

    textarea {
      height: 100px;
      resize: vertical;
    }
`;

export const submitWrapper: any = css`
    display: flex;
    button {
      margin-right: 30px;
      padding: 4px 6px;
      background-color: black;
      color: white;
    }
    label, a {
      font-family: arial;
      cursor: pointer;
      font-size: 16px;
    }
    input {
      margin-right: 3px;
    }
    a {
      color: #81c7dc;
    }
    label {
      color: #666;
    }
`;
