import React, { FunctionComponent, useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context, IAsset } from "src/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Wrapper } from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";
import { css } from "styled-components";

export const CSSArrow = css`
&:before {
  display: none;
}
right: unset !important;
svg {
  height: 28px;
  width: 32px;
  color: #F19024;
}
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
      css={css`
        ${CSSArrow}
      `}
    >
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      css={css`
        ${CSSArrow}
      `}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
    </div>
  );
}

const NewSlider: FunctionComponent<{ newsCustom?: boolean }> = ({
  newsCustom,
}) => {
  const p = useContext(Context);
  const News = newsCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
  const { NewsSlug } = p;
  if (!News || News.length <= 0) {
    return null;
  }

  const NewsToRender = News?.filter((NewsItem) => {
    if (!NewsItem) {
      return false;
    }
    if (!NewsItem?.startDate && !NewsItem?.endDate) {
      return true;
    } else if (!NewsItem?.startDate && NewsItem?.endDate) {
      return new Date() <= new Date(NewsItem?.endDate);
    } else if (NewsItem?.startDate && !NewsItem?.endDate) {
      return new Date() >= new Date(NewsItem?.startDate);
    } else {
      return (
        new Date() >= new Date(NewsItem?.startDate) &&
        new Date() <= new Date(NewsItem?.endDate)
      );
    }
  });

  const sliderSettings: Settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // nextArrow: <i className="fas fa-long-arrow-alt-right fa-2x slick-next"/>,
    // prevArrow: <i className="fas fa-long-arrow-alt-left fa-2x slick-next"/>,
    // onInit: () => {
    // //   $('.slick-slider').slick({
    // //     nextArrow: '<i class="fas fa-arrow-circle-right slick-next"></i>',
    // //     prevArrow: '<i class="fas fa-arrow-circle-left slick-prev"></i>',
    // // });
    //   console.log(` slickinit`, );
    // },
    responsive: [
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Slider {...sliderSettings}>
        {NewsToRender.map((NewsItem, i) => (
          <NewSlide
            key={i + NewsItem?.id}
            index={i}
            totalCount={NewsToRender.length}
            img={NewsItem?.extras as IAsset[]}
            imgAlt={(NewsItem?.extras as IAsset[])?.[0]?.description}
            title={NewsItem?.headline}
            text={NewsItem?.text}
            link={NewsSlug && NewsSlug + "/" + NewsItem?.id}
          />
        ))}
      </Slider>
    </Wrapper>
  );
};

export default NewSlider;
