import React, { useState } from "react";

import Img from "../../../BaseComponents/Img";
import {
  PromotionalOfferButtom,
  PromotionalOfferContentWrapper,
  PromotionalOfferContent,
  PromotionalOfferButtomMobile,
} from "./PromotionalOffert.style";

const PromotionalOffer: any = ({ promotionalOffer }) => 
{
  const [isActive, toogleActive] = useState(false);
  if (!promotionalOffer) return null;
  
  return (
    <>
      <PromotionalOfferButtomMobile href={promotionalOffer?.slug}>MUSTERKÜCHEN ZUM ABVERKAUE</PromotionalOfferButtomMobile>
      <PromotionalOfferContentWrapper active={isActive}>
        {isActive && (
          <PromotionalOfferContent>
            <p onClick={() => toogleActive(!isActive)}>Schließen <i className="fa fa-times"/></p>
            <a href={promotionalOffer?.slug}>
              <Img
                alt={"item.title"}
                src={promotionalOffer?.images}
                quality={1080} 
                height={360}/>
            </a>
                  
          </PromotionalOfferContent>
        )}
      </PromotionalOfferContentWrapper>

      <PromotionalOfferButtom onClick={() => toogleActive(!isActive)} active={isActive}>
        <p>Angebote</p>
      </PromotionalOfferButtom>
    </>
  );
};

export default PromotionalOffer;
