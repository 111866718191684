import React, { FC } from "react";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";


// https://60578-vorschau.kuechengilde.de/
const PageLandingT20: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Banner />
    <Footer />
     
  </>
);

PageLandingT20.displayName = "PageLandingT20";

export { PageLandingT20 };
