import React, { FunctionComponent, useContext, Fragment } from "react";
import {
  Context,
  LandingBannerLeft,
  IRichLinkItem,
  IAsset,
  ICallToAction,
  IPage,
  IThemingColor,
  ISlider,
} from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";
import { css } from "styled-components";
import ImageWithAspectRatio from "../../../../../common/BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../../BaseComponents/RichText/RichText";
import {
  centeredContent,
  flexItem100,
  flexItem50,
  flexItem25,
  flexWrapper,
} from "../../../constants/layout";
import { FooterT5 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import Slider from "../../elements/Slider/Slider";
import * as styles from "./Landing.page.style";
import { NewSlider } from "./NewSlider/NewSlider";

const PageLandingT5: FunctionComponent<any> = () => {
  const props = useContext(Context);
  const landingPageData = props.PageLanding;

  const landingImage = landingPageData?.elements?.find(
    (elements) => elements?.__typename === "Asset"
  ) as IAsset;

  const ctaBoxes = landingPageData?.elements.filter(
    (e) => e?.__typename === "CallToAction" && !!e
  ) as ICallToAction[];
  const gallery = landingPageData?.elements?.filter(
    (e) => e?.__typename === "RichLinkItem"
  ) as IRichLinkItem[];

  const slider = landingPageData?.elements?.find(
    (field) => field?.__typename === "Slider"
  ) as ISlider;

  const isNewsCustom = landingPageData?.NewsCustom.length > 0;

  return (
    <div id="PageLandingT5">
      <div>
        <LandingBannerLeft />
      </div>
      <div>
        <ProductLandingBanner />
      </div>
      <div css={centeredContent}>
        <Header />
        <div css={flexWrapper}>
          {slider && <Slider {...slider}></Slider>}
          {landingImage && (
            <div
              css={`
                ${flexItem100}${styles.landingImageWrapper}
              `}
            >
              <ImageWithAspectRatio
                src={landingImage?.src}
                bronze={1 / 2}
                alt={landingImage?.description || "bild"}
                srcSetWidths={{
                  bronze: 720,
                  silver: 1440,
                  gold: 1920,
                  diamond: 3600,
                }}
              />
            </div>
          )}
          {ctaBoxes?.map((ctaBox, index) => (
            <div
              key={`ctabox-${index}`}
              css={`
                ${ctaBoxes.length >= 2
                  ? flexItem50
                  : flexItem100}${styles.ctaBox};
                ${index % 2 === 0
                  ? ""
                  : css`
                      background-color: #ee765e;
                      a {
                        color: #ee765e;
                      }
                    `};
              `}
            >
              <RichText textContent={ctaBox.TextAboveButton}></RichText>
              <a href={(ctaBox.linkTo[0] as any)?.slug}>{ctaBox.ButtonLabel}</a>
              <RichText textContent={ctaBox.TextBelowButton}></RichText>
            </div>
          ))}
        </div>
        <div
          css={`
            ${flexWrapper}${styles.galleryWrapper}
          `}
        >
          <div css={flexItem25}>
            <Img
              quality={700}
              css={styles.galleryItemIcon}
              src="/images/news.png"
              alt="icon"
            />
          </div>

          <div css={flexItem25}>
            <NewSlider />
          </div>

          {isNewsCustom && (
            <>
              <div css={flexItem25}>
                <NewSlider newCustom={isNewsCustom} />
              </div>
              <div css={flexItem25}></div>
            </>
          )}
          {gallery &&
            gallery.length > 0 &&
            gallery?.map((galleryItem, i) => {
              const link = galleryItem?.linkTo?.find(
                (itemLink) => itemLink?.__typename === "Page"
              ) as IPage;
              const color = link?.elements?.find(
                (e) => e?.__typename === "ThemingColor"
              ) as IThemingColor;
              return (
                <Fragment key={i}>
                  <div
                    css={flexItem25}
                    style={
                      color
                        ? { backgroundColor: color.color, padding: 0 }
                        : { backgroundColor: "#E4EDF4", padding: 0 }
                    }
                  >
                    {link?.slug ? (
                      <a href={link?.slug}>
                        {galleryItem.image && (
                          <Img
                            quality={700}
                            css={styles.galleryItemIcon}
                            src={galleryItem.image?.[0]?.src}
                            alt={galleryItem.image?.[0]?.description}
                          />
                        )}
                      </a>
                    ) : (
                      <Fragment>
                        {galleryItem.image && (
                          <Img
                            quality={700}
                            css={styles.galleryItemIcon}
                            src={galleryItem.image?.[0]?.src}
                            alt={galleryItem.image?.[0]?.description}
                          />
                        )}
                      </Fragment>
                    )}
                  </div>
                  <div
                    css={flexItem25}
                    style={
                      color
                        ? { backgroundColor: color.color }
                        : { backgroundColor: "#E4EDF4" }
                    }
                  >
                    {galleryItem.headline && <h4>{galleryItem.headline}</h4>}
                    {galleryItem.richDesc && (
                      <div css={styles.galleryItemText}>
                        <RichText textContent={galleryItem.richDesc}></RichText>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })}
          <div css={flexItem25} style={{ backgroundColor: "#83D0F5" }}>
            <h4>Marken</h4>
            <p css={styles.galleryItemText}>
              In unserem Marken-Portfolio bieten wir Ihnen die meisten bewährten
              Marken an.
            </p>
          </div>
          <div css={flexItem25} style={{ backgroundColor: "#83D0F5" }}>
            <div css={styles.brands}>
              {props?.AllBrand?.filter(b => !!b?.logo?.[0]?.src)?.map((brand, i) => (
                <Img
                  quality={150}
                  key={i}
                  src={brand.logo?.[0]?.src}
                  alt={"logo"}
                />
              ))}
            </div>
          </div>
        </div>
        <div css={flexWrapper}>
          <FooterT5 />
        </div>
      </div>
    </div>
  );
};
PageLandingT5.displayName = "PageLandingT5";
export { PageLandingT5 };
