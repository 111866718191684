import React, { FC, useContext } from "react";
import {
  Context,
  IAsset,
  ISlider,
  ITextElement
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import Img from "../../../../BaseComponents/Img";
import { FadeSliderWrapper, HeroWrapper } from "./Hero.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  const title = props?.PageLanding?.elements?.find(element => element?.__typename === "TextElement") as ITextElement;

  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSliderWrapper><FadeSlider elements={slider?.[0]?.elements} /></FadeSliderWrapper>;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSliderWrapper><FadeSlider elements={[...imgArray?.map(f => f)]} /></FadeSliderWrapper>;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <FadeSliderWrapper><FadeSlider elements={imgArray} /></FadeSliderWrapper>;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
        > </Img>
      );
    }
  }

  return (
    <HeroWrapper>
      {imageOrGallery}
      {title?.headline && <h1 className="title">{title.headline}</h1>}
    </HeroWrapper>
  );
};

export default Hero;
