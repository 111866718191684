import styled from "styled-components";

import colors from "../../../../constants/colors";
import SSlickBase from "../../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../../utils/slick/SSlickTheme";

export const Wrapper: any = styled.div<{background: string; dotColors: string}>`
    ${SSlickBase};
    ${SSlickTheme};
    background-color: ${({ background }) => background ? background : colors.gray};
    padding-bottom: 16px;
    margin-bottom: 25px;
    .slick-dots {
        position: relative !important;
        bottom: auto !important;
        margin-top: 10px;
        li {
          margin: 0 3px;
        }
        li button:before {
          border-radius: 50%;
          background-color: transparent;
          border: solid ${({ dotColors }) => dotColors ? dotColors : "white"} 1px;
          opacity: 1 !important;
          width: 15px !important;
          height: 15px !important;
        }
        li.slick-active button:before {
          background-color: ${({ dotColors }) => dotColors ? dotColors : "white"};
        }
    }
`;
