import React, { FC } from "react";
import { Grid } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";
import { LandingBannerLeft } from "src/common";

// https://60026-vorschau.dk-das-kuechenhaus.de/
const PageLandingT9: FC = () => (
  <>
    <Header />
    <Grid>
      <LandingContent />
    </Grid>
    <Banner />
    <Footer />
     
    <LandingBannerLeft />
  </>
);

PageLandingT9.displayName = "PageLandingT9";

export { PageLandingT9 };
