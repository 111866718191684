import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
    margin-bottom: 21px;
`;

export const eventItem: any = css`
    display: flex;
    border-radius: 10px;
    background-color: ${props => props.theme.palette.bgLight};
    width: 100%;
    margin-bottom: 10px;
    margin-top: 21px;
`;

export const eventIcon: any = css`
      margin-left: 5px;
      transform: translateY(-10px);
      height: 70px;
      width: 70px;
      min-width: 70px;
`;

export const teaserTextWrapper: any = css`
    padding: 5px 30px 5px 10px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    witdh: 100%;
    h3 {
    font-size: 21px;
    font-weight: 700;
    }
    p {
    witdh: 100%;
    /* color: ${props => props.theme.palette.textColor1}; */
    display: inline;
    font-size: 12px;
    white-space: break-spaces;
    }
    .afterArrow:after {
    background-image: url("/images/sprite-s82c5aa31b4.png");
    height: 15px;
    width: 15px;
    display: inline-block;
    content: "";
    transform: translate(6px, 3px);
    }
    &:hover {
      text-decoration: underline;
    }
`;
