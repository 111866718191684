// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";


export const Slide: any = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  ${({ withImage }) => withImage && 'justify-content: flex-start;'}
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 174px;
  }
`;

export const contentWrapper: any = css`
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    max-height: 196px;
    .icon {
      color: ${(props) => props.theme.palette.textColor};
      font-size: 13px;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
    }
`;

export const img: any = css`
    object-fit: cover;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
      margin-right: 25px;
    }
`;

export const counter: any = css`
    font-size: 13px;
`;

export const content: any = css`
    color: ${(props) => props.theme.palette.primary};
    line-height: 140%;
    overflow: hidden;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.palette.textColor};
    }
    h3 {
      font-size: 18px;
      margin-bottom: 0px;
      font-weight: bold;
      text-decoration: inherit;
    }
  
    h6, h2, h4, p {
      color: ${(props) => props.theme.palette.textColor};
      font-size: 13px;
      font-weight: 400;
      strong {
        font-weight: 400;
      }
    }
`;
