import React from "react";
import { LandingBannerLeft } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";
import { Banner } from "src/templates/t1/components/common/Banner/Banner";
import { Footer } from "src/templates/t1/components/common/footer/Footer";

import { LandingContent } from "../../common/LandingContent/LandingContent";
import MainNavigation from "../../common/MainNavigation/MainNavigation";


const PageLandingT1 = () => 
  (
    <>
      <div>
        <ProductLandingBanner />
      </div>
      <div>
        <Banner />
        <LandingBannerLeft />
      </div>
      <div>
                 
        <MainNavigation />
        <LandingContent />
        <Footer />
      </div>
    </>
  );
PageLandingT1.displayName = "PageLandingT1";
export { PageLandingT1 };
