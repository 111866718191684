import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

interface IProps 
{
  isClickable: boolean;
}

const hoverEffect = css`
  &:hover {
    h3 {
      opacity: 0.7;
    }
  }
`;

const Default = css<IProps>`
  background-color: var(--primary-100);
  margin-bottom: 20px;

  > .landing__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      color: var(--back-1);
      transition: color 0.3s ease-in;
    }

    ${({ isClickable }) => isClickable && hoverEffect};
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  margin-bottom: 35px;

  > .landing__content {
    gap: 20px;
  }
`;

const Diamond = css``;

export const SLandingEvent = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
