/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// @ts-nocheck
import objectFitImages from "object-fit-images";
import React, { FC } from "react";
import LazyLoad from "react-lazyload";

import SImg from "./Img.styles";
String.prototype.reverse = function () {
  return this.split("")
    .reverse()
    .join("");
};

String.prototype.replaceLast = function (what, replacement) {
  return this.reverse()
    .replace(new RegExp(what.reverse()), replacement.reverse())
    .reverse();
};

class ImgInner extends React.PureComponent {
  imgRef = React.createRef();

  state = {
    loaded: false
  };

  componentDidMount() {
    if (this.imgRef.current) {
      objectFitImages(this.imgRef.current);
      if (this.imgRef.current.complete) {
        this.setState({ loaded: true });
        this.props.onLoad && this.props.onLoad();
      }
      else {
        this.imgRef.current.onload = () => {
          objectFitImages(this.imgRef.current);
          this.setState({ loaded: true });
          this.props.onLoad && this.props.onLoad();
        };
      }
    }
  }

  componentWillUnmount() {
    this.imgRef.current.onload = null;
  }

  render() {
    const {
      src, alt, quality, children
    } = this.props;

    const actualSrc: string = src?.[0]?.src;
    const { loaded } = this.state;
    const _src = actualSrc
      ? actualSrc
        .replace("http:", "https:")
        .replace("res.cloudinary.com", "d5u6artv10pna.cloudfront.net")
        .replaceLast("/upload", `/upload/w_${quality || "50"},f_auto`)
      : "";
    return (
      <SImg loaded={loaded} {...this.props}>
        <img css={this.props.css} src={_src} alt={alt} ref={this.imgRef} />
        {children}
      </SImg>
    );
  }
}

export interface IImgProps {
  src: IAsset[] /** link to file */;
  children?: any;
  alt?: string;
  placeholderHeight?: string /** lazyload preloader height ==> default: 0 */;
  height?: number;
  offset?: string /** offset lazyload trigger ==> default: 0 */;
  quality?: number /** set the quality of the image in px/width ==> default: 50 */;
  lazyload?: boolean /** should the image be lazyloaded on scroll? ==> default: true */;
  onLoad?: () => void /** trigger when image is loaded ==> default: null */;
}

const Img: FC<IImgProps> = ({ lazyload = true, ...props }: IImgProps) => {
  if (lazyload) {
    return (
      <LazyLoad height={props.placeholderHeight ? `${props.placeholderHeight}` : undefined} offset={props.offset}>
        <ImgInner {...props} />
      </LazyLoad>
    );
  }
  return <ImgInner {...props} />;
};

export default Img;
