import React, { FC } from "react";
import { IAsset } from "src/common";
import { Img } from "src/common/components/Img";

import {
  ProductContainer,
  ItemDetails,
  ItemPicture,
  Button,
  PromotionalOffertWrapper
} from "./PromotionalOffert.style";
export interface IsContent 
{
  carouselContent: object;
}

export interface ISlider 
{
  items: IAsset[];
}

const PromotialOffert: FC<{ items: any[] }> = props => 
{ 
  // const itemsList = [props.items[1], ...props.items, props.items[1], ...props.items, props.items[1], ...props.items, props.items[1], ...props.items, ...props.items, props.items[1], ...props.items, ...props.items, ...props.items, ...props.items, ...props.items, props.items[1]];
  return (
    <PromotionalOffertWrapper >
      {/* {itemsList.map((element, i) =>  */}
      {props.items.map((element, i) => 
      {
        const itemWidth = i < 1 || (i % 5 === 0) ? "66.25%" : "32.5%"; 
        return (
          <ProductContainer itemWidth={itemWidth} key={`promotional-offer-${i}`}> 
            <ItemPicture data-promotional-offer-item-picture>
              <Img quality={1600} key={i} src={element?.images} alt={element?.productDescription ?? "image"} />
            </ItemPicture>
            <ItemDetails data-promotional-offer-item-details>
              <h3><a href={`/produkt/${element.slug}`}>{element.title}</a></h3>
              <Button href={`/produkt/${element.slug}`}>
                  Details
              </Button>
            </ItemDetails>
          </ProductContainer>
        );
      })}
    </PromotionalOffertWrapper>
  );
};

export default PromotialOffert;

