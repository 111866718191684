import {
  H3_1366,
  H3_360,
  H3_768,
} from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
  ${H3_360}
  padding: 12px 15px 0 15px;
`;

const Silver = css`
  ${H3_768}
`;

const Gold = css``;

const Platinum = css`
  ${H3_1366}
  padding: 24px 24px 0 24px;
`;

const Diamond = css``;

export const SLandingEventHeadline = styled.h3`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
