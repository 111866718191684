import { css } from "styled-components";

export const wrapper: any = css`
  .slick-dots {
      position: static;
      margin-top: 20px;
      li {
        margin: 3px;
        width: 12px;
        height: 12px;
        & > button {
          width: 12px;
          height: 12px;
          &:before {
            opacity: 1;
            width: 8px;
            height: 8px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #ddd;
            border: 2px solid #ddd;
            color: transparent;
            border-radius: 12px;
          }
        }

        &.slick-active > button:before  {
          border-color: #adadad;
          background-color: #fff;
        }
      }
  }
`;
