import React, { FC, useContext, useState } from "react";
import {
  Context,
  ITextElement,
  ICallToAction,
  LogoBannerHorizontal,
  LandingBannerLeft,
} from "src/common";

import { ContactData } from "../../common/Contact";
import FurtherAwards from "../../common/FurtherAwards";
import { Section, Container, ContainerLarge } from "../../common/Grid";
import Hero from "../../common/Hero";
import Map from "../../common/Map";
import NewSlider from "../../common/NewSlider";
import PlanningDateModal from "../../common/PlanningDateModal";
import PromotialOffert from "../../common/PromotionalOffert";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import {
  HeroContainer,
  HeroCallToAction,
  HeroContent,
  MainTextContainer,
  PromotionalOfferContainer,
  NewsContainer,
  FurtherAwardsContainer,
  ContactContainer,
  bannerLeftCss,
} from "./LandingContent.style";

const LandingContent: FC = () => 
{
  const [isShowModal, setShowModal] = useState(false);
  const props = useContext(Context);
  const { PageLanding: page } = props;

  const [heroTitlePrimary, heroTitleSecondary] = page?.events;
  const [mainText, promitionalPOfferText, newsText] = page?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];
  const [callToAction] = page?.elements?.filter(e => e?.__typename === "CallToAction") as ICallToAction[];
  const promotionalOffert = page.promotionalOffer;
  const isNewsCustom = page?.NewsCustom?.length > 0;

  return (
    <Section>
      <HeroContainer>
        <Hero page={page}/>

        <HeroCallToAction>
          <ContainerLarge>
            <HeroContent>
              {heroTitlePrimary?.headline &&<h1>{heroTitlePrimary.headline}</h1>}
              <RichTextGeneral textContent={heroTitlePrimary?.text} />
            </HeroContent>

            <HeroContent>
              { heroTitleSecondary?.headline && <h2>{heroTitleSecondary?.headline}</h2>}
              {heroTitleSecondary?.linksTo?.[0]
                ? (
                  <a href={heroTitleSecondary?.linksTo?.[0]?.slug}>
                    <RichTextGeneral textContent={heroTitleSecondary?.text} />
                  </a>
                )
                : (
                  <RichTextGeneral textContent={heroTitleSecondary?.text} />
                )}
            </HeroContent>

          </ContainerLarge>
          
        </HeroCallToAction>
        <FurtherAwardsContainer>
          <FurtherAwards />
        </FurtherAwardsContainer>
      </HeroContainer>

      <MainTextContainer>
        <h1>{mainText?.headline}</h1>
        <RichTextGeneral textContent={mainText?.text} />
        {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
          <a href={"https://www.kuechenkompass.com/"} className="kitchen-compass">
            <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
          </a>
        ) : null}
      </MainTextContainer>

      <PromotionalOfferContainer>
        <Container>
          <h2>{promitionalPOfferText?.headline}</h2>
          <RichTextGeneral textContent={promitionalPOfferText?.text}/>
        </Container>
        <PromotialOffert items={promotionalOffert}/>
      </PromotionalOfferContainer>

      <NewsContainer>
        <Container>
          <h2>{newsText?.headline}</h2>
          <RichTextGeneral textContent={newsText?.text} />
        </Container>
        <NewSlider />
        {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
      </NewsContainer>

      <ContactContainer>
        <ContactData />
      </ContactContainer>
      <Map />
      {/* <LandingBannerLeft overrideStyles={bannerLeftCss} /> */}
    </Section>
  );
};

export default LandingContent;
