import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: auto;
  padding: 0 10px;
`;

export const contentWrapper: any = css`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
  }
`;

export const sideBar: any = css`
  max-width: initial;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 500px;
    padding-left: 20px;
  }
`;

export const serviceNumber: any = css`
  margin-bottom: 21px;
`;

export const mainText: any = css`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    max-width: 60%;
  }
  .h1-sub {
    diplay: block;
    margin-top: -21px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.16667;
    color: ${({ theme }) => theme?.palette?.black};
  }
`;
