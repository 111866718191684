import React, { FunctionComponent, useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context, IAsset } from "src/common";

import { Wrapper } from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

export const NewSlider: FunctionComponent<{newsCustom?: boolean}> = ({ newsCustom }) =>
{
  const p = useContext(Context);
  const News = newsCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
  const { NewsSlug } = p;
  if(!News || News.length <= 0)
  {
    return null;
  }

  const NewsToRender = News?.filter(NewsItem =>
  {
    if(!NewsItem) 
    {
      return false;
    }
    if(!NewsItem?.startDate && !NewsItem?.endDate)
    {
      return true;
    }
    else if(!NewsItem?.startDate && NewsItem?.endDate)
    {
      return new Date() <= new Date(NewsItem?.endDate);
    }
    else if(NewsItem?.startDate && !NewsItem?.endDate)
    {
      return new Date() >= new Date(NewsItem?.startDate);
    }
    else
    {
      return (
        new Date() >= new Date(NewsItem?.startDate)
                && new Date() <= new Date(NewsItem?.endDate)
      );
    }
  });

  const sliderSettings: Settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 667,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Wrapper>
      <Slider {...sliderSettings}>
        {NewsToRender.map((NewsItem, i) => (
          <NewSlide
            key={i+NewsItem?.id}
            index={i}
            totalCount={NewsToRender.length}
            img={(NewsItem?.extras as IAsset[])}
            imgAlt={(NewsItem?.extras as IAsset[])?.[0]?.title}
            title={NewsItem?.headline}
            text={NewsItem?.text}
            link={NewsSlug && (NewsSlug + "/" + NewsItem?.id)}
          />
        ))}
      </Slider>
    </Wrapper>
  );
};
