import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { theme } from "../../../constants/themes";

export const wrapper: any = css`
  width: 100%;
  margin: auto;
  .slick-prev:before,
  .slick-next:before {
    color: #f19024;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    width: 85%;
  }
  h1 {
    color: #f19024;
    font-size: 30px;
    line-height: 56.25px;
    margin: 0 0 25px;
    text-align: center;
    width: 100%;
  }
`;

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  background: transparent url("/images/bg_paper.png") repeat -100px 0;
  background-size: 60%;

  div.spacer {
    width: 100%;
    height: 30px;
  }
`;

export const WhiteStripe = styled.div`
  width: 100%;
  background-color: white;
`;

export const ContentWrapper: any = styled.div<{ row: boolean }>`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ row }) => row && "display: flex;"}
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    margin: 0 auto;
    max-width: ${theme.maxWidth}px;
  }
`;

export const sideBar: any = css`
  width: 100%;
  max-width: initial;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0px 11px;
    width: 50%;
    margin-left: auto;
    max-width: 500px;
  }
`;

export const mainText: any = css`
  width: 100%;
`;

export const bannerHorizontal: any = css`
  margin: -10px 0 -20px;
`;

export const HeroWrapper: any = styled.div`
  position: relative;
  /* background-image: url("/images/bg_paper.png"); */
  width: 100%;
  display: flex;
  align-items: flex-end;

  img {
    margin-top: calc(112px);
  }
  div.middle {
    width: 100%;
    height: 210px;
    margin-top: 125px;
    /* display: none; */
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    img {
      margin-top: 0;
    }
    div.middle {
      display: block;
      background-color: #f19024;
      margin-top: 200px;
      height: 420px;
    }
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    div.middle {
      margin-top: 220px;
      height: 420px;
    }
    > div:first-child {
      max-width: 89vw;
    }
  }
`;

export const FurterAwardsWrapper: any = styled.div`
  width: 100%;
  height: 330px;
  /* background-image: url("images/bg_paper.png"); */
  padding: 0 10px;
`;

export const ImgWrapper: any = styled.div`
  img {
    width: 360px;
    height: 160px;
  }
`;

export const ContentTextWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 15px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TextContent: any = styled.div`
  width: 100%;
  padding: 0px 0 0;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    padding-left: 10px;
  }

  h1 {
    font-family: franklin_gothic_fs_bookRg, Helvetica, Arial, sans-serif;
    color: #f19024;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0.02em;
    font-size: 22px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      font-size: 40px;
    }
  }
  a {
    color: #bcbcbc;
  }
  p {
    color: ${theme.palette.dark_gray};
    padding-left: 5px;
    span {
      font-family: franklin_gothic_fs_bookRg, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.4px;
      font-weight: 300;
    }
  }
`;

export const Image1: any = styled.div`
  background-image: url(images/welcome_image_left.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 80%;

  width: 25%;
  margin-top: 210px;
  display: none;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: block;
  }
`;

export const Image2: any = styled.div`
  width: 75%;
  height: 40px;
  margin-top: 20px;
  overflow: hidden;
  a {
    display: block;
    background: transparent url(/images/termin_btn.png) no-repeat 0 0;
    width: 100%;
    height: 155px;
    background-size: 100%;
    margin-left: 35px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 25%;
    height: 155px;
    margin-top: 210px;
  }
`;

export const IconWrapper: any = styled.div`
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  img {
    width: 40px;
    height: 65px;
  }
`;

export const PhoneNumberWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 445px;
  margin-right: -80px;
  position: relativ;
  @media (max-width: 500px) {
    img{
      margin-top: 200px;
      max-width: 60px;
      object-fit: contain;
    }
  }

  img {
    /* top: 1570px; */
    margin-top: 190px;
    right: 15px;
    position: absolute;
    width: 80px;
    height: 100px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding-left: 156px;
    img {
      /* top: 93px; */
      right: 220px;
      position: relative;
      width: 156px;
      height: 202px;
    }
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    width: 100%;
    padding: 0;
    margin: auto;
    img {
      /* top: 1600px; */
      right: max(32vw , 430px);
      position: absolute;
      width: 156px;
      height: 202px;
    }
  }
`;

export const PhoneNumberContent: any = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  h1 {
    color: #f19024;
    font-size: 30px;
    line-height: 56.25px;
    margin: 0 0 25px;
    text-align: center;
    width: 100%;
  }
  h3 {
    color: #5e5e5d;
    line-height: 28px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 710px;
    height: 245px;
    h1 {
      padding: 40px 240px 0 0;
    }
    h3 {
      padding-left: 80px;
      margin-bottom: 0;
      text-align: left;
    }
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    width: 100%;
    padding: 0;
    h1 {
      padding: 40px 0 0;
      width: fit-content;
      margin: auto;
      text-align: center;
    }
    h3 {
      padding-left: 0;
      width: fit-content;
      margin: auto;
      text-align: center;
    }
  }
`;

export const TitleWrapper: any = styled.div<{ dark: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;

  h1 {
    color: ${({ dark }) => (dark ? "#5E5E5D" : "#F19024")};
    font-size: 24px;
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    line-height: 60px;
    padding-left: 10px;
  }
  img {
    width: 31px;
    height: 49px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding-top: 40px;
    padding-left: 0;
    h1 {
      padding-left: 20px;
      font-size: 32px;
    }
  }
`;

export const ContactUsWrapper: any = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 155px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

export const ContactUsContent: any = styled.div`
  width: 100%;
  min-height: 171px;
  background-color: #f19024;
  margin: 0 10px 24px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(50% - 20px);
  }
`;

export const ContactUsContentCard: any = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 40px 20px 40px 20px;
  h2 {
    font-size: 24px;
    font-weight: 100;
    line-height: 45px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 40px 60px 40px 20px;
  }
`;

export const Icon: any = styled.div`
  text-align: right;
  display: none;
  width: 100px;
  font-size: 55px;
  padding: 0 30px 0 0;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: initial;
  }
`;

export const TextAndButton: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
    width: fit-content;
    padding: 8px;
    margin: 8px 0 0;
    border: 1px solid white;
  }
`;

export const AddressForm: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    width: 100%;
  }
  input {
    widht: 100%;
    background-color: #f19024;
    border: 1px solid white;
    height: 60px;
    padding: 0 16px;
    margin: 0 0 2px;
    font-size: 18px;
    color: white;
    &::placeholder {
      font-size: 18px;
      color: white;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 100%;
  }
`;
