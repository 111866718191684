import styled, { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";

export const Wrapper: any = styled.div`
  ${SSlickBase};
  ${SSlickTheme};
  .slick-prev:before,.slick-next:before{
    color: ${(props: any) => props.theme.primary || "#AAD4CF"} !important;
  }
  .slick-dots {
    @media (${BREAKPOINTS_DM.silver_768}) {
      top: 330px;
    };
    @media (${BREAKPOINTS_DM.gold_1024}) {
      top: 455px;
    };
    @media (${BREAKPOINTS_DM.platinum_1366}) {
      top: 665px;
    };
  }
  
`;

export const CarouselContent: any = styled.div`
  background-color: transparent;
  position: relative;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  top: -470px;
  margin-bottom: -410px;
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    position: absolute;
    top: 125px;
  };
  @media (${BREAKPOINTS_DM.gold_1024}) {
    position: absolute;
    top: 245px;
  };
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    position: absolute;
    top: 505px;
  };
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    width: 100%;
  }
`;

export const TextContent: any = styled.p`
  text-shadow: 0 0 4px #000;
  text-align: center;
  font-family: Roboto,sans-serif;
  width: 80%;
  color: white;
  font-size: 30px;
  letter-spacing: 0px;
  margin: 40px auto;
  @media (${BREAKPOINTS_DM.silver_768}) {
    font-size: 50px;
    letter-spacing: 0.12px;
    margin: auto;
  };
`;

export const ButtonsContainer: any = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 45px auto;
    flex-direction: row;
  };
`;

export const CarouselButton: any = styled.div<{textColor: string; bgColor: string}>`
  margin: 10px 0px;
  width: 92%;
  color: ${(props: any) => props.textColor};
  background-color: ${(props: any) => props.bgColor};
  border-radius: 4px;
  a {
    color: ${(props: any) => props.textColor}; 
    font-family: Roboto,sans-serif;
    font-size: 17px;
    line-height: 1.4;
    font-weight: 600;
  }
  &:hover {
    a {
      color: ${(props: any) => props.textColor === colors.green && colors.white};
      opacity: 1;
    };
    {
      background-color: ${colors.light_green};
    };
  };
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: fit-content;
    margin: 0px 6px;
  };
`;

export const ButtonContent: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16.5px 30px;
  font-family: Roboto,sans-serif;
  line-height: 1.4;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0.8px;
  color: ${(props: any) => props.textColor};
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: fit-content;
    display: content;
  };
`;
