import React, { FC, useContext, Fragment } from "react";
import {
  Context,
  LogoBannerHorizontal,
  ITextElement,
  IAsset,
  ISlider
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import { Section } from "src/templates/t9/BaseComponents/Grid";

import Img from "../../../BaseComponents/Img";
import FurtherAwards from "../../common/FurtherAwards";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import ServiceNumber from "../../common/ServiceNumber";
import TeaserRight from "../../common/TeaserRight";
import * as styles from "./LandingContent.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];

  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
        />
      );
    }
  }

  return imageOrGallery ?? null;
};

const LandingContent: FC = () =>
{
  const props = useContext(Context);
  const isNewsContent = props?.PageLanding?.NewsCustom?.length > 0;
  return (
    <>
      <div css={styles.wrapper}>
        <Section>
          <Hero />
          <div css={styles.contentWrapper}>
            <div css={styles.leftContent}>
              {(props.PageLanding?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[])?.map(
              (t, i) => (
                <RichTextGeneral
                  key={`rt0-${i}`}
                  textContent={t.text}
                  css={styles.mainText}
                >
                  <h1>{t?.headline}</h1>
                </RichTextGeneral>
              )
            )}

              {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
                <a href={"https://www.kuechenkompass.com/"} className="kitchen-compass">
                  <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
                </a>
              ) : null}
            </div>
            <div css={styles.sideBar}>
              {props?.CompanyInformationPublic?.serviceNumber && <ServiceNumber />}
              <TeaserRight />
              <NewSlider />
              {isNewsContent && <NewSlider newsContent={isNewsContent}/>}
              <FurtherAwards />
            </div>

          </div>
        </Section>
        <div css={styles.bannerHorizontal}>
          <LogoBannerHorizontal shadowOverlayVisible={true} />
        </div>
      </div>
    </>
  );
};

export default LandingContent;
