import { css } from "styled-components";

export const wrapper: any = css`
    margin-bottom: 0 !important;
    text-align: center;
    width: 100%;
`;

export const links: any = css`
    color: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      margin: 0 5px;
    }
    a {
      color: inherit;
    }
`;
