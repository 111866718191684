import React, { FunctionComponent } from "react";
import Slider from "react-slick";
import { IAsset, INews, getIfNewsShouldBeDisplayByDate } from "src/common";

import { centeredContent, contentWrapper } from "../../../../constants/layout";
import SSlickBase from "../../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../../utils/slick/SSlickTheme";
import * as styles from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

interface IProps {
    newsSlug: string;
    news: INews[];
}

export const NewSlider: FunctionComponent<IProps> = ({ news, newsSlug }) => {
    if (news.length <= 0) {
        return null;
    }
    const newsToRender = news?.filter((newsItem) =>
        getIfNewsShouldBeDisplayByDate({
            startDate: newsItem?.startDate,
            endDate: newsItem?.endDate,
        })
    );
    const sliderSettings = {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div
            css={`
                ${styles.outerWrapper}${contentWrapper}
            `}
        >
            <div css={centeredContent}>
                <div
                    css={`
                        ${SSlickBase}${SSlickTheme}${styles.wrapper}
                    `}
                >
                    <Slider {...sliderSettings}>
                        {newsToRender.map((newsItem, i) => {
                            const asset = newsItem?.extras?.find((item) => item?.__typename === "Asset") as IAsset;
                            return (
                                <NewSlide
                                    key={i}
                                    index={i}
                                    totalCount={newsToRender.length}
                                    img={asset?.src}
                                    imgAlt={asset?.title}
                                    teaser={newsItem?.teaser}
                                    title={newsItem?.headline}
                                    text={newsItem?.text}
                                    link={newsSlug ? newsSlug + "/" + newsItem?.id : undefined}
                                />
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
