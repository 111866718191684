import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import { cutCorner } from "../../../constants/layout";
import * as styles from "./AssetRefSlice.style";

export interface IAssetProps
{
  file?: IAsset[];
  subtitle?: string;
}

const AssetRefSlice: FunctionComponent<IAssetProps> = props => (
  <>
    {props?.file?.length === 1 ?
      <div css={`${cutCorner}${styles.wrapper}`}>
        <div>
          <Img quality={1080} src={[props.file?.[0]?.src]} alt={props.subtitle}/>
        </div>
      </div>
      :
      props?.file?.map((file, i) => 
        <div key={i} css={`${cutCorner}${styles.wrapper}`}>
          <div>
            <Img quality={1080} src={[file?.src]} alt={file.title}/>
          </div>
        </div>)
    }
  </>

);

export default AssetRefSlice;
