import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://61099-testpartner.gedk.de//
const PageLandingT23: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Footer />
    <LandingBannerLeft />
     
  </>
);

PageLandingT23.displayName = "PageLandingT23";

export { PageLandingT23 };
