import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { theme } from "../../../constants/themes";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: auto;
  color: ${theme.palette.default};
  p {
    span {
      font-family: "sofia_pro_extralightregular", Helvetica, Arial, sans-serif;
      font-weight: 300;
      font-size: 15px;
      box-sizing: border-box;
      line-height: 21px;
    }
  }
  p:first-child {
    margin-bottom: 21px;
  }
`;

export const mainText: any = css`
  width: 100%;
`;

export const RichLinkWrapper: any = styled.div<{
  firstOfTwo: boolean;
  half: boolean;
  full: boolean;
  hasSrc: string;
  withOutImage: boolean;
}>`
  width: 100%;
  /* height: 475px; */
  /* margin: 1px; */

  div.parent {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      transition: all 0.8s;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    position: relative;
    .imgwrapper {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      div {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }
    > *:not(.imgwrapper) {
      z-index: 3;
      position: relative;
    }
  }

  div.rich-link-with-out-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e67c19;
    height: 475px;
  }

  div.rich-link-content {
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ hasSrc }) =>
      hasSrc
        ? null
        : css`
            background-color: var(--color-primary);
          `};
    min-height: 475px;
    border-top: 2px solid #fff;
  }

  div.rich-link-content:before {
    filter: grayscale(50%) brightness(10%);
    background-color: rgba(76, 61, 66, 0.45);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .rich-link-content:hover,
  .rich-link-content:focus {
    background-size: 240% 120%;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ firstOfTwo, half }) =>
      half && firstOfTwo ? "border-right: 1px solid #fff;" : ""};
    ${({ firstOfTwo, half }) =>
      half && !firstOfTwo ? "border-left: 1px solid #fff;" : ""};
    ${({ half }) => half && "width: 50%"};
    ${({ full }) => full && "width: 100%"};

    div.rich-link-content {
      ${({ half }) => half && "background-size: 130% 100%"};
      ${({ full }) => full && "background-size: 100% 100%"};
    }

    .rich-link-content:hover,
    .rich-link-content:focus {
      ${({ half }) => half && "background-size: 150% 120%"};
      ${({ full }) => full && "background-size: 120% 140%"};
    }
  }

  /* @media (${BREAKPOINTS_DM.gold_1024}) {
    ${({ half }) => half && "width: 49.8%"};
    ${({ full }) => full && "width: 100%"};

    div.rich-link-content {
      ${({ half }) => half && "background-size: 100% 100%"};
      ${({ full }) => full && "background-size: 100% 150%"};
    }

    .rich-link-content:hover,
    .rich-link-content:focus {
      ${({ half }) => half && "background-size: 120% 120%"};
      ${({ full }) => full && "background-size: 120% 180%"};
    }
  } */
`;

export const RichLinkContainer = styled.div`
  z-index: 9;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  font-size: 13px;
  div.button {
    margin: 10px auto 0;
    padding: 12px 18px;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    span {
      letter-spacing: 1px;
      line-height: 22px;
      color: #fff;
      font-size: 13px;
      font-weight: 100;
    }
    &:hover {
      background-color: #fff;
      span {
        color: #e67c19;
      }
    }
  }
  div.content {
    padding: 15px;
    text-align: center;
    table {
      width: 100%;
    }
    ul, ol {
      li {
        color: rgb(255, 255, 255);
        font-family: Roboto, sans-serif;
        line-height: 1.2;
        padding-bottom: 8px;
      }
    }

    p {
      color: rgb(255, 255, 255);
      font-family: Roboto, sans-serif;
      padding-bottom: 20px;
      span {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
      }
    }

    > h3 {
      color: #fff;
      font-family: Roboto, sans-serif;
      font-size: 28px;
      font-weight: 300;
      line-height: 33.6px;
      padding-bottom 20px;
      text-align: center;
    }

    > a {
      margin: 10px auto 0;
      padding: 7px 16px;
      background: ${theme.palette.default};
      border: 2px solid #fff;
      border-radius: 2px;
      color: #fff;
      font-size: 13px;
      width: fit-content;
      display: flex;
      align-items: center;
      text-align: center;
      span {
        letter-spacing: 1px;
        line-height: 22px;
        color: #fff;
        font-size: 13px;
        font-weight: 100;
      }
      &:hover {
        background-color: var(--color-primary-light);
      }
    }
  }
`;

export const RichTextWrapper: any = styled.div<{ text: boolean }>`
  width: fit-content;
  margin: ${({ text }) => (text ? "-25px auto 0" : "0 auto")};
`;

export const RichLinkSection: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 2px solid #fff;
`;
export const Bottom: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
  background-color: ${theme.palette.default};
  border-top: 2px solid #fff;
  div.left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    p {
      line-height: 28px;
      margin: 5px 0 10px;
      span {
        font-size: 18px;
        font-weight: 300;
        text-size-adjust 100%
      }
    }
  }
  div.right-side {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a.button-bottom {
      margin: 1px 20px 0 0;
      border: 2px solid #fff;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 16px;
      padding: 13px 30px;
      color: #fff;
      &:hover {
        color: ${theme.palette.default};
        background-color: #fff;
      }
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    div.left-side { 
      width: 60%;
      padding-left: 50px;
      justify-content: flex-start;
    }
    div.right-side {
      width: 40%;
    }
  }
`;
