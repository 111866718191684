// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 274px;
  }
`;
export const SlideWrapper: any = styled.div`
  display: flex;
  flex-direction: row;
  ${({ withImage }) => withImage && "justify-content: center;"}
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 274px;
  }
`;

export const ContentWrapper: any = styled.div`
  padding: 6px 0;
  margin-bottom: 8px;
  width: 100%;
  // max-height: 196px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }
`;

export const ImgWrapper: any = styled.div`
  // object-fit: cover;
  // width: 100%;
  height: 274px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    /* height: 274px; */
  }
`;

export const Counter: any = styled.div`
  width: fit-content;
  position: absolute;
  top: 60px;
  > h3 {
    font-size: 20px;
    line-height: 15px;
    background-color: #1d1d1b;
    padding: 15px 21px 15px 30px;
    font-weight: 100;
  }
  h3:after {
    content: " ";
    display: block;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    margin: 14px auto;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #1d1d1b;
  }
`;

export const Content: any = styled.div`
  color: #000;
  line-height: 140%;
  overflow: hidden;
  font-size: 14px;
  margin-top: 120px;
  padding-left: 22px;
  &:hover {
    text-decoration: underline;
  }
  h3 {
    font-size: 16px;
    margin-bottom: 10px;
    text-decoration: inherit;
    color: #000;
    font-weight: 300;
  }
  h2,
  p {
    span {
      font-weight: 300;
    }
    font-weight: 300;
    font-size: 13px;
    color: inherit;
    text-decoration: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
export const Spacer: any = styled.div`
  @media (${BREAKPOINTS_DM.gold_1024}) {
    width: 7%;
  }
`;
