import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
padding-top: 22px;
margin-top: 48px;
border-top: 1px solid var(--back-3);
`;

const Silver = css`
margin-top: 0;
padding-top: 0;
border-top: none;
`;

const Gold = css``;

const Platinum = css`
`;

const Diamond = css``;

export const SNewsSlider = styled.div<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
