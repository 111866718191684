import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container, ContainerLarge } from "../../common/Grid";

export const sideBar: any = css`
  width: 100%;
  max-width: initial;
  padding-left: 10px;
  padding-right: 10px;

  @media (${BREAKPOINTS_DM.silver_768})
  {
    width: 50%;
    max-width: 500px;
    padding-left: 21px;
    padding-right: 0;
  }
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`;

export const bannerLeftCss: any = css`
  font-family: "Open Sans";

  h1 {

    padding: 15px 0;
    margin: 0;

    &, * {
      font-size: 32px;
      font-weight: 300;
      color: white;
    }
  }

  p {
    &, span, * {
      font-size: 18px;
      line-height: 1.25rem !important;
      color: ${({ theme }) => theme.palette.textColor };
    }
  }

`;

export const HeroContainer = styled.div`
  position: relative;

  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 580px;
    margin: 0;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: auto;
    margin: 0;
  }

  [data-slider-wrapper] > div {
    border: none;

    img {
      filter: grayscale(6%) brightness(96.4%);
    }
  }

  .slick-next {
    display: none !important;
  }
`;

export const HeroContent: any = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100px;

  ${Container} {
    display: block;
    h1 {
      font-family: Roboto, sans-serif;
      font-size: 53px;
      line-height: 1.2;
      color: #fff;
      text-transform: none;
      font-weight: 700;
      text-shadow: 0 1px 2px rgb(0 0 0 / 60%);

      p, span {
        font-family: Roboto, sans-serif;
      }
    }

    > p {
      font-family: Roboto, sans-serif;
      font-size: 21px;
      color: #fff;
      text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
      margin-bottom: 60px;
    }


    a.button {
      padding: 17px 25px;
      display: inline-block;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      color: white;
      line-height: 1;

      border: 2px solid #ddd;
      background-color: #222;

      :hover {
        border-color: #fff;
      }
    }
  }

  article {
    position: absolute;
    width: 190px;
    height: 190px;
    top: -315px;
    right: 35px;
    text-shadow: 0 0 3px #000;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 24px;

    background: transparent;
    transform: rotate(7deg);

    b {
      line-height: 18px;
      text-shadow: 0 0 3px #000;
    }

    small > p {
      font-family: Roboto, sans-serif;
      margin: 0;
      span {
        font-weight: 600;
        text-shadow: 0 0 3px #000;
      }

    }

    a {
      text-shadow: 0 0 3px #000;
      cursor: pointer;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;

export const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 98px;
  background-color: ${({ theme }) => theme.palette.bgLight};
  padding-top: 40px;
  padding-bottom: 10px;

  a {
    position: relative;
    display: flex;
    width: calc(100% / 3);
    line-height: 48px;
    flex-direction: column;
    align-items: center;
    justyfy-content: center;
    color: ${({ theme }) => theme.palette.primary};

    img {
      position: absolute;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
      left: calc(50% - 40px);
      top: -78px;
    }
  }
`;

export const BrandsContainer = styled.div`
  width: 100%;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.palette.bgLight};

  img {
    filter: grayscale(100%);
    opacity: .8;
  }
`;

export const FurtherAwardsContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const BaseTextContainer = styled(Container)`
  flex-direction: column;
  padding: 50px 15px;

  .title, h1, h2 {
    margin-top: 20px;
    margin-bottom: 40px;

    &, span {
      ${({ theme }) => theme.textStyles.h1};
    }

    position: relative;

    :after {
      position: absolute;
      display: block;
      height: 2px;
      width: 30px;
      left: calc(50% - 15px);
      margin-top: 10px;
      background: ${({ theme }) => theme.palette.primary};
      content: '';
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
`;

export const MainTextContainer = styled(BaseTextContainer)`
  justify-content: center;
  align-items: center;

  .title, h1, p {

    text-align: center;
  }
`;

export const AboutUsContainer = styled(MainTextContainer)`
  margin-top: 68px;
  padding: 60px 15px 100px;
`;

export const KnowMoreContainer = styled(MainTextContainer)`

  > p {
    margin: auto;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
      text-align: center;
    }
  }

  .primary.inverted {
    background-color: #113ceb;
    border-color: #113ceb;
    color: #fff;
    &:hover {
      background-color: #2550ff;
      border-color: #2550ff;
    }
  }

  .linksContainer {
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
      display: flex;
      justify-content: space-between;
    }

    a[data-richlink] {
      display: block;
      width: 100%;
      padding: 20px 0;

      @media (${BREAKPOINTS_DM.silver_768}) {
        width: calc(100% / 3);
        padding: 20px 15px;
      }

      > div:first-child {
        height: 216px;
      }
      > h3 {
        font-weight: 300;
        color: #555;
        text-align: center;

        :after {
          left: calc(50% - 15px);
        }
        @media (${BREAKPOINTS_DM.silver_768}) {
          text-align: left;
          :after {
            left: 0;
          }
        }
      }

      > div:last-child {
        h2,h3,h4 {
          &, span {
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            margin: 0 0 10px 0;
            color: ${({ theme }) => theme.palette.primary};
            :after {
              display: none;
            }
          }
        }

        > p {
          &, a {
            color: ${({ theme }) => theme.palette.textColor};
            text-decoration: none;
          }
        }
      }
    }
  }
`;

export const NewsContainer = styled(MainTextContainer)`
  flex-direction: column;
  padding: 80px 15px 0;
  margin-bottom: 30px;
  .primary.inverted {
    background-color: #113ceb;
    border-color: #113ceb;
    color: #fff;
    &:hover {
      background-color: #2550ff;
      border-color: #2550ff;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 80px 0 0;
  }
`;
