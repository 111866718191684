import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { ProductLandingBanner } from "../../common/ProductLandingBanner";
 ;
import LandingContent from "../../content/LandingContent";

// https://60026-vorschau.dk-das-kuechenhaus.de/
const PageLandingT22: FC = () => (
  <>
    <Header />
    <LandingContent />
    <ProductLandingBanner />
    <Banner />
    <Footer />
     
    <LandingBannerLeft />
  </>
);

PageLandingT22.displayName = "PageLandingT22";

export { PageLandingT22 };
