import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const GridWrapper: any = styled.div`
  > div {
    padding: 10px 0;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    padding: 0;

    > div {
      padding: 0;

      &:nth-child(1) {
        grid-column: 1/1;
        grid-row-start: 1;
      }

      &:nth-child(2) {
        grid-column: 1/1;
        grid-row-start: 2;
      }

      &:nth-child(3) {
        grid-column: 2/2;
        grid-row-start: 1;
      }

      &:nth-child(4) {
        grid-column: 2/2;
        grid-row-start: 2;
      }

      &:nth-child(5) {
        grid-column: 1/1;
        grid-row-start: 3;
      }

      &:nth-child(6) {
        grid-column: 1/-1;
        display: flex;
        flex-wrap: wrap;
        > hr {
          width: 100%;
          flex: 1 0 100%;
          order: -1;
        }

        > a,
        > div {
          flex: 1 1 50%;
        }

        > a {
          order: 2;
          padding-left: 10px;
          padding-bottom: 15px;
        }

        > div {
          order: 1;
        }
      }

      &:nth-child(7) {
        grid-column: 2/2;
        grid-row-start: 3;
      }
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    > div {
      &:nth-child(1) {
        grid-column: 1/1;
        grid-row-start: 1;
      }

      &:nth-child(2) {
        grid-column: 3/3;
        grid-row-start: 1;

        hr {
          display: none;
        }
      }

      &:nth-child(3) {
        grid-column: 2/2;
        grid-row-start: 1;
      }

      &:nth-child(4) {
        grid-column: 1/1;
        grid-row-start: 2;
      }

      &:nth-child(5) {
        grid-column: 2/2;
        grid-row-start: 2;
      }

      &:nth-child(6) {
        grid-column: 1/-1;
      }

      &:nth-child(7) {
        grid-column: 3/3;
        grid-row-start: 2;

        hr {
          display: none;
        }
      }
    }
  }
`;

export const TextElementWrapper = styled.div<{ isActive: boolean }>`
  div:first-of-type img {
    height: 214px;
  }

  h1 {
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    font-size: 20px;
    margin: 34px 0;
  }
  div > p > span {
    font-size: 15px;
  }
  > div {
    > p,
    > ul,
    > li {
      max-height: ${({ isActive }) => (isActive ? "initial" : "100px")};
      ${({ isActive }) =>
        !isActive &&
        css`
          margin: 0;
          padding: 0;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        `}
    }
  }

  > a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 700;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${({ isActive }) => isActive && "20px"};

    img {
      display: block;
      width: 29px;
    }
    &[data-open] {
      margin-left: 20px;
      img {
        transform: rotate(90deg);
      }
    }
  }
`;

export const SideBar: any = styled.div<any>`
  width: 100%;
  max-width: initial;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding-left: 21px;
    padding-right: 0;
  }
`;

export const BannerHorizontal: any = styled.div`
  margin-bottom: 40px;
  > div {
    overflow: hidden;
    max-width: calc(100vw - 40px);
    @media (${BREAKPOINTS_DM.silver_768}) {
      max-width: calc(1140px - 120px);
      width: calc(100vw - 120px);
    }
  }
`;

export const HeroContainer = styled.div``;
