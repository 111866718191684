import React, { FunctionComponent, useContext } from "react";
import { Context, ITextElement, LogoBannerHorizontal } from "src/common";
import { css } from "styled-components";

import RichText from "../../../BaseComponents/RichText/RichText";
import SRichTextGeneral from "../../../BaseComponents/RichText/Styles/SRichTextGeneral";
import LandingGrid from "../LandingGrid/LandingGrid";
import { NewSlider } from "../NewSlider/NewSlider";
import * as styles from "./LandingContent.style";

export const LandingContent: FunctionComponent = () => 
{
  const props = useContext(Context);
  const textElements = props?.PageLanding?.elements?.filter(
        event => event?.__typename === "TextElement"
    ) as ITextElement[];
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;

  return (
    <div css={styles.wrapper}>
      <div css={styles.contentWrapper}>
        <LandingGrid />
        <NewSlider />
        {isNewsCustom && <NewSlider newsCustom={isNewsCustom}/>}
        {textElements?.map((element, ind) => 
          (
            <div key={`text-element-key-${ind}`}>
              <h1>{element.headline}</h1>
              <RichText
                textContent={element.text}
                overwriteStyle={SRichTextGeneral}
                css={styles.mainText}
              ></RichText>
            </div>
          ))}
        {props?.PageLanding?.footnote && (
          <RichText
            textContent={props.PageLanding.footnote}
            overwriteStyle={css`${SRichTextGeneral}${styles.footnote}`}
            css={styles.mainText}
          ></RichText>
        )}
      </div>
      <LogoBannerHorizontal />
    </div>
  );
};
