import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const SectionWrapper: any = styled.div<{bgImage?: string}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  background: url(${({ bgImage }) => bgImage}) no-repeat center fixed;
  background-size: cover;
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    .kitchen-compass {
      display: block;
      margin-bottom: -20px;
    }
  }
`;

export const TextConten: any = styled.div`
  width: 100%;
  color: #777878;
  h1 {
    color: #777878;
    font-weight: 400;
    line-height: 35px;
    font-size: 32px;
  }
  div.parallax-mirror {
    height: 300px;
  }
  @media (${BREAKPOINTS_DM.silver_768}){
  }
`;

export const NewSliderWrapper: any = styled.div`
    width: 100%;
    background: #fff;
    padding: 70px 20px;
    @media (${BREAKPOINTS_DM.silver_768})
    {
    }
`;

export const mainText: any = css`
    width: 100%;
`;

export const BannerHorizontalWrapper: any = styled.div`
    background-color: rgba(255,255,255,0.5);
    margin: 15px 0 0;
`;
export const ImgWrapper: any = styled.div`
    width: 100%;
    div > img {
      width: 150%;
    }
`;

export const RichTextWrapper: any = styled.div`
    background-color: #fff;
    width: 100%;
    div {
      margin: auto;
      max-width: 950px;
    }
`;
export const MapWraper: any = styled.div`
    width: 60%;
`;

export const MapContact: any = styled.div`
  width: 100%;
  background-color: #94c03e;
  display: flex;
`;

export const MapForm: any = styled.div`
  width: 40%;
  background: pink;
  padding: 0 25px;
  div {
    width: 90%;
  }
  div > div > button {
      padding: 5px;
      background-color: #000;
      f
  }
  div > div {
    font-size: 13px;
    background: red;
  }
`;

export const HeroWrapper: any = styled.div`
  position: relative;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 600px;

    > div > div {
      img {
        height: 600px;
      }
    }
  }
`;

export const ButtonHero: any = styled.div`
  width: 100%;
  max-width: 950px;
  margin: auto;

  > div {
    display: flex;
    flex-direction: column;
    bottom: 100px;
    position: absolute;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: row;
    }
  }
`;

export const ButtonHeroContent: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  width: 285px;
  div.text {
    width: fit-content;
    background: #fff;
    padding: 0 8px;
    span {
      color: #000;
      font-size: 32px;
      font-weight: 300;
      line-height: 44px;
      margin-bottom: 21px;
    }
    div {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }

  a.button {
    margin-top: 10px;
    width: fit-content;
    display: flex;
    aling-content: center;
    color: #fff;
    padding: 5px 10px;
    background-color: #94C03E;
    &:hover {
      background-color: #000;
    }
    span {
      font-size: 13px;
      line-height: 26px;
      font-weight: 300;
    }
    div {
      font-size: 13px;
      line-height: 26px;
      margin-left: 10px;
    }
  }
`;
export const ContainerText: any = styled.div`
  max-width: 950px;
  margin-left: 30px;
  padding: 50px 10px 70px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 50px 30px 70px;
  }
`;

export const ProductLandingBannerWrapper: any = styled.div`
  .plb-c10 {
    min-height: 250px;
  }
  .plb-image {
    min-height: 120px;
  }
  h1.plb-title {
    background-color: #94C03E;
  }
  .plb-c0 {
    background-color: #94C03E;
  }

  .plb-price-container {
    background-color: #94C03E;
  }
  .plb-price-badge {
    background-color: #94C03E;
    ::after {
      background-color: #94C03E;
      right: -5px;
      margin-top: 7px;
    }
    ::before {
      background-color: #94C03E;
      margin-left: -13px;
      margin-top: 8px;
    }
  }
`;
