import React, { FC } from "react";
 ;
import LandingContent from "../../content/LandingContent";


// https://60578-vorschau.kuechengilde.de/
const PageLandingT21: FC = () => (
  <>
    <LandingContent />
     
  </>
);

PageLandingT21.displayName = "PageLandingT21";

export { PageLandingT21 };
