import React, { FC } from "react";
import { LandingBannerLeft, LogoBannerHorizontal } from "src/common";

import { Grid } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import PlanningDateModal from "../../common/PlanningDateModal";
 ;
import LandingContent from "../../content/LandingContent";
import { BannerHorizontalWrapper } from "../../content/LandingContent/LandingContent.style";

// https://60026-vorschau.dk-das-kuechenhaus.de/
const PageLandingT17: FC = () => (
  <>
    <Header />
    <Grid>
      <LandingContent />
      <BannerHorizontalWrapper>
        <LogoBannerHorizontal shadowOverlayVisible={true} />
      </BannerHorizontalWrapper>
    </Grid>
    <Banner />
    <Footer />
     
    <LandingBannerLeft />
    <PlanningDateModal/>
  </>
);

PageLandingT17.displayName = "PageLandingT17";

export { PageLandingT17 };
