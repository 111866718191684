import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: auto;
    padding: 0 10px;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding: 0;
    }
`;

export const footnote: any = css`
   p, p> span {
       font-size: 20px;
       font-weight: 400;
   }
`;

export const contentWrapper: any = css`
    margin-top: 40px;
`;

export const mainText: any = css`
    width: 100%;
    h1{
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
    }
`;
