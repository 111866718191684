import styled from "styled-components";

export const SubNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  max-width:  ${({ theme }) => theme.maxWidth};
`;

export const SubNavigationWrapper = styled.div`
  width: calc(50% - 10px);
  background: #333;
  padding: 140px 10px 10px 10px;

  h3 {
    margin: 15px 0;
    font-size: 20px;
    color: ${({ theme }) => theme.palette.primary};
  }

  a {
    display: block;
    color: #fff;
    line-height: 30px;
    margin-left: 30px;
    font-size: 12px;

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;
