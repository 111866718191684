import React, { FC, useContext, Fragment, useState } from "react";
import {
  Context,
  LogoBannerHorizontal,
  ITextElement,
  getPage,
} from "src/common";
import { DachseiteSearch } from "../../common/DachseiteSearch/DachseiteSearch";

import FurtherAwards from "../../common/FurtherAwards";
import { Container, Divider, Section } from "../../common/Grid";
import Hero from "../../common/Hero";
import NewSlider from "../../common/NewSlider";
import PromotionalOffertSlider from "../../common/PromotionalOfferSlider";
import RichLinks from "../../common/RichLinks/RichLinks";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import {
  HeroContainer,
  GridWrapper,
  SideBar,
  BannerHorizontal,
  TextElementWrapper,
} from "./LandingContent.style";
import LinkBannerTeaser from "../../common/LinkBannerTeaser/LinkBannerTeaser";
import Img from "src/common/BaseComponents/Img/Img";

const LandingContent: FC = () => {
  const [isActive, setIsActive] = useState(false);
  const props = useContext(Context);
  const page = getPage(props);
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  return (
    <Container>
      <HeroContainer>
        <Hero page={page} />
      </HeroContainer>
      <Section>
        <div>
          <GridWrapper>
            <TextElementWrapper isActive={isActive}>
              {(
                props.PageLanding?.elements?.filter(
                  (e) => e?.__typename == "TextElement"
                ) as ITextElement[]
              )?.map((t, i) => (
                <Fragment key={`rt0-${i}`}>
                  <Divider />
                  <RichTextGeneral textContent={t.text}>
                    {t.icon?.[0]?.src && (
                      <Img
                        quality={600}
                        src={t.icon[0].src}
                        alt={t.icon[0].title}
                      />
                    )}
                    <h1>{t?.headline}</h1>
                  </RichTextGeneral>
                  {isActive ? (
                    <a onClick={() => setIsActive(!isActive)}>
                      <img src="/images/kw_icon_close_orange.png" />
                    </a>
                  ) : (
                    <a onClick={() => setIsActive(!isActive)} data-open>
                      <img src="/images/kw_icon_arrow_right.png" />
                      mehr
                    </a>
                  )}
                </Fragment>
              ))}
            </TextElementWrapper>
            <div>
              <Divider />
              <SideBar dachseite={props.dealer?.dachseite}>
                {props.dealer?.dachseite ? (
                  <DachseiteSearch />
                ) : (
                  <>
                    <FurtherAwards />
                    <TeaserRight />
                    {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
                      <a
                        href={"https://www.kuechenkompass.com/"}
                        className="kitchen-compass"
                      >
                        <img
                          src={"/images/kompass.png"}
                          alt={"Küchenkompass"}
                        />
                      </a>
                    ) : null}
                  </>
                )}
              </SideBar>
            </div>
            <RichLinks />
            <div>
              <Divider />
              <NewSlider />
            </div>
            {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
          </GridWrapper>

          <Divider nomargin />
          <LinkBannerTeaser />

          <BannerHorizontal>
            <Divider nomargin />
            <LogoBannerHorizontal shadowOverlayVisible={true} />
            <Divider nomargin />
          </BannerHorizontal>
        </div>
      </Section>
      <PromotionalOffertSlider />
    </Container>
  );
};

export default LandingContent;
