import React, { FunctionComponent, useContext } from "react";
import { Context, IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img";
import {
  ContactWrapper,
  ContentWrapper,
  Content,
  Title,
  Button,
  ButtonText,
} from "./ContactBanner.style";

const ContactBanner: FunctionComponent = () =>
{
  const props = useContext(Context);
  const contactData = props?.PageContact?.elements;
  const slug = props?.PageContact?.slug;
  return (
    <ContactWrapper>
      {contactData && contactData.map((item, ind) =>
      {
        const isAssetRefSlice = item?.__typename === "Asset" && item;
        const isTextElement = item?.__typename === "TextElement" && item;
        return (
          <ContentWrapper key={`key-contac-${ind}`}>
            {isAssetRefSlice && (
              <Img
                src={[{ src: (item as IAsset)?.src }]}
                height={240}
                quality={1080}
              />
            )}
            {isTextElement && (
              <Content key={`key-contac-${ind}`}>
                <Title>{isTextElement?.headline}</Title>
                <Button>
                  <ButtonText href={`/${slug}`}>So kommen Sie zu uns</ButtonText>
                </Button>
              </Content>
            )}
          </ContentWrapper>
        );
      })

      }
    </ContactWrapper>
  );
};

export default ContactBanner;
