import { css } from "styled-components";

export const wrapper: any = css`
  background: #D0D2D3;
  margin-top: 20px;
  margin-bottom: 40px;

  .slick-dots {
      li {
        width: 10px;
        height: 10px;

        & > button {
          width: 10px;
          height: 10px;

          &:before {
            width: 10px;
            height: 10px;
  
            color: transparent;
            opacity: 1;
            background-color: #D0D2D3;
            border-radius: 50%;
          }
        }
        
        &.slick-active > button:before  {
          background-color: ${props => props.theme.palette.green};
        }
      }
  }
`;
