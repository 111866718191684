import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/templates/t27/constants/globalStyle";

interface IProps {
    hasImage: boolean;
}

const Default = css<IProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;
    ${CSSWithOpacityHoverAnimation}

    &,
    svg {
        width: 25px;
        height: 25px;
    }

    ${({ hasImage }) =>
        hasImage
            ? css`
                  color: var(--back-2);
              `
            : css`
                  color: var(--front-2);
              `}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css<IProps>`
    ${({ hasImage }) =>
        hasImage
            ? css`
                  top: calc(8px + 24px);
              `
            : css`
                  top: calc(8px + 38px);
                  right: 38px;
              `}

    &, svg {
        width: 29px;
        height: 29px;
    }
`;

const Diamond = css``;

export const SLandingBannerLeftIconWrapper = styled.span`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
