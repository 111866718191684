import React, { FunctionComponent, Fragment } from "react";
import { IAsset } from "src/common";
import { css } from "styled-components";

import Img from "../../../BaseComponents/Img/Img";
import RichText from "../../common/RichText";
import * as styles from "./TextElement.style";

export interface ITextElement
{
  text?: string;
  headline?: string;
  icon?: IAsset[];
  isStickedToComponentAbove?: boolean;
  children?: React.ReactNode;
}

const TextElement: FunctionComponent<ITextElement> = props =>
{
  const icon = props?.icon?.find(e => (e as any)?.__typename === "Icon");
  const images = props?.icon?.filter(e => e?.__typename === "Asset");

  return (
    <div
      css={`${styles.wrapper}${props.isStickedToComponentAbove ? styles.isStickedToComponentAbove : null
      }`}
    >
      <div>
        {props.children}
        {(icon?.src || props.headline) && (
          <div css={styles.headlineWrapper}>
            {icon?.src && (
              <Img quality={80} src={[icon?.src]} alt={icon?.title || "icon"} />
            )}
            {props?.headline && <h2>{props.headline}</h2>}
          </div>
        )}
        <div css={styles.richText}>
          <div css={styles.imageWrapper}>
            {images?.map((asset, i) =>
              (
                <Fragment key={i}>
                  <Img src={[asset.src]} quality={720} alt={asset.description}></Img>
                  <p>{asset.description}</p>
                </Fragment>
              ))}
          </div>
          {props?.text && <RichText fragment textContent={props.text} ></RichText>}
        </div>
        <div css={styles.clearFix}></div>
      </div>
    </div>
  );
};

export default TextElement;
