import React, { Fragment, useContext } from "react";

import { Context } from "../../../../common";

interface IUniversalComponentsRendererProps
{
  items: any[];
  children?: React.ReactNode;
  onSetShowModal?: (boolean) => void;
}

const UniversalComponentsRenderer = ({ items, children, onSetShowModal }: IUniversalComponentsRendererProps): React.ReactElement =>
{
  const c = useContext(Context);
  const isLanding = !c.slug;
  return (
    <Fragment>
      {items &&
                items.map((item, key) =>
                {
                  try
                  {
                    if(`${item?.__typename}`.includes("UnknownSlice"))
                    {
                      return null;
                    }
                    const MountedComponent = require(`../../components/elements/${item?.__typename}/${item?.__typename}`)
                      .default;

                    let isStickedToComponentAbove = false;

                    if(key >= 1 && items[key - 1]?.__typename === "Asset")
                    {
                      isStickedToComponentAbove = true;
                    }
                    if(isLanding && key >= 1 && items[key - 1]?.__typename === "Slider")
                    {
                      isStickedToComponentAbove = true;
                    }

                    return (
                      <React.Fragment key={key + `${item?.__typename}`}>
                        <MountedComponent
                          {...item}
                          items={items}
                          isStickedToComponentAbove={isStickedToComponentAbove}
                          index={key}
                          onSetShowModal={onSetShowModal}
                        >
                          {children}
                        </MountedComponent>
                      </React.Fragment>
                    );
                  }
                  catch(e)
                  {
                    console.error(item?.__typename, e);
                    return null;
                  }
                })}
    </Fragment>
  );
};

export default UniversalComponentsRenderer;
