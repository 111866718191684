import React, { FC, useContext } from "react";
import { Context, ITextElement, getPage } from "src/common";

import FurtherAwards from "../../common/FurtherAwards";
import { Container, Divider, Section } from "../../common/Grid";
import Hero from "../../common/Hero";
import NewSlider from "../../common/NewSlider";
import { ProductLandingBanner } from "../../common/ProductLandingBanner";
import { RichTextGeneral } from "../../common/RichText";
import Search from "../../common/Search";
import TeaserRight from "../../common/TeaserRight";
import { LogoBannerHorizontal } from "../../common/LogoBannerHorizontal/LogoBannerHorizontal";
import {
  HeroContainer,
  ContentWrapper,
  SideBar,
  BannerHorizontal,
  ProductLandingBannerWrapper,
  ModelKitchen,
  HeadLine,
} from "./LandingContent.style";
import { EventItem } from "../../common/TeaserRight/TeaserRight.style";

const LandingContent: FC = () => {
  const props = useContext(Context);
  const page = getPage(props);
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;
  return (
    <Container>
      <Section>
        <HeroContainer>
          <Hero page={page} lg />
        </HeroContainer>
        <ProductLandingBannerWrapper>
          <ProductLandingBanner />
        </ProductLandingBannerWrapper>
        <div>
          <HeadLine>
            {(
              props.PageLanding?.elements?.filter(
                (e) => e?.__typename == "TextElement"
              ) as ITextElement[]
            )?.map((t, i) => (
              <RichTextGeneral key={`rt0-${i}`} textContent={t.text}>
                <h1>{t?.headline}</h1>
              </RichTextGeneral>
            ))}
          </HeadLine>
          <ContentWrapper>
            <SideBar>
              {furtherAwards.length >= 1 && <FurtherAwards />}
              {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
                <a
                  href={"https://www.kuechenkompass.com/"}
                  className="kitchen-compass"
                >
                  <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
                </a>
              ) : null}
              <Search />
              <ModelKitchen>
                <a
                  href="https://musterkuechen.kuechengilde.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="home_module_small_bg_image">
                    <div className="home_module_image_bg">
                      <h3>Musterküchenabverkauf</h3>
                      <div className="background_text">
                        <h4>
                          Die besten Angebote von Partnern in ganz Deutschland
                        </h4>
                      </div>
                    </div>
                  </div>
                </a>
              </ModelKitchen>
            </SideBar>
            <TeaserRight />
          </ContentWrapper>
          <NewSlider />
          {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}

          <BannerHorizontal>
            <LogoBannerHorizontal />
          </BannerHorizontal>
          <Divider nomargin />
        </div>
      </Section>
    </Container>
  );
};

export default LandingContent;
