import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../../constants/mediaquerys";

const Default = css`
  color: var(--primary-100);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in;

  &, svg {
    width: 18.8px;
    height: 15.5px;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  &, svg {
    width: 25px;
    height: 19px;
  }
`;

const Diamond = css``;

export const SSliderItemIconWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
