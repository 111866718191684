import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../constants/colors";
import { theme } from "../../../constants/themes";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: ${theme.maxWidth}px;
  width: 100%;
  margin: auto;
  color: #85b2c3;
  p {
    span {
      font-weight: 300;
      font-size: 15px;
      box-sizing: border-box;
      line-height: 21px;
    }
  }
  p:first-child {
    margin-bottom: 21px;
  }
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  padding: 45px 10px 30px;
  flex-direction: column;
  margin-top: 30px;
  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    padding: 45px 30px 0;
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
`;

export const LeftContent: any = styled.div`
  width: 100%;
  padding: 10px;
  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-family: "sofia_pro_extralightregular", Helvetica, Arial, sans-serif;
    color: #85b2c3;
    line-height: 38px;
    font-size: 29px;
    letter-spacing: 0.02em;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
    width: 66.66%;
  }
`;

export const RightContent: any = styled.div`
  width: 100%;
  max-width: initial;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 33.33%;
    margin-left: auto;
    padding: 0;
  }
`;

export const mainText: any = css`
  width: 100%;
`;

export const BannerHorizontalWrapper: any = styled.div``;

export const LinksContentWrapper: any = styled.div`
  width: 100%;
  max-width: 1100px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 30px;
  }
`;

export const RichLinkWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 15px;
  img {
    height: 307.58px;
  }
  background-color: rgb(146, 194, 214);
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;

    > * {
      width: 50%;
    }
  }
`;

export const RichLinkContent: any = styled.div<{ src: string }>`
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 20px 0 31px;
  * {
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  h3 {
    b {
      font-size: 18px;
      font-weight: 800;
    }
  }

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;

    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${({ src }) => src}");
    @media (${BREAKPOINTS_DM.gold_1024}) {
      left: 60px;
      max-width: 950px;
      height: 274px;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 274px;
  }
`;

export const RichLinkContainer = styled.div`
  z-index: 1;
  flex-direction: column;
  font-size: 13px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }

  > div {
    padding: 0 15px;
    height: 100%;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    a {

      &.contentWrapper {
       
      }

      > h3 {
      color: #fff;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 0.6px;
      line-height: 38px;
      margin-bottom: 21px;
    }
    }

    > a.link {
      color: #fff;
      font-size: 15px;
      span {
        color: #fff;
        font-size: 15px;
        font-weight: 300;
        line-height: 21px;
      }
      span:before {
        content: "—›  ";
      }
    }
  }
`;

export const RichTextWrapper: any = styled.div<{ text: boolean }>`
  width: fit-content;
  margin: ${({ text }) => (text ? "-25px auto 0" : "0")};
`;

export const RichLiksTextWrapper: any = styled.div`
  width: 100%;
  color: #fff;
  div {
    margin: 0;
  }
  p:first-child {
    margin-bottom: 15px;
  }

  a {
    color: #fff;
    font-size: 15px;
    span {
      color: #fff;
      font-size: 15px;
      font-weight: 300;
      line-height: 21px;
    }
  }
`;

export const ProductLandingBannerWrapper: any = styled.div`
  color: #92c2d6;
`;
