import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import * as styles from "./NewSlide.style";
import { Slide } from "./NewSlide.style";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

const NewSlide: FunctionComponent<IProps> = props =>
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <Slide withImage={!!props.img}>
      {props.img && (
        <a href={props?.link} css={styles.img}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </a>
      )}
      <div css={styles.contentWrapper}>
        <a href={props?.link}>
          <p css={styles.counter}>
            Nachricht {props?.index + 1} / {props?.totalCount}
          </p>
          {text ? (
            <div css={styles.content}>
              <RichText textContent={text}>
                {props.title && <h3>{props.title}</h3>}
              </RichText>
            </div>
          ) : (
            <div css={styles.content}>
              {props.title && <h3>{props.title}</h3>}
            </div>
          )}
        </a>
      </div>
    </Slide>
  );
};

export default NewSlide;
