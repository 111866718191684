import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: (50vmin)[5];
    grid-template-rows: repeat(5, 1fr);
    @media (${BREAKPOINTS_DM.silver_768})
    {
        -ms-grid-columns: (1fr)[5];
        grid-template-columns: repeat(5, 1fr);
        -ms-grid-rows: (1fr)[3];
        grid-template-rows: repeat(3, 1fr);
    }
    /* @media (${BREAKPOINTS_DM.diamond_1920})
    {
        -ms-grid-columns: (1fr)[5];
        grid-template-columns: repeat(5, 1fr);
        -ms-grid-rows: (1fr)[3];
        grid-template-rows: repeat(3, 1fr);
    } */
    .gridItem-1 {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
    }
    .gridItem-2 {
        grid-column: 2;
        grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
    }
    .gridItem-3 {
        grid-column: 1;
        grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-row: 2;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            -ms-grid-column: 3;
            grid-column: 3;
            -ms-grid-row: 1;
            grid-row: 1;
        }
    }
    .gridItem-4 {
        grid-column: 2;
        grid-row: 2;
        -ms-grid-column: 2;
        -ms-grid-row: 2;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            grid-column: 4 / 6;
            grid-row: 1 / 3;
            -ms-grid-column: 4;
            -ms-grid-column-span: 2;
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
        }
    }
    .gridItem-5 {
        grid-column: 1;
        grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-row: 3;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 2;
            grid-row: 2;
        }
    }
    .gridItem-6 {
        grid-column: 2;
        grid-row: 3;
        -ms-grid-column: 2;
        -ms-grid-row: 3;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            grid-column: 2 / 4;
            grid-row: 2 / 4;
            -ms-grid-column: 2;
            -ms-grid-column-span: 2;
            -ms-grid-row: 2;
            -ms-grid-row-span: 2;
        }
    }
    .gridItem-7 {
        grid-column: 1;
        grid-row: 4;
        -ms-grid-column: 1;
        -ms-grid-row: 4;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            -ms-grid-column: 1;
            grid-column: 1;
            -ms-grid-row: 3;
            grid-row: 3;
        }
    }
    .gridItem-8 {
        grid-column: 2;
        grid-row: 4;
        -ms-grid-column: 2;
        -ms-grid-row: 4;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            -ms-grid-column: 4;
            grid-column: 4;
            -ms-grid-row: 3;
            grid-row: 3;
        }
    }
    .gridItem-9 {
        grid-column: 1;
        grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-row: 5;
        @media (${BREAKPOINTS_DM.silver_768})
        {
            -ms-grid-column: 5;
            grid-column: 5;
            -ms-grid-row: 3;
            grid-row: 3;
        }
    }
`;
