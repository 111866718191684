import styled from "styled-components";

export const FurtherAwardsWrapper: any = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
export const ImageWrapper: any = styled.div`
  width: calc(50% - 10px);
  
  &:nth-child(even) {
    margin-left: 20px;
  }
  &:nth-child(n+3) {
    margin-top: 20px;
  }
`;
