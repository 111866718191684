import styled, { css } from "styled-components";

const NOT_LOADED = css`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: -20px;
    width: 1px;
    height: 1px;
    opacity: 0;
`;

export const SWrapperToFixNoPointerOnLoad = styled.div<any>`
  ${(props: any) => props.loaded ? '' : NOT_LOADED}  
`;