import styled, { css } from "styled-components";

export const SImageWrapper = styled.div`
    height: 38px;
    margin-right: 40px;

    & > div {
        position: relative;
        isolation: isolate;
        filter: invert(1);

        ::before {
            content: "";
            position: absolute;
            top: 0.5px;
            left: 1px;
            width: 96%;
            height: 96%;
            background-color: #000000;
        }

        img {
            height: 100%;
            width: auto !important;
            background-color: #f2f2f2;
            padding: 5px 10px;
        }

        ::before,
        img {
            mix-blend-mode: hard-light;
        }
    }
`;

export const shadowOverlay: any = css`
    position: absolute;
    top: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    width: 5px;
    z-index: 1;
    animation: none !important;
`;
