import { css } from "styled-components";

import colors from "../../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../../constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 430px;
  margin-bottom: 12px;
  > * {
    width: 45%;
  }
  @media (${BREAKPOINTS_DM.platinum_1366})
  {
      height: 350px;
  }
  @media (${BREAKPOINTS_DM.gold_1024})
  {
      height: 280px;
  }
  @media (${BREAKPOINTS_DM.silver_768})
  {
      height: auto;
      flex-direction: column-reverse;
      > * {
        width: 100%;
      }
  }
`;

export const contentWrapper: any = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    h4 {
      color: white;
      font-size: 19px;
      margin-bottom: 10px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        max-height: 210px;
        h4 {
          font-size: 16px;
        }
    }
`;

export const richText: any = css`
    overflow: hidden;
    max-height: 100%;
    * {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
    }

`;

export const mehrErfahrenLink: any = css`
    width: 100%;
    padding-top: 20px;
    background-color: ${props => props.theme.secondary};
    text-align: center;
    color: ${props => props.theme.primary};
    @media (${BREAKPOINTS_DM.silver_768})
    {
        margin-bottom: 15px;
    }
`;

export const imgWrapper: any = css`
    @media (${BREAKPOINTS_DM.silver_768})
    {
        img {
          margin-bottom: 25px;
          height: 50vw !important;
        }
    }
`;
