import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
width: 48px;
min-width: 48px;
height: 48px;
background-color: var(--front-2);
display: flex;
align-items: center;
justify-content: center;
margin-right: 24px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
width: 76px;
min-width: 76px;
height: 76px;
`;

const Diamond = css``;

export const SLandingEventIcon = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

