import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";
import { hexIsLight } from "../../../../../../../common/utils/colorHelper";
import Img from "../../../../../BaseComponents/Img/Img";
import * as styles from "./NewSlide.style";
import { Wrapper, Contentrapper } from "./NewSlide.style";
import { richToTeaser } from "../../../../../../../common/utils/text/teaser";

interface IProps {
  index: number;
  totalCount: number;
  img?: string;
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  teaser?: string;
}

export const NewSlide: FunctionComponent<IProps> = (props) => {
  const p = useContext(Context);
  const palette = p?.Settings;
  let teaser = props.teaser;
  let text: any;

  const elementToShowAsSubtitle = props.text?.content?.find((p) =>
    p.type.includes("heading")
  );

  if (elementToShowAsSubtitle) {
    text = {
      type: "doc",
      content: [elementToShowAsSubtitle],
    };
  }

  let bgColor = palette.themeBackgroundColourIsland || "#666";
  let textColor = palette.themeTextColor || "#000";
  try {
    const lightBg = hexIsLight(bgColor);
    const lightText = hexIsLight(textColor);
    if (!lightBg && !lightText) {
      textColor = "#fff";
    }
    if (lightBg && lightText) {
      textColor = "#000";
    }
  } catch {}

  return (
    <Wrapper withImg={props.img}>
      {props.img && (
        <a href={props.link} 
        target={`${props.link}`.includes("https://") ? "_blank" : undefined}
        css={styles.img}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </a>
      )}
      <Contentrapper
        counterColor={palette.themeTextColourIsland}
        contentColor={textColor}
      >
        <a
          href={props.link}
          target={`${props.link}`.includes("https://") ? "_blank" : undefined}
        >
          <p className="counter">
            Nachricht {props?.index + 1} / {props?.totalCount}
          </p>
          {teaser || text ? (
            <div className="content">
              {props.title && <h3>{props.title}</h3>}
              {teaser ? (
                <p>{teaser}</p>
              ) : (
                <p>{richToTeaser(props.text, 130)}</p>
              )}
            </div>
          ) : (
            <div className="content">
              {props.title && <h3>{props.title}</h3>}
            </div>
          )}
        </a>
      </Contentrapper>
    </Wrapper>
  );
};
