import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://60852-vorschau.kuechenwerkstatt.de/
const PageLandingT19: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Footer brands />
     
  </>
);

PageLandingT19.displayName = "PageLandingT19";

export { PageLandingT19 };
