import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const wrapper: any = css`
  width: 100%;
  margin: auto;
  .slick-prev:before,.slick-next:before{
    color: #F19024;
  }
`;

export const ImgWrapper: any = styled.div`
  img {
    width: 360px;
    height: 160px;
  }
  @media (${BREAKPOINTS_DM.diamond_1920})
  {
    img {
      padding: 0 43px;
      margin: 0 0 20px;
    }
  }

`;
