import React, { Fragment, FunctionComponent, useEffect, useRef, useState, useContext } from "react";
import { Img, Context } from "src/common";

import * as styles from "./LogoBannerHorizontal.style";
import { SImageWrapper } from "./LogoBannerHorizontal.style";
import Marquee from "react-fast-marquee";
import { SLogoBannerHorizontal } from "src/common/components/LogoBannerHorizontal/LogoBannerHorizontal.style";
import { css } from "styled-components";

const LogoBannerHorizontal: FunctionComponent<any> = ({ shadowOverlayVisible }) => {
    const props = useContext(Context);
    const allBrands = props.AllBrand;

    if (allBrands.length <= 0) {
        return null;
    }

    const Companies = () => (
        <>
            {allBrands.map((company, i) => {
                if (!company?.logo.length) {
                    return null;
                }

                return (
                    <SImageWrapper key={i}>
                        <Img src={company?.logo} quality={200} alt={company?.name || ""} lazyload={false}/>
                    </SImageWrapper>
                );
            })}
        </>
    );

    return (
        <SLogoBannerHorizontal
            stylesOverwrite={css`
                margin: 10px 0;
            `}
        >
            <Marquee gradient={false}>
                <Companies />
            </Marquee>
            {shadowOverlayVisible && (
                <Fragment>
                    <div css={styles.shadowOverlay} />
                    <div style={{ right: 0, left: "unset", transform: "rotate(180deg)" }} css={styles.shadowOverlay} />
                </Fragment>
            )}
        </SLogoBannerHorizontal>
    );
};

export { LogoBannerHorizontal };
