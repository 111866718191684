import React, { FC, useContext } from "react";
import { Context, IPage } from "src/common";
import { SubNavigationContainer, SubNavigationWrapper } from "./SubNavigation.style";

const SubNavigation: FC = () => {
  const props = useContext(Context);
  const positionInformation = props.Settings.t12_positionInformation;
  const positionService = props.Settings.t12_positionService;
  return (
    <SubNavigationContainer>
      <SubNavigationWrapper>
        <h3><i className="fa fa-fw fa-caret-down" />Informationen</h3>
        {positionInformation?.map((item: any) => (
          <a href={item.slug} key={item.slug}>{item.nameInNavigation}</a>
        ))}
      </SubNavigationWrapper>

      <SubNavigationWrapper>
        <h3><i className="fa fa-fw fa-caret-down" />Service</h3>
        {positionService?.map((item: any) => (
          <a href={item.slug} key={item.slug}>{item.nameInNavigation}</a>
        ))}
      </SubNavigationWrapper>
    </SubNavigationContainer>
  );
};

export default SubNavigation;
