import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img";
import { FurtherAwardsWrapper, ImageWrapper } from "./FurtherAwards.style";

const FurtherAwards: FunctionComponent = () => 
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;

  return (
    <FurtherAwardsWrapper>
      {furtherAwards?.map((item, i) => (
        <ImageWrapper key={i}>
          <Img quality={1600} key={i} src={[{ src: item?.src }]} alt={item?.title ?? "image"}/>
        </ImageWrapper>
      ))}
    </FurtherAwardsWrapper>
  );
};

export default FurtherAwards;
