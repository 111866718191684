import styled, { css } from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";
import { textStyleDefaults } from "../../../../constants/textStyleDefaults";

export const wrapper: any = css<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  width: 100%;
  z-index: 1;
  position: relative;
  padding-top: 50px;
  padding-bottom: 25px;
`;

export const heading: any = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;
  display: block;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const columnsWrapper: any = css`
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    &:first-child {
      margin-right: 0;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    > div {
      width: 50%;
      &:first-child {
        margin-right: 20px;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
`;

export const mainRichtext: any = css`
  strong p {
    margin-top: 22px;
  }
  ul {
    margin-bottom: 2rem;
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  ${textStyleDefaults}
`;

export const toTopWrapper: any = css`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;
  svg {
    fill: ${colors.gray};
    transform: rotate(-90deg) scale(1.1);
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const TextWrapper: any = styled.div<{ color: string }>`
  ${textStyleDefaults}

  div.mobileHeading {
    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      &,
      span {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      display: none;
    }
  }
  color: ${({ color }) => (color ? color : "#222")};
`;
