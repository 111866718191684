import { css } from "styled-components";

export const wrapper: any = css`
    background-color: #bababa;
    display: flex;
    padding: 0 !important;
    justify-content: center;
    width: 100%;
    > div {
      border: solid 1px ${props => props.theme.secondary};
      border-bottom: none;
    }
`;
