import React, { useContext, FunctionComponent } from "react";
import { Context, ILandingEvent, RichText } from "src/common";

import { Wrapper, EventItem, EventIcon, LinkWrapper } from "./TeaserRight.style";

export const TeaserRight: FunctionComponent = () => {
  const props = useContext(Context);

  const eventsToRender = props?.PageLanding?.events?.filter((e: any) => {
    const eventItem: ILandingEvent = e;
    if (eventItem?.__typename !== "LandingEvent") {
      return false;
    }
    else if (!eventItem?.startDate && !eventItem?.endDate) {
      return true;
    }
    else if (!eventItem?.startDate && eventItem?.endDate) {
      return new Date() <= new Date(eventItem?.endDate);
    }
    else if (eventItem?.startDate && !eventItem?.endDate) {
      return new Date() >= new Date(eventItem?.startDate);
    }
    else {
      return new Date() >= new Date(eventItem?.startDate) && new Date() <= new Date(eventItem?.endDate);
    }
  });

  const eList: ILandingEvent[] = eventsToRender as any;

  const events = eList?.map((event, index: number) => 
  {
    if(!event)
    {
      return;
    }
    console.log(event)
    return (
      <EventItem key={event?._meta?.id ? event._meta?.id : index}>
        {event?.icon ? <EventIcon src={event.icon?.[0]?.src} alt={"eventIcon"} /> : null}
        <LinkWrapper href={event?.linksTo?.[0]?.slug ? event?.linksTo?.[0]?.slug : undefined}>
          <h3>{event?.headline}</h3>
          <div className={event?.linksTo?.[0]?.slug ? "afterArrow" : undefined}>
            <RichText textContent={event?.text} />
          </div>
        </LinkWrapper>
      </EventItem>
    );
  });

  if (!events) {
    return null;
  }

  return <Wrapper>{events}</Wrapper>;
};

export default TeaserRight;
