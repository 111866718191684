import React, { FC, useContext } from "react";
import {
  Context,
  ITextElement,
  ICallToAction,
  LandingBannerLeft,
  IPageLanding_Elements,
  IRichLinkItem,
  Img,
  IPageLanding_Events,
  ILandingEvent,
} from "src/common";

import BrandBannerVertical from "../../common/BrandBannerVertical";
import FurtherAwards from "../../common/FurtherAwards";
import { Section, Container } from "../../common/Grid";
import Hero from "../../common/Hero";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import {
  HeroContainer,
  HeroContent,
  CallToActionContainer,
  AboutUsContainer,
  BrandsContainer,
  KnowMoreContainer,
  NewsContainer,
  FurtherAwardsContainer,
  bannerLeftCss,
} from "./LandingContent.style";

const selectHeroLinks = (
  elements: IPageLanding_Elements[]
): IRichLinkItem[] => {
  const [call1, call2, call3] = elements?.filter(
    (e) => e.__typename === "RichLinkItem"
  ) as IRichLinkItem[];
  return [call1, call2, call3];
};

const selectKnowMoreLinks = (
  elements: IPageLanding_Elements[]
): IRichLinkItem[] => {
  const [call1, call2, call3, ...more] = elements?.filter(
    (e) => e.__typename === "RichLinkItem"
  ) as IRichLinkItem[];
  return more;
};

const selectCallToAction = (
  elements: IPageLanding_Elements[]
): ICallToAction[] =>
  elements?.filter((e) => e.__typename === "CallToAction") as ICallToAction[];

const selectLandingEvent = (events: IPageLanding_Events[]): ILandingEvent =>
  events?.[0];

const LandingContent: FC = () => {
  const props = useContext(Context);
  const { PageLanding: page } = props;

  const mainText = page?.elements?.find(
    (e) => e.__typename === "TextElement"
  ) as ITextElement;

  const heroRichLinks = selectHeroLinks(page?.elements);
  const knowMoreLinks = selectKnowMoreLinks(page?.elements);

  const heroEvent = selectLandingEvent(page?.events);
  const isNewsCustom = page?.NewsCustom?.length > 0;
  const [heroCallToAction, aboutUsCallToAction, knowMoreCallToAction] =
    selectCallToAction(page?.elements);

  return (
    <Section>
      <HeroContainer>
        <Hero page={page} />

        <HeroContent>
          <Container>
            {heroCallToAction && (
              <>
                <h1>
                  <RichTextGeneral
                    textContent={heroCallToAction?.TextAboveButton}
                    fragment
                  />
                </h1>
                <RichTextGeneral
                  css={""}
                  textContent={heroCallToAction?.TextBelowButton}
                  fragment
                />
                <a
                  href={heroCallToAction?.linkTo?.[0]?.slug}
                  className="button"
                >
                  {heroCallToAction?.ButtonLabel}
                </a>
              </>
            )}
          </Container>

          <article>
            <a href={heroEvent?.linksTo?.[0]?.slug}>
              <b>{heroEvent?.headline}</b>
              <small>
                <RichTextGeneral textContent={heroEvent?.text} fragment />
              </small>
              <br />
              <br />
              <b>MEHR</b>
            </a>
          </article>
        </HeroContent>

        <FurtherAwardsContainer>
          <FurtherAwards />
        </FurtherAwardsContainer>
      </HeroContainer>

      <CallToActionContainer>
        <Container>
          {heroRichLinks?.map((link, i) => (
            <a key={`hero-link-${i}`} href={link.linkTo?.[0]?.slug}>
              <Img src={link.image} />
              <b>{link.headline}</b>
            </a>
          ))}
        </Container>
      </CallToActionContainer>

      {aboutUsCallToAction && (
        <AboutUsContainer>
          <div className="title">
            <RichTextGeneral
              textContent={aboutUsCallToAction?.TextAboveButton}
            />
          </div>
          <RichTextGeneral textContent={aboutUsCallToAction?.TextBelowButton} />
          <br />
          <a className="primary" href={aboutUsCallToAction?.linkTo?.[0]?.slug}>
            {aboutUsCallToAction?.ButtonLabel}
          </a>
        </AboutUsContainer>
      )}

      <BrandsContainer>
        <Container>
          <BrandBannerVertical />
        </Container>
      </BrandsContainer>

      <KnowMoreContainer>
        <h1>{mainText?.headline}</h1>
        <RichTextGeneral textContent={mainText?.text} fragment />

        {knowMoreLinks?.length > 0 && (
          <div className="linksContainer">
            {knowMoreLinks?.map((link, i) => (
              <a key={`know-more-link-${i}`} data-richlink>
                <Img src={link.image} />
                <h3>{link.headline}</h3>
                <RichTextGeneral textContent={link?.richDesc} />
              </a>
            ))}
          </div>
        )}
        <br />
        {knowMoreCallToAction && (
          <>
            <a
              className="primary inverted"
              href={knowMoreCallToAction?.linkTo?.[0]?.slug}
            >
              {knowMoreCallToAction?.ButtonLabel}
            </a>
          </>
        )}
      </KnowMoreContainer>

      <NewsContainer>
        <h1>Aktuelles</h1>
        <NewSlider />
        {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
      </NewsContainer>
      <LandingBannerLeft overrideStyles={bannerLeftCss} />
    </Section>
  );
};

export default LandingContent;
