import React, { FunctionComponent } from "react";
import { LandingBannerLeft } from "src/common";

import { Container } from "../../../BaseComponents/Grid";
import { Footer } from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";

import LandingContent from "../../content/LandingContent/LandingContent";
const PageLandingT8: FunctionComponent = () => 
  (
    <Container>
      <Header />
      <LandingContent />
      <LandingBannerLeft/>
      <Footer />
       
    </Container>
  );
PageLandingT8.displayName = "PageLandingT8";
export { PageLandingT8 };
