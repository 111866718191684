import React, { FC, useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context } from "src/common";

import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";
import Img from "../../../BaseComponents/Img/Img";
import { BrandBannerVerticalWrapper, BrandSlide } from "./BrandBannerVertical.style";

const sliderSettings: Settings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
};

const BrandBannerVertical: FC = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;

  if (!allBrands || !allBrands.length) {
    return null;
  }

  return (
    <BrandBannerVerticalWrapper>
      <div
        css={`
          ${SSlickBase}
          ${SSlickTheme}
        `}
      >
        <Slider {...sliderSettings}>
          {allBrands.filter(c => !!c?.logo?.[0]?.src).map((company) => company?.logo && (
            <BrandSlide>
              <Img
                src={company?.logo}
                lazyload={false}
                placeholderHeight={"84px"}
                quality={200}
                alt={company?.name || ""}
              />
            </BrandSlide>
          ))}
        </Slider>
      </div>
    </BrandBannerVerticalWrapper>
  );
};

export default BrandBannerVertical;
