import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const SliderWrapper: any = styled.div`
  position: absolute;
  width: 100%;
  border: none;
  right: 0;

  div {
    border: none;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 88%;
    bottom: 38px;

    img {
      height: 464px;
    }
  }
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    top: 125px;
    width: 2278px;
    div > img {
      height: 515px;
    }
  }
`;
