import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../constants/colors";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: auto;
`;

export const contentWrapper: any = css`
  display: flex;
  flex-direction: column;
  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
`;

export const leftContent: any = css`
  width: 100%;
  padding: 10px;
  h1 {
    display: flex;
    background-color: ${colors.light_blue};
    color: ${colors.white};
    font-weight: 400;
    padding: 3px 0;
    line-height: 24px;
    font-size: 22px;

    :before {
      content: "»";
      padding: 0 5px;
      color: #008288;
      font-weight: 100;
      font-size: xx-large;
      line-height: 18px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}){
    padding: 21px;
    width: 50%;
  }
`;

export const sideBar: any = css`
    width: 100%;
    max-width: initial;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 50%;
        margin-left: auto;
        max-width: 500px;
        padding-left: 21px;
    }
`;

export const mainText: any = css`
    width: 100%;
`;

export const BannerHorizontalWrapper: any = styled.div`
    background-color: rgba(255,255,255,0.5);
    margin: 15px 0 0;
`;
