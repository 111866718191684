import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import { SlideWrapper, ImgWrapper, Counter, Content, ContentWrapper, Wrapper } from "./NewSlide.style";
import { richToTeaser } from "../../../../../../common/utils/text/teaser";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  teaser?: string;
}

const NewSlide: FunctionComponent<IProps> = props =>
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  const teaser = props.teaser ? props.teaser : props?.text && richToTeaser(props.text);
  
  return (
    <Wrapper>
      <SlideWrapper withImage={!!props.img}>
        {props.img && (
          <ImgWrapper>
            <Img quality={600} lazyload={false} src={props.img} alt={props.imgAlt} />
          </ImgWrapper>
        )}
        <ContentWrapper>
          <Counter>
            <h3>Küchennachricht {props?.index + 1} / {props?.totalCount}</h3>
          </Counter>
          <a href={props?.link}>
              <Content>
                {props.title && <h3>{props.title}</h3>}
                {teaser && (<p>{teaser}</p>)}
              </Content>
          </a>
        </ContentWrapper>
      </SlideWrapper>
    </Wrapper>
  );
};

export default NewSlide;
