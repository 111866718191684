import styled from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ItemDetails = styled.div`
  display: none;
  position: absolute;
  bottom: 30px;
  left: 30px;

  h3 {
    color: ${({ theme }) => theme.palette.white};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.75rem;
    word-break: break-word;
    text-shadow: ${({ theme }) => theme.palette.textColor} 0 0 10px;
    margin-bottom: 0;
  }
`;


export const ProductContainer = styled.div<{itemWidth: string}>`
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: ${({ itemWidth }) => itemWidth};
    margin-bottom: 18px;
  }

  :hover {
    ${ItemDetails} {
      display: block;
    }
  }
`;

// export const ItemDescription = styled.div`

//   * {
//     font-size: 13px;
//     line-height: 1.4;
//     margin: 0;
//     padding: 0;
//     font-weight: 400;
//     overflow-x: hidden;
//     color: #666;
//   }
// `;

// export const ItemPrice = styled.div`
//   span:first-child {
//     font-size: 13px;
//     line-height: 1.4;
//     display: inline-block;
//     padding: 3px 20px;
//     margin-bottom: -3px;
//     margin-right: 8px;
//     background: #bbb;
//     font-weight: 700;
//     color: #fff;
//   }
// `;

export const ItemPicture = styled.div`
  width: 100%;

  img {
    height: 364px !important;
  }
`;

export const Button: any = styled.a`
  display: inline-block;
  font-size: .8125rem;
  padding: .1875rem .625rem;
  position: relative;
  background-color: rgba(121,102,98,.4);
  border: 1px solid #fff;
  color: #fff;

  svg {
    display: inline-block;
    margin-left: 5px;
    width: 16px;
    height: 8px;
    stroke: #FFF;
    stroke-width: 3px;
    fill: none;
    transform: rotate(-90deg);
  }

  :hover {
    background-color: rgba(121,102,98,.8);
  }
`;

export const PromotionalOffertWrapper: any = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap; 
  justify-content: space-between;

`