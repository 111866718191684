import React, { FC } from "react";
import { useDealerSearch } from "src/common/hooks/useDealerSearch";

import { SSearch } from "./Search.style";

export const Search: FC<{
  textContent?: any;
  css?: any;
  fragment?: boolean;
}> = (props) => {
  const { triggerSearch } = useDealerSearch();
  return (
    <SSearch>
      PLANER DER KÜCHENGILDE IN IHRER NÄHE
      <form
        onSubmit={(e) => {
          e.preventDefault();
          triggerSearch((e.target as any)?.elements?.[0]?.value);
        }}
      >
        <input type="text" />
        <input type="submit" value="" />
      </form>
      PLZ/ORT
    </SSearch>
  );
};

export { Search as default };
