
import React, { FunctionComponent } from "react";

import RichText from "../../common/RichText";
import * as styles from "./CallToAction.style";

export interface ICallToAction
{
  ButtonLabel?: string;
  TextAboveButton?: string;
  TextBelowButton?: string;
  linkTo?: [{slug: string}];
}

const CallToAction: FunctionComponent<ICallToAction> = props => (
  <div css={styles.wrapper}>
    {props.TextAboveButton && <RichText textContent={props.TextAboveButton}></RichText>}
    {props.ButtonLabel && <a href={props.linkTo?.[0]?.slug}>
      <div>{props.ButtonLabel}</div>
    </a>}
    {props.TextBelowButton && <RichText textContent={props.TextBelowButton}></RichText>}
  </div>
);

export default CallToAction;
