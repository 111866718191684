import Link from "next/link";
import React, { FC, useContext, useState, useEffect } from "react";
import { Context, IRichLinkItem, ICallToAction, LandingBannerLeft, Img } from "src/common";
import { useModal } from "../../../../../common/contexts/modal";
import { getLinkFromConnection } from "../../../../../common/utils/getLinkFromConnection";
import { richToPlainText } from "../../../../../common/utils/text/plaintext";

import NewSlider from "../../common/NewSlider";
import PlanningDateModaL from "../../common/PlanningDateModaL";
import { RichTextGeneral } from "../../common/RichText";
import Hero from "./Hero";
import { SectionWrapper, RichLinkContainer, RichLinkWrapper, RichLinkSection, Bottom } from "./LandingContent.style";

export const planningDateModalName = "planningDateModal";
const LandingContent: FC = () => {
    const props = useContext(Context);
    const { PageLanding: page, Settings } = props;
    const showBoxFroPlanningDate = `${Settings?.appointmentRequestDisplay}`.toLowerCase() === "standard";

    let richLink = page?.elements?.filter((e) => e.__typename === "RichLinkItem") as IRichLinkItem[];

    if (showBoxFroPlanningDate) {
        const universalTexts = props?.UniversalTexts;
        const customPlanningDateText = universalTexts?.planningDateText;

        const planningDateRichLink = {
            __typename: "RichLinkItem",
            headline:
                universalTexts?.planningDateHeadline && universalTexts?.planningDateHeadline != ""
                    ? universalTexts.planningDateHeadline
                    : "Terminanfrage",
            richDesc:
                customPlanningDateText && richToPlainText(customPlanningDateText) != ""
                    ? customPlanningDateText
                    : {
                          content: [
                              {
                                  content: [
                                      {
                                          text: "Nennen Sie uns Ihren Wunschtermin",
                                          type: "text",
                                      },
                                  ],
                                  type: "paragraph",
                              },
                          ],
                          type: "doc",
                      },
        } as any;

        planningDateRichLink[planningDateModalName] = true;
        if (richLink.length == 0) {
            richLink = [planningDateRichLink];
        }
        if (richLink.length > 0) {
            richLink = [richLink[0], planningDateRichLink, ...(richLink[1] ? richLink.slice(1) : [])];
        }
    }
    const buttonElement = page?.elements?.find((elem) => elem?.__typename === "CallToAction") as ICallToAction;
    const { activeModals, setModalActive, setModalInactive } = useModal();
    const isShowModal = activeModals.includes(planningDateModalName);
    const [isShowBanner, toogleShowBanner] = useState(true);
    const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;

    useEffect(() => {
        if (isShowBanner) {
            setTimeout(() => toogleShowBanner(false), 7000);
        }
    });

    return (
        <SectionWrapper>
            <Hero />
            {isShowModal && (
                <PlanningDateModaL
                    onSetShowModal={(v) =>
                        v ? setModalActive(planningDateModalName) : setModalInactive(planningDateModalName)
                    }
                />
            )}
            <RichLinkSection>
                {richLink?.map((richLinkItem, ind) => {
                    const { href, valid, shallow, target } = richLinkItem?.linkTo
                        ? getLinkFromConnection(richLinkItem?.linkTo)
                        : ({} as any);

                    const firstOfTwo = ind > 0 && ind % 2 === 1 && !(ind + 1 == richLink.length);
                    const full = ind < 1 || (richLink.length % 2 === 0 && ind + 1 == richLink.length);
                    return (
                        <RichLinkWrapper
                            key={`richlink-${ind}`}
                            half={!full && ind > 0}
                            firstOfTwo={firstOfTwo}
                            full={ind < 1 || (richLink.length % 2 === 0 && ind + 1 == richLink.length)}
                            hasSrc={!!richLinkItem?.image?.[0]?.src}
                        >
                            <div className="parent">
                                {richLinkItem?.image?.[0]?.src && (
                                    <div className="imgwrapper">
                                        <Img src={richLinkItem.image[0].src}></Img>
                                    </div>
                                )}
                                <div className="rich-link-content">
                                    <RichLinkContainer>
                                        <div className="content">
                                            <h3>{richLinkItem?.headline}</h3>
                                            <RichTextGeneral fragment textContent={richLinkItem?.richDesc} />
                                            {richLinkItem[planningDateModalName] ? (
                                                <a
                                                    onClick={() => {
                                                        setModalActive(planningDateModalName);
                                                    }}
                                                >
                                                    <span>Beratungstermin vereinbaren</span>
                                                </a>
                                            ) : (
                                                <>
                                                    {valid && (
                                                        <Link passHref href={href} shallow={shallow}>
                                                            <a target={target}>
                                                                <span>Mehr erfahren</span>
                                                            </a>
                                                        </Link>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </RichLinkContainer>
                                </div>
                            </div>
                        </RichLinkWrapper>
                    );
                })}
                <Bottom>
                    <div className="left-side">
                        <RichTextGeneral fragment textContent={buttonElement?.TextAboveButton}></RichTextGeneral>{" "}
                    </div>
                    <div className="right-side">
                        <a className="button-bottom" href={props?.PageContact?.slug}>
                            {buttonElement?.ButtonLabel || "So kommen Sie zu uns"}
                        </a>
                    </div>
                </Bottom>
                <NewSlider />
                {isNewsCustom && <NewSlider newCustom={isNewsCustom} />}
            </RichLinkSection>
            {isShowBanner && <LandingBannerLeft overrideStyles={`z-index: 190;`} />}
        </SectionWrapper>
    );
};

export default LandingContent;
