import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container, ContainerLarge } from "../../common/Grid";

export const sideBar: any = css`
  width: 100%;
  max-width: initial;
  padding-left: 10px;
  padding-right: 10px;

  @media (${BREAKPOINTS_DM.silver_768})
  {
    width: 50%;
    max-width: 500px;
    padding-left: 21px;
    padding-right: 0;
  }
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`;

export const bannerLeftCss: any = css`
  font-family: "Open Sans";

  h1 {

    padding: 15px 0;
    margin: 0;

    &, * {
      font-size: 32px;
      font-weight: 300;
      color: white;
    }
  }

  p {
    &, span, * {
      font-size: 18px;
      line-height: 1.25rem !important;
      color: ${({ theme }) => theme.palette.textColor };
    }
  }

`;

export const HeroContainer = styled.div`
  position: relative;
  height: 400px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 600px;
    margin: 0 0 21px 0;
  }

  > div > div {
    border: none;

    img {
      filter: grayscale(6%) brightness(96.4%);
    }
  }

  .slick-next {
    display: none !important;
  }
`;

export const HeroCallToAction = styled.div`

  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;

  * {
    color: ${({ theme }) => theme.palette.white };
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  ${ContainerLarge} {
    display: block;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    bottom: 6.25rem;
    top: unset;
  }
`;

export const HeroContent: any = styled.div`
  h1, h2 {
    isplay: inline;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.3rem;
    padding: 0rem .5rem;
    margin-bottom: 0;
  }

  a {
    font-size: .8125rem;
    padding: .1875rem .625rem;
    position: relative;
    background-color: rgba(121,102,98,.4);
    border: 1px solid #fff;
    display: inline-block;
    

    p {
      margin: 0;

      span {
        :after {
          transform: rotate(-90deg);
          content: '';
          background: transparent url(/images/icon-arrow-white.svg) 0 0 no-repeat;
          display: inline-block;
          position: relative;
          top: -.0625rem;
          width: 1rem;
          height: .5rem;
          right: -.3125rem;
        }
      }
    }



    :hover {
      background-color: rgba(121,102,98,.8);
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3);
    margin-left: 62px;

    h1, h2 {
      isplay: inline;
      font-size: 2rem;
      line-height: 2.75rem;
    }

    a {
      margin-top: .625rem;
    }
  }
`;

export const FurtherAwardsContainer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
`;

export const BaseTextContainer = styled(Container)`
  flex-direction: column;
  padding: 40px 15px;
  margin-bottom: 40px;

  h1, h2 {
    font-size: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.textColorHeadings};
    text-align: center;
  }
`;

export const MainTextContainer = styled(BaseTextContainer)`
  margin: 0 auto 90px auto;
  max-width: 890px;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 115px auto 90px auto;
  }
`;

export const BaseTextContainerLarge = styled(ContainerLarge)`
  flex-direction: column;
  padding: 40px 15px;
  margin-bottom: 40px;

  h1, h2 {
    font-size: 32px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.textColorHeadings};
    text-align: center;
  }

  ${Container} {
    max-width: 890px;
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const PromotionalOfferContainer = styled(BaseTextContainerLarge)`
  padding: 0 15px;
  .slick-dots {
    bottom: -30px!important;
    li {
      line-height: 0;
      button {
        background-color: #DDDDDD!important;
        border: none!important;
      }
    }
  }
  li.slick-active {
    button {
      border: 2px solid #DDDDDD!important;
      background-color: #fff!important;
    }
  }
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 15px;
  }
`;

export const NewsContainer = styled(BaseTextContainerLarge)`
  flex-direction: column;
  padding: 40px 15px;
  margin-bottom: 40px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 3.125rem 0 1.875rem;
  }

  > div {
    flex: 1;
    > h1 {
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color: #222;;
    }
  }

  > h2 {
    padding: 20px 15px;
    text-align: center;
    font-size: 24px;
    color: #222;
  }


  > a {
    align-self: center;
    margin-top: 25px;
    display: inline-block;
    padding: 6px 30px;
    font-weight: 600;
    font-size: 11px;
    letter-spacing: .3px;
    color: #adadad;
    line-height: 1;

    border-radius: 18px;
    border: 2px solid #adadad;
    cursor: pointer;
    text-shadow: none;

    &:hover {
      background-color: #adadad;
      color: white;
    }
  }
`;

export const ContactContainer = styled(Container)`
  flex-direction: column;
  margin-bottom: 10px;
  h3 {
    color: ${props => props.theme.palette.textColorHeadings};
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.25rem;
    text-align: center;
    flex: 1;
  }

  strong {
    font-weight: 700;
  }

  table {
    display: flex;
    flex-direction: column;
    @media (${BREAKPOINTS_DM.silver_768}) {
      flex-direction: inline-block;
      width: 100%;
    }
    tr {
      flex-direction: column;
      display: flex;
      @media (${BREAKPOINTS_DM.silver_768}) {
        flex-direction: row;
      }

      td {
        width: 100%;
        p {
          padding: 0;
        }
      }
    }
  }
`;
