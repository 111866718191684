import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const FadeSliderWrapper: any = styled.div`
  width: 100%;
  div {
    border: none;
  }
`;

export const HeroWrapper: any = styled.div`
  h1.title {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 280px;
    color: #fff;
    font-size: 34px;
    font-weight: 500;
    line-height: 63.6px;
    margin-bottom: 10px;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }
  div {
    img {
      padding-top: 80px;
      min-width: 100%;
      min-height: 580px;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    h1.title {
      font-size: 53px;
      top: 465px;
    }
    div {
      img {
        padding-top: 112px;
      }
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    h1.title {
      top: 530px;
    }
    
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    h1.title {
      top: 635px;
    }
  }
`;
