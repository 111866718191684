import React, { FC, useContext } from "react";
import {
  Context,
  ITextElement,
  IAsset,
  ISlider,
  ICallToAction,
  IPageLanding_Elements,
  Img
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import { Section } from "src/templates/t17/BaseComponents/Grid";

import Map from "../../common/Map";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import * as styles from "./LandingContent.style";
import {
  SectionWrapper,
  HeroWrapper,
  ButtonHero,
  ButtonHeroContent,
  TextConten,
  RichTextWrapper,
  NewSliderWrapper,
  ContentWrapper,
  MapContact,

  ContainerText
} from "./LandingContent.style";

export const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
 
  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
        />
      );
    }
  }

  return imageOrGallery;
};

const TopButtons: FC<{ actions: ICallToAction[] }> = ({ actions }) =>
{ 
  const [action1, action2] = actions;
  return (
    <ButtonHero>
      <div>
        {action1 && 
          <ButtonHeroContent>
            <div className="text"><RichTextGeneral textContent={action1?.TextAboveButton}/></div>
            <a className="button" href={`/${action1?.linkTo[0]?.slug}`}>
              <span>{action1?.ButtonLabel}</span>
              <div>
                <i className="fa fa-chevron-right"/>
              </div>
            </a>
          </ButtonHeroContent>
        }
        {action2 && 
          <ButtonHeroContent>
            <div className="text"><RichTextGeneral textContent={action2?.TextAboveButton}/></div>
            <a className="button" href={`/${action2?.linkTo[0]?.slug}`}>
              <span>{action2?.ButtonLabel}</span>
              <div>
                <i className="fa fa-chevron-right"/>
              </div>
            </a>
          </ButtonHeroContent>
        }
      </div>
    </ButtonHero>
  );
};

const LandingContent: FC = () =>
{
  const props = useContext(Context);
  const { PageLanding: page } = props;
  const backgroundImg = page.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const selectCallToAction = (elements: IPageLanding_Elements[]): ICallToAction[] => (
    elements?.filter(e => e.__typename === "CallToAction") as ICallToAction[]
  );
  const isNewsCustom = props?.PageLanding?.NewsCustom.length > 0;
  
  return (
    <SectionWrapper bgImage={backgroundImg?.length > 0 ? backgroundImg[1]?.src : backgroundImg[0]?.src}>
      <Section>
        <HeroWrapper>
          <Hero />
          <TopButtons actions={selectCallToAction(page?.elements)}/>
        </HeroWrapper>
        <ContentWrapper>
          <TextConten>
            {(props.PageLanding?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[])?.map(
              (t, i) => (
                <>
                  <RichTextWrapper key={`rt0-${i}`}>
                    <ContainerText>
                      <RichTextGeneral
                        key={`rt0-${i}`}
                        textContent={t.text}
                        css={styles.mainText}
                      >
                        <h1>{t?.headline}</h1>
                      </RichTextGeneral>
                    </ContainerText>
                  </RichTextWrapper>
                  {i === 0 && <div className="parallax-mirror"/>}
                </>
              )
            )}
          </TextConten>
          <NewSliderWrapper>
            <NewSlider />
          </NewSliderWrapper>
          {isNewsCustom &&
            <NewSliderWrapper>
              <NewSlider newsCustom={isNewsCustom} />
            </NewSliderWrapper>
          }

          <MapContact>
            <Map landingPage/>
          </MapContact>

        </ContentWrapper>
      </Section>
    </SectionWrapper>
  );
};

export default LandingContent;
