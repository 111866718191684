import React, { FC } from "react";

import { Grid } from "../../../BaseComponents/Grid";
import Banner from "../../common/Banner";
import ContentWrapper from "../../common/ContentWrapper";
import Footer from "../../common/Footer";
 ;
import LandingContent from "../../content/LandingContent";
import { LandingBannerLeft } from "src/common";

// https://60026-vorschau.dk-das-kuechenhaus.de/
const PageLandingT12: FC = () => (
  <>
    <ContentWrapper>
      <Grid>
        <LandingContent />
      </Grid>
      <Banner />
      <Footer />
       
      <LandingBannerLeft />
    </ContentWrapper>
  </>
);

PageLandingT12.displayName = "PageLandingT12";

export { PageLandingT12 };
