import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const wrapper: any = css`
    cursor: pointer;
    display: block;
    position: relative;
    > div, span{
        transition: opacity 0.3s ease !important;
    }
    > div {
        opacity: 1;
    }
    > span {
        opacity: 1;
        @media (${BREAKPOINTS_DM.silver_768}) {
                opacity: 0;
            }
    }
    &:hover {
        > span {
            opacity: 1;
        }
        > div {
            opacity: 0;
        }
    }
`;

export const img: any = css`
    width: 100%;
    height: 100%;
`;

export const span: any = css`
    font-weight: 800;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 16px;
    word-wrap: break-word;
    width: 35vmin;
    @media (${BREAKPOINTS_DM.silver_768}) {
        width: 170px;
        font-size: 20px;
    }
`;
