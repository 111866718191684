import { css } from "styled-components";
import { SDachseiteSearch } from "./DachseiteSearch.style";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const MusterkuechenLink = () => {
  return (
    <SDachseiteSearch
      href="https://musterkuechen.dk-das-kuechenhaus.de"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          border: white 2px solid;
          margin: 30px 0;
          padding: 0px 40px 0px 40px;
          height: 100px;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div>
          <img src="images/dk_icon_percent.png" />
        </div>
        <div>
          <h2
            css={css`
              font-size: 16px;
              line-height: 18px;
              padding-left: 10px;
              padding-right: 10px;
              padding-bottom: 0px;
              font-weight: bold;
              letter-spacing: -1px;
              text-transform: uppercase;
              margin: 0;
              :hover {
                text-decoration: underline;
              }

              @media (${BREAKPOINTS_DM.silver_768}) {
                font-size: 37px;
                line-height: 37px;
              }
            `}
          >
            Günstige Ausstellungs&shy;küchen
          </h2>
        </div>
        <div>
          <div
            css={css`
              :hover {
                text-decoration: underline;
              }
            `}
          >
            {" "}
            <img src="images/dk_icon_link_arrow.jpeg" />
            {" mehr"}
          </div>
        </div>
      </div>
    </SDachseiteSearch>
  );
};
