import { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const wrapper: any = css`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: auto;
`;

export const contentWrapper: any = css`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
`;

export const leftContent: any = css`
  width: 100%;
  padding: 10px;
  @media (${BREAKPOINTS_DM.silver_768}){
    padding: 21px;
    width: 50%;
  }
`;

export const sideBar: any = css`
    width: 100%;
    max-width: initial;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 50%;
        margin-left: auto;
        max-width: 500px;
        padding: 21px;
    }
`;

export const mainText: any = css`
    width: 100%;
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`
