import React, { FunctionComponent, useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context, IAsset } from "src/common";

import { Wrapper } from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

export const NewSlider: FunctionComponent<{newsCustom?: boolean}> = ({ newsCustom }) =>
{
  const props = useContext(Context);
  const news = newsCustom ? props?.PageLanding?.NewsCustom : props.PageLanding?.News;
  const newsSlug = props.NewsSlug;

  if(news?.length <= 0)
  {
    return null;
  }

  const sliderSettings: Settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
  };
  return (
    <Wrapper isLast={newsCustom}>
      <Slider {...sliderSettings}>
        {news?.map((newsItem, i) => (
          <NewSlide
            key={i}
            index={i}
            totalCount={news?.length}
            img={(newsItem?.extras?.find(e => e?.__typename === "Asset") as IAsset)?.src}
            imgAlt={(newsItem?.extras?.find(e => e?.__typename === "Asset") as IAsset)?.title}
            title={newsItem?.headline}
            text={newsItem?.text}
            link={newsSlug + "/" + newsItem?.id}
          />
        ))}
      </Slider>
    </Wrapper>
  );
};
