import { Context, Img } from "src/common";

import React, { FunctionComponent, useContext } from "react";

import { FurtherAwardsWrapper, ImageWrapper } from "./FurtherAwards.style";

const FurtherAwards: FunctionComponent = () => 
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;
  // console.log(` furtherAwards`, furtherAwards);
  return (
    <FurtherAwardsWrapper>
      {furtherAwards?.map((item, i) => (
        <ImageWrapper key={i}>
          <Img quality={1600} key={i} src={[{ src: item?.src, __typename: "Asset" }]} alt={item?.title ?? "image"}/>
        </ImageWrapper>
      ))}
    </FurtherAwardsWrapper>
  );
};

export default FurtherAwards;
