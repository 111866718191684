import React, { useContext, FunctionComponent } from "react";
import { Context, ILandingEvent, RichText } from "src/common";

import * as styles from "./TeaserRight.style";
import { css } from "styled-components";

export const TeaserRight: FunctionComponent = () => {
  const props = useContext(Context);

  const eventsToRender = props?.PageLanding?.events?.filter((e: any) => {
    const eventItem: ILandingEvent = e;
    if (eventItem?.__typename !== "LandingEvent") {
      return false;
    } else if (!eventItem?.startDate && !eventItem?.endDate) {
      return true;
    } else if (!eventItem?.startDate && eventItem?.endDate) {
      return new Date() <= new Date(eventItem?.endDate);
    } else if (eventItem?.startDate && !eventItem?.endDate) {
      return new Date() >= new Date(eventItem?.startDate);
    } else {
      return (
        new Date() >= new Date(eventItem?.startDate) &&
        new Date() <= new Date(eventItem?.endDate)
      );
    }
  });

  // console.log(` eventsToRender`, eventsToRender);
  const eList: ILandingEvent[] = eventsToRender as any;
  const events = eList?.map((e, index: number) => {
    const event = e;
    if (!event) {
      return;
    }
    return (
      <div
        css={styles.eventItem}
        key={event?._meta?.id ? event._meta?.id : index}
      >
        {event.icon?.[0]?.src ? (
          <img
            css={styles.eventIcon}
            src={event.icon?.[0]?.src}
            alt={"eventIcon"}
          />
        ) : null}
        <a
          css={styles.teaserTextWrapper}
          href={
            event?.linksTo?.[0]?.slug ? event?.linksTo?.[0]?.slug : undefined
          }
        >
          <h3>{event?.headline}</h3>
          <div className={event?.linksTo?.[0]?.slug ? "afterArrow" : undefined}>
            <RichText
              textContent={event?.text}
            ></RichText>
          </div>
        </a>
      </div>
    );
  });

  if (!events) {
    return null;
  }

  return <div css={styles.wrapper}>{events}</div>;
};
