import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 10.5px;
`;

export const ImageWrapper: any = styled.div`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;

    :first-child {
      padding-right: 10.5px;
    }

    :last-child {
      padding-left: 10.5px;
    }
  }
`;

