import React, { useContext, Fragment, FunctionComponent } from "react";
import {
  Context,
  ITextElement,
  LogoBannerHorizontal,
  IAsset,
  ISlider,
} from "src/common";

import FadeSlider from "../../../../../common/components/FadeSlider/Slider";
import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio/ImageWithAspectRatio";
import { NewSlider } from "../NewSlider/NewSlider";
import RichText from "../RichText/RichText";
import SRichTextGeneral from "../RichText/Styles/SRichTextGeneral";
import { ServiceNumber } from "../ServiceNumber/ServiceNumber";
import { TeaserRight } from "../TeaserRight/TeaserRight";
import * as styles from "./LandingContent.style";
import FurtherAwards from "../FurtherAwards/FurtherAwards";

export const LandingContent: FunctionComponent = () => {
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(
    (e) => e?.__typename === "Asset"
  ) as IAsset[];
  const slider = props.PageLanding?.elements?.filter(
    (e) => e?.__typename === "Slider"
  ) as ISlider[];
  const isNewsCustom = props.PageLanding?.NewsCustom.length > 0;

  let imageOrGallery;
  if (slider && !!slider?.[0]?.elements) {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  } else if (imgArray?.[0]) {
    if (imgArray.length > 1) {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map((f) => f)]} />;
    } else {
      imageOrGallery = (
        <ImageWithAspectRatio
          alt={imgArray?.[0]?.title}
          src={[imgArray?.[0]]}
          height={342}
          ratio={50}
          quality={1080}
        />
      );
    }
  }
  return (
    <div css={styles.wrapper}>
      {imageOrGallery || null}
      <div css={styles.contentWrapper}>
        {(
          props.PageLanding?.elements?.filter(
            (e) => e?.__typename === "TextElement"
          ) as ITextElement[]
        )?.map((t, i) => {
          const headline1 = t?.headline?.split("\n")[0];
          const headline2 = t?.headline?.split("\n")[1];
          return (
            <Fragment key={`rt0-${i}`}>
              <RichText
                overwriteStyle={SRichTextGeneral}
                textContent={t.text}
                css={styles.mainText}
              >
                {headline1 && <h1>{headline1}</h1>}
                {headline2 ? <h1 className="h1-sub">{headline2}</h1> : null}
              </RichText>
            </Fragment>
          );
        })}
        <div css={styles.sideBar}>
          <ServiceNumber css={styles.serviceNumber} />
          <TeaserRight />
          <NewSlider />
          {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
          <FurtherAwards/>
        </div>
      </div>
      {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
        <a
          href={"https://www.kuechenkompass.com/"}
          style={{ marginTop: "20px" }}
        >
          <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
        </a>
      ) : null}
      <LogoBannerHorizontal shadowOverlayVisible={true} />
    </div>
  );
};
