import React, { FunctionComponent, useContext, useState } from "react";
import { IContext, Context, getPage } from "src/common";
import { LogoBannerHorizontal } from "src/common/components/LogoBannerHorizontal";

import UniversalComponentsRenderer from "../../../BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import ContactBanner from "../../common/Contact/ContactBanner";
import FurtherAwards from "../../common/FurtherAwards/FurtherAwards";
import { NewSlider } from "../../common/NewSlider/NewSlider";
import { PlanningDateModaL } from "../../common/PlanningDateModaL/PlanningDateModaL";
import * as styles from "./LandingContent.style";
import { LogoBannerWraper, Separator, NewSliderWrapper } from "./LandingContent.style";

interface FunctionInterface
{
  children?: React.ReactNode;
  topChildren?: React.ReactNode;
}

export const MainAreaWrapper: FunctionComponent<FunctionInterface> = props =>
{
  const caisyProps = useContext<IContext>(Context);
  const elements = getPage(caisyProps)?.elements;
  const isNewsCustom = caisyProps?.PageLanding?.NewsCustom?.length > 0;

  const [isShowModal, setShowModal] = useState(false);
  return (
    <div css={styles.wrapper}>
      <div css={styles.pageContent}>
        {!(caisyProps?.PageImprint?.slug && caisyProps?.PageImprint?.slug === caisyProps.slug) && (
          <div>
            {isShowModal && (
              <PlanningDateModaL onSetShowModal={setShowModal}/>
            )}
            <UniversalComponentsRenderer items={elements} onSetShowModal={setShowModal}>
              {props.topChildren}
            </UniversalComponentsRenderer>
            <Separator/>
            <NewSliderWrapper>
              <NewSlider />
            </NewSliderWrapper>
            {isNewsCustom && (
              <>
                <Separator/>
                <NewSliderWrapper>
                  <NewSlider newsCustom={isNewsCustom}/>
                </NewSliderWrapper>
              </>
            )}

            <LogoBannerWraper>
              <LogoBannerHorizontal shadowOverlayVisible={false}/>
            </LogoBannerWraper>
            <ContactBanner />
            <FurtherAwards />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainAreaWrapper;
