// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  display: flex;
  flex-direction: column;
  ${({ withImage }) => withImage && "justify-content: flex-start;"}
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 220px;
  }
`;

export const ContentContainer: any = styled.div`
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    max-height: 240px;
    text-decoration: none;
    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 0 20px 30px 20px;
      width: 50%;
    }
`;

export const Content: any = styled.div`
    color: black;
    line-height: 140%;
    overflow: hidden;
    font-size: 14px;

    > h3 {
      font-size: 18px;
      text-decoration: none;
      font-weight: 400;
      color: #555;

      :hover {
        color: #adadad;
      }
    }
    > div {
      font-size: 13px;
      text-decoration: none;
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > a {
      margin-top: 10px;
      display: inline-block;
      padding: 6px 30px;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: .3px;
      color: #adadad;
      line-height: 1;

      border-radius: 18px;
      border: 2px solid #adadad;
      cursor: pointer;
      text-shadow: none;

      &:hover {
        background-color: #adadad;
        color: white;
      }
    }
`;

export const ImageContainer: any = styled.a`
    object-fit: cover;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      padding: 0 15px 10px 15px;
      width: 50%;
    }
`;

export const counter: any = css`
    margin-bottom: 15px;
    font-size: 13px;
`;
