import Link from "next/link";
import React, { FC, useContext } from "react";
import { Context } from "src/common";
import { getLinkFromConnection } from "../../../../../../common/utils/getLinkFromConnection";

import { HeadContent } from "./Hero.style";

const HeroLinks: FC = () => {
    const props = useContext(Context);

    const contactPage = props.PageContact;
    const links = props?.Settings?.t11_connectionsOnLanding;
    return (
        <HeadContent className="links-data-wrapper">
            <div className="links-data">
                {[links[0], links[1], links[2]]?.map((item, i) => {
                    if (!item) {
                        return <div className="data" key={`${i}data`}></div>;
                    }

                    const { valid, href, title, target, shallow } = getLinkFromConnection(item.linkTo);

                    if (!valid) {
                        return (
                            <div className="data" key={`${i}data`}>
                                {item.headline && <h4>{item.headline}</h4>}
                                {item.subline ? (
                                    <>
                                        {item.subline.split("\n").map((item, i) => {
                                            return (
                                                <>
                                                    {item}
                                                    <br></br>
                                                </>
                                            );
                                        })}
                                    </>
                                ) : (
                                    ""
                                )}
                                {title ? <span>{`${title || ""}`}</span> : null}
                            </div>
                        );
                    }

                    return (
                        <div className="data" key={`${i}data`}>
                            {item.headline && <h4>{item.headline}</h4>}
                            <Link href={href} shallow={shallow} passHref>
                                <a target={target} title={title}>
                                    {item.subline ? (
                                        <>
                                            {item.subline.split("\n").map((item) => {
                                                return (
                                                    <>
                                                        {item}
                                                        <br></br>
                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {title ? <span>{`${title || ""}`}</span> : null}
                                </a>
                            </Link>
                        </div>
                    );
                })}
                {contactPage && (
                    <div className="data" key={`${contactPage?.slug}data`}>
                        <h4>{contactPage?.nameInNavigation}</h4>
                        <Link href={`/${contactPage?.slug}`} shallow passHref>
                            <a>
                                {`Telefon: ${props?.CompanyInformationPublic?.phone}`}
                                <br />
                                {props?.CompanyInformationPublic?.email}
                                <br />
                                <span>{`${contactPage?.nameInNavigation}`}</span>
                            </a>
                        </Link>
                    </div>
                )}
            </div>
        </HeadContent>
    );
};

export default HeroLinks;
