import TextStyles from "src/templates/t27/constants/richtextTypography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

const Default = css`
  ${TextStyles};
  color: var(--back-1) !important;
  padding: 0 15px 12px 15px;
  a {
    color: var(--back-2);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  padding: 0 24px 15px 24px;
`;

const Diamond = css``;

export const SLandingEventTextWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
