import React, { FunctionComponent, useContext, useEffect, useState } from "react";

import { SWrapperToFixNoPointerOnLoad } from "./styles/SWrapperToFixNoPointerOnLoad";
import { SLandingBannerLeft } from "./styles/SLandingBannerLeft";
import { SLandingBannerLeftRichTextWrapper } from "./styles/SLandingBannerLeftRichTextWrapper";
import { SLandingBannerLeftIconWrapper } from "./styles/SLandingBannerLeftIconWrapper";
import CloseIcon from "src/templates/t27/icons/CloseIcon";
import { Context, RichText } from "src/common";
import { getLinkFromConnection } from "src/common/utils/getLinkFromConnection";
import { IGenBanner } from "src/common/types_dealer_gen";
import { SLandingBannerLeftImageWrapper } from "./styles/SLandingBannerLeftImageWrapper";
import Link from "next/link";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";

const T27LandingBannerLeft: FunctionComponent = () => {
    const [hasBeenClosed, setHasBeenClosed] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [autoClosed, setAutoClosed] = useState(false);

    const props = useContext(Context);
    const bannerEvent = props.AllBanner?.sort((a, b) => a._meta.publishedAt - b._meta.publishedAt)?.[0] as IGenBanner;

    if (!bannerEvent) {
        return null;
    }

    const { href, valid } = bannerEvent?.linksToPage ? getLinkFromConnection(bannerEvent?.linksToPage) : ({} as any);
    const hasLink = !!href && valid;
    const hasImage = !!bannerEvent?.asset?.src;
    // const hasLink = false;
    // const hasImage = false;

    // useEffect(() => {
    //     if(!isLoaded){
    //         return;
    //     }

    //     let t = setTimeout(() => {
    //         setAutoClosed(true);
    //     }, 7000);
    //     return () => clearTimeout(t);
    // }, [isLoaded]);

    useEffect(() => {
        if (!hasImage) {
            setIsLoaded(true);
        }
    }, [hasImage]);

    const Content = () => (
        <>
            <SLandingBannerLeftRichTextWrapper hasImage={hasImage}>
                <RichText textContent={bannerEvent?.text} fragment />
            </SLandingBannerLeftRichTextWrapper>
            {hasImage && (
                <SLandingBannerLeftImageWrapper>
                    <FancyImage
                        img={bannerEvent?.asset}
                        lazyload={true}
                        onLoad={() => setIsLoaded(true)}
                        responsiveAspectRatio={{ bronze: 320 / 320 }}
                        responsiveFactor={{ bronze: 0.89, silver: 0.52, platinum: 0.45 }}
                        responsiveMaxWidth={{ platinum: 620 }}
                    />
                </SLandingBannerLeftImageWrapper>
            )}
        </>
    );

    const Inner = () => (
        <SLandingBannerLeft isClosed={hasBeenClosed || autoClosed} id="lbl" isLoaded={isLoaded} hasImage={hasImage}>
            {hasLink ? (
                <Link href={href} passHref>
                    <a>
                        <Content />
                    </a>
                </Link>
            ) : (
                <Content />
            )}

            <SLandingBannerLeftIconWrapper onClick={() => setHasBeenClosed(true)} hasImage={hasImage}>
                <CloseIcon />
            </SLandingBannerLeftIconWrapper>
        </SLandingBannerLeft>
    );

    return (
        <SWrapperToFixNoPointerOnLoad loaded={true}>
            <Inner />
        </SWrapperToFixNoPointerOnLoad>
    );
};

export { T27LandingBannerLeft };
