import { Context } from "src/common";
import { ProductLandingBanner2024 } from "src/common/components/ProductLandingBanner";

import React, { FunctionComponent, useContext } from "react";

import { FooterT27 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import LandingImage from "../../common/landingImage/LandingImage";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import * as styles from "./Landing.page.style";
import { ProductLandingBannerWrapper } from "./Landing.page.style";
import { SiteContent } from "./siteContent/SiteContent";
import { T27LandingBannerLeft } from "./LandingBannerLeft/LandingBannerLeft";

const PageLandingT27: FunctionComponent = () => {
    const props = useContext(Context);
    const palette = props?.Settings;
    return (
        <div css={styles.landingWrapper}>
            <div>
                <Header />
            </div>
            <ProductLandingBannerWrapper
                background={palette.themeBackgroundColourIsland}
                titleColor={palette.themeTextColourIsland}
                color={palette.themeTextColor}
                primary={palette.themeColorPrimary}
            >
                <ProductLandingBanner2024 />
            </ProductLandingBannerWrapper>
            <div>
                <PlanningDateModal />
            </div>
            <div>
                <T27LandingBannerLeft />
            </div>
            <div>
                <LandingImage />
            </div>
            <div>
                <SiteContent />
            </div>
            <FooterT27 />
        </div>
    );
};

PageLandingT27.displayName = "PageLandingT27";
export { PageLandingT27 };
