import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const FurtherAwardsContet: any = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  margin: auto;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
  }
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  padding: 10px 30px 0 30px !important;


  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 4);
    padding: 10px 15px 0 15px;
  }
`;

