import styled,{ css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../../constants/colors";

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 450px;
  width: 100%;
  //  background-color: rgba(255,255,255,0.4);
  h3 {
    font-size: inherit;
    margin-bottom: 44px;
    font-weight: bold;
    text-decoration: inherit;
    color: ${colors.green};
  }
  border-radius: 16px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 450px;
    width: 92%;
  }
`;

export const ContentWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    aling-items: center;
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    max-height: 196px;

    > a {
      text-align: center;
      color: #606060;
      font-size: 13px;
    }
`;

export const counter: any = css`
    margin-bottom: 15px;
    font-size: 13px;
`;

export const content: any = css`
    color: black;
    line-height: 140%;
    overflow: hidden;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    h3 {
      font-size: inherit;
      margin-bottom: 15px;
      font-weight: bold;
      text-decoration: inherit;
      color: ${colors.green};
    }
    p {
      font-size: inherit;
      color: inherit;
      text-decoration: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }
`;

export const ImgWrapper: any = styled.div`
  width: 100%;
  height: 237px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    img {
      height: 237px;
    }
  }
`;

export const Button: any = styled.a`
  border: 2px solid ${colors.green};
  padding: 6px 24px;
  width: fit-content;
  margin 24px auto 30px;
  background-color: ${colors.white};
  border-radius: 5px;
  p {
    font-size: 12px;
    color: ${colors.green};
    font-weight: bold;
    letter-spacing: .8px;
  }
  &:hover {
    background-color: ${colors.green};
    opacity: 1;
  p {
    color: ${colors.white};
  }
  }
`;
