import React, { FC, useContext } from "react";
import {
  Context,
  LogoBannerHorizontal,
  ITextElement,
  IAsset,
  ISlider
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import Img from "../../../BaseComponents/Img";
import { Container } from "../../common/Grid";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import * as styles from "./LandingContent.style";
import { HeroContainer } from "./LandingContent.style";
const Hero: FC = () => 
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  
  let imageOrGallery = null;
  if(slider && !!slider?.[0]?.elements) 
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0]) 
  {
    if(imgArray.length > 1) 
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray?.length > 1) 
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else 
    {
      imageOrGallery = (
        <HeroContainer>
          <Img
            alt={imgArray?.[0]?.title}
            src={imgArray}
            height={342}
            quality={1080} />
        </HeroContainer>
      );
    }
  }
  return imageOrGallery;
};

const LandingContent: FC = () => 
{
  const props = useContext(Context);
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  return (
    <Container>
      <div css={styles.wrapper}>
        <Hero />
        <div css={styles.contentWrapper}>
          <div css={styles.leftContent}>
            {(props.PageLanding?.elements?.filter(e => e?.__typename == "TextElement") as ITextElement[])?.map(
              (t, i) => (
                <RichTextGeneral
                  key={`rt0-${i}`}
                  textContent={t.text}
                >
                  <h1>{t?.headline}</h1>
                </RichTextGeneral>
              )
            )}

            {props?.CompanyInformationPrivate?.showInKitchenCompass ? (
              <a href={"https://www.kuechenkompass.com/"} className="kitchen-compass">
                <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
              </a>
            ) : null}
          </div>
          <div css={styles.sideBar}>
            <TeaserRight />
            <NewSlider />
            {isNewsCustom && <NewSlider newsCustom={isNewsCustom}/>}
          </div>

        </div>
        <div css={styles.bannerHorizontal}>
          <LogoBannerHorizontal shadowOverlayVisible={true} />
        </div>
      </div>
    </Container>
  );
};

export default LandingContent;
