import styled from "styled-components";

export const FurtherAwardsWrapper: any = styled.div`
  display: flex;
  width: 100%;
`; 
export const ImageWrapper: any = styled.div`
  width: 50%;
  padding-left: 21px;
`;
