import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

interface IProps {
    areThereMoreThanOneElement: boolean;
}

const Default = css`
    display: flex;
    flex-direction: column;
    padding: 48px 0;

    > div {
        width: 100%;
    }
`;

const Silver = css`
    flex-direction: row;
    gap: 26px;

    ${({ areThereMoreThanOneElement }: IProps) =>
        areThereMoreThanOneElement &&
        css`
            > div {
                width: 50%;
            }
        `}
`;

const Gold = css``;

const Platinum = css`
    padding: 76px 0;
    gap: 40px;
`;

const Diamond = css``;

export const SSiteContentColumnsWrapper = styled.div<IProps>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
