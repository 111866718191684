import { css } from "styled-components";

export const imgWrapper: any = css`
  height: 35px;

  & > div {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #92c2d6;
      mix-blend-mode: hard-light;
    }
    img {
      height: 100%;
      width: auto !important;
    }
  }
`;
