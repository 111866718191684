import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";
import colors from "../../../constants/colors";

export const wrapper: any = css`
    width: 100%;
`;

export const twoColumnsWrapper: any = css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
        display: none;
    }
    > div:first-child {
        display: block;
        width: calc(100% - 310px);
    }
    > div:nth-child(2) {
        display: block;
        width: 270px;
        margin-left: 40px;
    }
`;

export const pageContent: any = css`
    padding: 0 !important;
    width: 100%;
    
    @media (${BREAKPOINTS_DM.platinum_1366}) {
        width: 100% !important;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
        margin: 0 auto;
    }
`;

export const sliderWrapper: any = css`
    width: 100%;
    padding: 0 !important;
    &:before {
        border-bottom-color: ${props => props.theme.secondary};
    }
    > div {
        padding: 27px;
        border: solid 1px ${props => props.theme.secondary};
    }
`;

export const connectedArticle: any = css`
    margin: 10px 0 50px;
    > div > div {
        float: left;
    }
    h2 {
        color: ${props => props.theme.primary};
        font-size: 30px;
        text-transform: uppercase;
    }
    h3 {
        color: ${props => props.theme.primary};
        font-size: 20px;
        margin-bottom: 13.5px;
    }
    iframe {
        max-width: 100%;
    }
`;

export const connectedArticleWithBorder: any = css`
    padding: 0 !important;
    &:before {
        border-bottom-color: ${props => props.theme.secondary};
    }
    > div {
        padding: 27px;
        width: 100%;
        border: solid 1px ${props => props.theme.secondary};
    }
`;

export const connectedArticleImg: any = css`
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 20px 20px 0;
`;

export const connectedArticleImgThreeInARow: any = css`
    width: calc(33.3% - 20px);
    height: auto;
    display: inline-block;
    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
        margin-right: 0;
    }
`;

export const connectedArticleRichtext: any = css`
    /* margin: 10px 0 20px; */
    em {
        font-style: italic;
    }
    float: left;
    > div:first-child {
        float: left;
        margin: 0 28px 28px 0;
    }
`;

export const footnote: any = css`
    margin-bottom: 13px;
    a,
    a * {
        color: ${props => props.theme.primary};
    }
`;

export const LogoBannerWraper: any = styled.div`
    width: 100%;
    height: 158px;
    background-color: #F5F5F5;
    margin: -2px;
    padding: 40px 0px;
   
`;

export const Separator: any = styled.hr`
  color: ${colors.ligth_gray};
  margin: 20px auto;
  width: 80%;
`;
export const NewSliderWrapper: any = styled.div`
  margin: 0 0 110px;
`;
