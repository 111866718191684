// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  position: relative;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
  }
`;

export const ImageContainer: any = styled.a`
  display: block;
  width: 100%;
  height: 390px;
  object-fit: cover;
  margin-bottom: 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    padding: 0 15px;
  }
`;

export const ContentContainer: any = styled.div`
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    width: fit-content;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    padding: 0 15px;
    align-items: flex-start;
  }
`;

export const Content: any = styled.div`
  line-height: 140%;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;

  max-height: 220px;
  margin-bottom: 30px;
  width: 100%;
  > h4,
  > h3,
  > h2 {
    font-size: 20px;
    text-decoration: none;
    text-align: left;
    font-weight: 300;
    color: #555;
    margin: 5px 0 10px 0;

    :after {
      display: none;
    }
  }
  > p,
  > ul,
  > ol,
  > iframe,
  > h1,
  > h2,
  > h3,
  > h4,
  > h3,
  > h2 {
    text-overflow: ellipsis;
  }
`;

export const counter: any = css`
  margin-bottom: 15px;
  font-size: 13px;
`;
