import React, { FunctionComponent, useContext } from "react";

import * as styles from "./LandingGrid.style";
import LandingGridItem from "./LandingGridItem/LandingGridItem";
import { Context, IRichLinkItem } from "src/common";

const LandingGrid: FunctionComponent = () =>
{
    const props = useContext(Context);
    const richElements = props?.PageLanding?.elements?.filter(e=>e?.__typename=="RichLinkItem") as IRichLinkItem[]
    const gridItems = richElements?.map((item, index) => {
        const link: any = item?.linkTo?.[0];
        return(
            <LandingGridItem key={index} index={index} icon={item?.image?.[0]?.src} target={!!link?.externalLinkAddress  ? "_blank" : undefined} link={link?.externalLinkAddress || link?.slug} label={item?.headline}/>
        )
    });

    return (
        <div css={styles.wrapper}>
            {gridItems}
        </div>
    );
};

export default LandingGrid;
