import styled from "styled-components";

export const BrandBannerVerticalWrapper = styled.div<any>`
  width: 100%;
`;

export const BrandSlide = styled.div`
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  img {
    object-fit: contain;
    height: 38px;
  }
`;
