import styled, { keyframes } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../../common/Grid";

export const BaseContainer = styled(Container)`
  font-family: 'Roboto Slab',sans-serif;
  
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 60px 260px 0 260px;
  }
`;

export const HeroContainer = styled(BaseContainer)`
  div {
    padding: 0 10px;
  }
  p {
    span {
      font-family: 'Roboto Slab',sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    div {
      padding: 60px 25px;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    div {
      padding: 0;
    }
  }
`;

export const PromotionalOfferContainer = styled(BaseContainer)`
  flex-direction: column;
  padding: 40px 15px;
  margin-bottom: 40px;

  > div {
    flex: 1;
    > h1 {
      font-size: 24px;
      text-align: center;
      font-weight: 400;
      color: #222;;
    }
  }
`;

export const RichLinkContainer = styled(BaseContainer)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 60px 15px;
  margin-bottom: 25px;
  font-size: 13px;

  hr {
    width: 100%;
    height: 1px;
    border: none;
    margin: 3rem 0;
    background-color: #bcbcbc;
  }
  > div {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
  }

  > h3 {
    font-size: 40px;
    line-height: 1;
    font-family: "Nunito Sans";
    font-weight: 400;
  }
`;

export const NewsContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.textColor};  
  padding: 25px 0 3rem;
  div:nth-child(2) {
    padding: 0;
  }
`;

export const ContactDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.white};

  ${Container} {
    flex-direction: column;
  }

  > h2 {
    align-self: center;
    color: #fff;
    border-bottom: 1px solid #fff;
    font-weight: 300;
    padding-bottom: 10px;
    margin-bottom: 3rem;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    ${Container} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;

export const ContactDataCard = styled.div<{ icon: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: calc(100% - 20px);
  i {
    width: 70px;
    height: 60px;
    margin-bottom: 2rem;
    background-image: url("/images/${({ icon }) => icon}.png");
    background-repeat: no-repeat;
    background-position: 0 center;
  }

  h4 {
    width: 70%;
    font-family: 'Roboto Slab';
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
    padding-top: 0.8rem;
  }

  h3 {
    font-size: 35px;
    font-weight: 400;
    padding-bottom: 22px;
    display: inline-block;
    line-height: 34px;
  }
  hr {
    width: 100%;
    height: 1px;
    margin: 2rem 0;
    border: none;
    background-color: ${({ theme }) => theme.palette.white};
  }

  button, a {
    background-color: transparent;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.white};
    padding: .5rem;
    border: 1px solid ${({ theme }) => theme.palette.white};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 2rem;

    :hover {
      background-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.textColor};
      border-color: ${({ theme }) => theme.palette.textColor};
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    margin: 0;
    padding-right: 60px;
    padding-left: 20px;
    width: calc(50% - 60px);
    h4 {
      padding-top: 1.5rem;
    }
    h3 {
      font-size: 40px;
    }
  }
`;

const jump = keyframes`
  0% {
    transform:translate(0,0) rotate(-90deg);
  }

  25% {
    transform:translate(0,-3px) rotate(-90deg);
  }

  50% {
    transform:translate(0,0) rotate(-90deg);
  }

  75% {
    transform:translate(0,3px) rotate(-90deg);
  }

  100% {
    transform:translate(0,0) rotate(-90deg);
  }
`;

export const ScrollIndicator: any = styled.div`
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 60px;
  height: 60px;
  border: 2px solid white;
  font-size: 54px;
  color: white;
  border-radius: 50%;

  :before {
    content: "❬";
    text-rendering: geometricPrecision;
    display: inline-block;
    animation: ${jump} 3s infinite linear;
  }
`;

export const LandingBannerLeftWrapper = styled.div`
  div > div > div > p, h1, h2, h3 > span {
    em {
      font-style: normal;
    }
  }
`;
