import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const HeroContainer: any = styled.div`
  .slick-slider {
    @media (${BREAKPOINTS_DM.silver_768}) {
      height: 580px;
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
      height: 812px;
    }

    img {
      @media (${BREAKPOINTS_DM.silver_768}) {
        height: 580px;
      }

      @media (${BREAKPOINTS_DM.gold_1024}) {
        height: 812px;
      }
    }

    .slick-dots {
      bottom: 60px;

      .slick-active {
        button {
          background-color: ${({ theme }) => theme.palette.primary};
          border-color: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }
`;