import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ContentWrapper: any = styled.div`
  margin-bottom: 21px;
  .kitchen-compass {
    width: 50%;
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: grid;
    grid-template-columns: repeat(2, minmax(49%, 1fr));
    gap: 0 21px;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    grid-template-columns: repeat(3, minmax(32%, 1fr));
  }
`;

export const HeadLine: any = styled.div`
  width: 100%;
  margin-bottom: 21px;

  h1 {
    margin: 10px 0;
    font-weight: 300;
    text-align: center;
    line-height: 24px;
    letter-spacing: 3.9px;
    font-size: 20px;
    padding: 0;
  }
  div > p > span {
    font-size: 15px;
  }
`;

export const SideBar: any = styled.div`
  width: 100%;
  max-width: initial;
  margin-bottom: 21px;

  > div:first-child {
    margin-bottom: 21px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    grid-column: 1 / 3;
    grid-row: 2;
    div {
      flex-basis: 48%;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    grid-row: 1;
    grid-column: 3 / 3;
    flex-direction: column;
  }
`;

export const BannerHorizontal: any = styled.div`
  margin-bottom: 20px;
`;

export const HeroContainer = styled.div`
  margin-bottom: 21px;
`;

export const ProductLandingBannerWrapper: any = styled.div`
  color: rgb(29, 29, 27);
`;

export const ModelKitchen: any = styled.div`
  padding: 20px;
  border: 1px solid #7d7261;
  a {
    .home_module_small_bg_image {
      height: 229px;
    }
    .home_module_image_bg {
      position: relative;
      width: 100%;
      height: 100%;
      background: url("/images/kg_home_content_image_03.jpg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;

      h3 {
        text-align: center;
        padding-top: 10px;
        font-size: 23px;
        letter-spacing: -1px;

        &:hover {
          text-decoration: underline;
        }
      }

      .background_text {
        position: absolute;
        top: 57%;
        left: 55%;
        width: 200px;

        h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          background-color: white;
          padding: 0 5px;
          max-width: calc(41vw);
        }
        @media (${BREAKPOINTS_DM.silver_768}) {
          top: 47%;
          left: 75%;
          h4 {
            max-width: none;
            display: inline;
          }
        }
      }
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    a {
      .home_module_small_bg_image .background_text {
        left: 23%;
      }
    }
  }
`;
