import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://60026-vorschau.dk-das-kuechenhaus.de/
const PageLandingT15: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Banner />
    <Footer />
     
    <LandingBannerLeft />
  </>
);

PageLandingT15.displayName = "PageLandingT15";

export { PageLandingT15 };
