import React, { FunctionComponent, useContext, Fragment } from "react";
import { Context, ITextElement } from "src/common";
import UniversalComponentsRenderer from "../../../../../../common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";
import {
  hexIsLight,
  rgba2hex,
} from "../../../../../../common/utils/colorHelper";
import { richToPlainText } from "../../../../../../common/utils/text/plaintext";

import RichText from "../../../../BaseComponents/RichText/RichText";
import { centeredContent } from "../../../../constants/layout";
import FurtherAwards from "../../../common/FurtherAwards/FurtherAwards";
import { UniversalComponentsRendererWrapper } from "../../../common/MainContent/Slug.style";
import {
  eLandingEventLocation,
  LandingEvent,
} from "../landingEvent/LandingEvent";
import { NewSlider } from "../NewSlider/NewSlider";
import * as styles from "./SiteContent.style";
import { TextWrapper } from "./SiteContent.style";
import { css } from "styled-components";

export const SiteContent: FunctionComponent = () => {
  const p = useContext(Context);
  const palette = p?.Settings;
  const showInKitchenCompass =
    !!p.CompanyInformationPrivate?.showInKitchenCompass;

  let isFirstTextElement = false;
  let firstTextElement: any = undefined;
  // @ts-ignore
  const universlElements =
    p.PageLanding?.elements?.filter((e) => {
      if (e?.__typename === "TextElement") {
        if (!isFirstTextElement) {
          isFirstTextElement = true;
          firstTextElement = e;
          return false;
        }
        return true;
      }

      return (e?.__typename as any) == "HtmlEmbedding";
    }) ?? [];

  const landingEvents = p?.PageLanding?.events;
  const isNewsCustom = p?.PageLanding?.NewsCustom?.length > 0;

  let style: any = {};
  if (palette.themeBackgroundColor) {
    try {
      let hex = palette.themeBackgroundColor;
      if (hex.indexOf("rgb") > -1) {
        hex = rgba2hex(palette.themeBackgroundColor);
      }

      if (!hexIsLight(hex)) {
        style = {
          filter: "brightness(3)",
        };
      }
    } catch (err) {
      console.log(` themeBackgroundColor err`, err);
    }
  }
  return (
    <div
      css={styles.wrapper}
      // @ts-ignore
      backgroundColor={palette.themeBackgroundColor}
      id={"top"}
    >
      <div css={centeredContent}>
        <div css={styles.columnsWrapper}>
          <TextWrapper color={palette.themeTextColor}>
            <div className="mobileHeading">
              {firstTextElement?.headline && (
                <h1>{firstTextElement?.headline}</h1>
              )}
              {!!firstTextElement?.text &&
              richToPlainText(firstTextElement?.text)?.trim() != "" ? (
                <RichText textContent={firstTextElement?.text}></RichText>
              ) : null}
            </div>
            {universlElements.map((element: any, i: number) => {
              switch (element.__typename) {
                case "TextElement":
                  return (
                    <Fragment key={`${element.id}${i}`}>
                      <h1 css={styles.heading}>{element?.headline}</h1>
                      <RichText
                        key={i}
                        textContent={element.text}
                        overwriteStyle={styles.mainRichtext}
                      ></RichText>
                    </Fragment>
                  );
                case "HtmlEmbedding":
                  return (
                    <UniversalComponentsRendererWrapper
                      key={`${element.id}${i}`}
                      colorText={palette.themeTextColor}
                      colorPrice={palette.themeContrastColour}
                    >
                      <UniversalComponentsRenderer
                        items={[element]}
                      ></UniversalComponentsRenderer>
                    </UniversalComponentsRendererWrapper>
                  );
                default:
                  return null;
              }
            }) ?? null}
            {showInKitchenCompass ? (
              <a
                href="https://www.kuechenkompass.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer" }}
              >
                <img
                  style={{ marginTop: 20, ...style }}
                  src="/images/kk.png"
                  alt="empfohlen von Küchenkompass"
                />
              </a>
            ) : null}
          </TextWrapper>

          <div>
            {landingEvents &&
              landingEvents.map((event, i) => {
                // if (i === 0) {
                //   return null;
                // }
                return (
                  <LandingEvent
                    className={i === 0 ? "first" : ""}
                    key={i}
                    event={event}
                    type={eLandingEventLocation.sideBar}
                  />
                );
              })}
            <div
              className="newsSliderWrapperForOrder"
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <NewSlider />
              {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
            </div>
            <FurtherAwards></FurtherAwards>
          </div>
        </div>
        <div css={styles.toTopWrapper}>
          <a href="#top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};
