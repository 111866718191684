import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
    ${SSlickBase}
    ${SSlickTheme}
    margin-top: 20px;
    width: 100%;
    height: 480px;
    .slick-dots {
        width: 100%;
        margin: auto;
        bottom: -35px;
        top: 770px;
        li button:before {
          border: 2px solid gray;
          border-radius: 50%;
        }
      @media (${BREAKPOINTS_DM.silver_768}) {
        width: 91%;
        top: 740px;
        li button:before {
          border-radius: 50%;
          border: 2px solid gray;
        }
      }
    }
    .slick-dots li.slick-active button:before
    {
      background-color: #606060;
    }
    .slick-prev:before,
    .slick-next:before
    {
        font-family: 'slick';
        font-size: 28px;
        line-height: 1;
        opacity: 1;
        color: #606060;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      .slick-slider {
        padding: 65px;
      }
      width: 91%;
      margin: auto;
    }
`;
