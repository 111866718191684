import React, { FC, useContext } from "react";
import { Context, getPage, IRichLinkItem } from "src/common";

import Item from "./Item";

const RichLinks: FC = () => {
  const props = useContext(Context);
  const page = getPage(props);

  // @ts-ignore
  const links = page?.elements?.filter(
    (e) => e?.__typename === "RichLinkItem"
  ) as IRichLinkItem[];
  return (
    <>
      {links.map((item, i) => (
        <Item
          key={item.linkTo?.[0]?.slug}
          index={i}
          totalCount={links.length}
          img={item.image}
          title={item.headline}
          text={item.richDesc}
          link={item.linkTo?.[0]?.slug}
        />
      ))}
    </>
  );
};

export default RichLinks;
