import React, { FunctionComponent } from "react";

import RichText from "../../../../BaseComponents/RichText/RichText";
import * as styles from "./NewSlide.style";

interface IProps
{
  index: number;
  totalCount: number;
  img?: string;
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

export const NewSlide: FunctionComponent<IProps> = props => 
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <div css={styles.wrapper}>
      {props.img && <img css={styles.img} src={props.img} alt={props.imgAlt ? props.imgAlt : ""}/>}
      <div css={styles.contentWrapper}>
        <p css={styles.counter}>Nachricht {props.index + 1} / {props.totalCount}</p>
        <a href={props.link ? props.link : null} css={styles.content}>
          {props.title && <h3>{props.title}</h3>}
          {text && <RichText textContent={text}/>}
        </a>
      </div>
    </div>
  );
};

