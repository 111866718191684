import { IGenNews } from "src/common/__generated/root/types";
import { IGenAsset } from "src/common/types_dealer_gen";
import { richToTeaser } from "src/common/utils/text/teaser";
import LinkArrow from "src/templates/t27/icons/LinkArrow";

import React, { FunctionComponent } from "react";

import { SSliderItem } from "./styles/SSliderItem";
import { SSliderItemIconWrapper } from "./styles/SSliderItemIconWrapper";
import { SSliderItemImageWrapper } from "./styles/SSliderItemImageWrapper";
import { SSliderItemTextWrapper } from "./styles/SSliderItemTextWrapper";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";

const SliderItem: FunctionComponent<IGenNews> = ({ headline, teaser, extras, text }) => {
    const image = extras?.find((e) => e?.__typename === "Asset") as IGenAsset | undefined;


    return (
        <SSliderItem>
            {image && image.src && (
                <SSliderItemImageWrapper>
                    <FancyImage
                        img={image}
                        responsiveFactor={{ bronze: 1, silver: 0.5 }}
                        responsiveMaxWidth={{ platinum: 1366 / 2 }}
                        responsiveAspectRatio={{
                            bronze: 312 / 234,
                            silver: 348 / 262,
                            platinum: 588 / 442,
                        }}
                    />
                </SSliderItemImageWrapper>
            )}
            <SSliderItemTextWrapper className="textWrapper">
                {headline && <h3 className="headline">{headline}</h3>}
                {(teaser || text) && <p className="teaser">{teaser || richToTeaser(text, 130)}</p>}
            </SSliderItemTextWrapper>
            <SSliderItemIconWrapper className="icon">
                <LinkArrow />
            </SSliderItemIconWrapper>
        </SSliderItem>
    );
};

export default SliderItem;
