import React, { FC, useContext, useEffect, useState } from "react";
import {
  Context,
  ITextElement,
  IAsset
} from "src/common";

import FurtherAwards from "../../common/FurtherAwards";
import { Section, Container } from "../../common/Grid";
import NewSlider from "../../common/NewSlider";
import PageHeading from "../../common/PageHeading";
import PromotionalOffer from "../../common/PromotionalOffer";
import { RichTextGeneral } from "../../common/RichText";
import {
  HeroContainer,
  RichLinkContainer,
  NewsContainer,
  ContactDataContainer,
  ContactDataCard,
  ScrollIndicator,
} from "./LandingContent.style";

const ScrollDown: FC = () => 
{
  const [display, toggleDisplay] = useState(true);

  useEffect(() => 
  {
    const scrolled = (): void => toggleDisplay(false);
    window.addEventListener("scroll", scrolled);

    return () => window.removeEventListener("scroll", scrolled);
  }, []);

  const srollDown = (): void => window.scrollTo({
    top: window.innerHeight,
    left: 0,
    behavior: "smooth"
  });

  return (
    <>
      {display && (<ScrollIndicator onClick={srollDown}/>)}
    </>
  );
};

const LandingContent: FC = () => 
{
  const [isShowModal, setShowModal] = useState(false);
  const props = useContext(Context);
  const { PageLanding: page } = props;

  const heroImage = page?.elements?.find(e => e?.__typename === "Asset") as IAsset;
  const [heroText] = page?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[];
  const [promotionalOffer] = props?.PageLanding?.promotionalOffer;
  const { CompanyInformationPublic } = props;
  const isNewsCustom = page?.NewsCustom?.length > 0;

  return (
    <Section>
      <PageHeading imageUrl={heroImage?.src} headLine={heroText?.headline} />
      <ScrollDown />

      <HeroContainer>
        <RichTextGeneral textContent={heroText.text} />
      </HeroContainer>

      <PromotionalOffer promotionalOffer={promotionalOffer} />
      
      <RichLinkContainer>
        <hr />
        <div>Haben Sie Fragen? Rufen Sie uns an.</div>
        <h3>{CompanyInformationPublic?.phone}</h3>
        <hr />
      </RichLinkContainer>

      <NewsContainer>
        <NewSlider limit={4} />
        {isNewsCustom && <NewSlider limit={4} newsCustom={isNewsCustom} />}

        <FurtherAwards />
      </NewsContainer>

      <ContactDataContainer>
        <h2>WIR SIND FÜR SIE DA</h2>
        <Container>
          <ContactDataCard icon="icon-weg-zur-kueche">
            <i />
            <h4>Der Weg zur neuen Küche.</h4>
            <a href="/kontakt">So geht's</a>
            <hr />
          </ContactDataCard>
          <ContactDataCard icon="icon-phone">
            <i />
            <h4>Rufen Sie uns an.</h4>
            <h3>{CompanyInformationPublic?.phone}</h3>
            <hr />
          </ContactDataCard>
          <ContactDataCard icon="icon-location">
            <i />
            <h4>So finden Sie uns.</h4>
            <a href="/kontakt">Weg berechnen</a>
            <hr />
          </ContactDataCard>
        </Container>
      </ContactDataContainer>
    </Section>
  );
};

export default LandingContent;
