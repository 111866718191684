import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND, BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    margin-right: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-items: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 60;
    pointer-events: none;
    display: none;

    @media (${BREAKPOINTS_DM.gold_1024}) {
        display: ${(props: any) => (props.closed ? "none" : "flex")};
    }
    .plb-c0 {
        cursor: pointer;
        pointer-events: all;
        width: 80%;
        margin: 0 auto;
        position: relative;
        font-family: Helvetica, Arial, sans-serif;
        p {
            font-size: 0.9em;
            padding: 10px;
        }
    }
    * {
        font: 0.9375em/1.4 Exo, Helvetica, Arial, sans-serif;
    }
    a {
        color: ${(props) => props.theme.palette.productPrime};
    }
    h3 {
        letter-spacing: 0.68px;
        font-size: 34px;
        line-height: 38px;
        color: #f6931e;
        font-weight: 700;
        font-family: Montserrat, Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        margin: 7px;
    }
    .plb-c10 {
        background: #fff;
        padding-top: 0;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .plb-image {
        padding: 0px 0px 30px 0;
        min-height: 150px;
        img {
            max-height: 50vh;
        }
    }

    h1.plb-title {
        text-align: left;
        position: relative;
        background: #f6931e;
        color: #fff;
        font-size: 24px;
        line-height: 1.1;
        font-weight: 300;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        margin: 0;
        span {
            font-size: 33px;
            line-height: 36.3px;
            font-weight: 500;
        }
    }
    .plb-slider {
        ul {
            height: 70vw;
            position: relative;
        }
        li > div {
            height: 100%;
        }
    }
    .plb-arrow {
        top: 0;
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 45px;
        display: flex;
        justify-content: center;
        justify-items: center;
        :hover {
            background: rgba(0, 0, 0, 0.3);
        }
        svg {
            height: auto;
            width: 45px;
        }
    }
    .plb-arrow-left {
        left: -45px;
        transform: rotate(180deg);
    }
    .plb-arrow-right {
        right: -45px;
    }
    .plb-price-container {
        text-align: center;
        color:  #f6931e;
        margin-top: 0;
    }
    .plb-price-badge {
        background:  #f6931e;
        color: #fff;
        position: absolute;
        z-index: 99;
        font-size: 1.4em;
        font-weight: 700;
        padding: 8px;
        transform: rotate(-10deg);
        margin-top: -90px;
        margin-left: 15px;
        box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 10px;
        ::before {
            content: " ";
            height: 10px;
            width: 10px;
            background: #f6931e;
            border-radius: 20px;
            position: absolute;
            margin-left: -11px;
            margin-top: 10px;
        }
        ::after {
            right: -3px;
            margin-top: -20px;
            content: " ";
            height: 10px;
            width: 10px;
            background: #f6931e;
            border-radius: 20px;
            position: absolute;
            margin-left: -11px;
        }
    }
    hr {
        outline: 0;
        border: 0;
        border-top: 1px solid #f6931e;
    }
    .plb-download {
        color: ${(props) => props.theme.palette.productPrime};
        padding: 10px;
        padding-top: 16px;
        :hover {
            color: black;
        }
        svg {
            height: 12px;
            width: auto;
            padding-right: 6px;
        }
    }
    /* SERIVCE GALLERY */
    .plb-service-gallery {
        padding: 10px;
    }
    .plb-service-icon {
        display: flex;
        align-items: center;
        img {
            margin-right: 12px;
            height: 32px;
            width: 32px;
        }
    }
    /* END SERIVCE GALLERY */

    .plb-close {
        margin: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid gray;
        border-radius: 5px;
        display: block;
        color: ${(props) => props.theme.palette.productPrime};
        text-align: center;
    }
    /* DESKTOP LINK LEFT */
    .plb-desktop-link-left {
        display: none;
    }
    /* END DESKTOP LINK LEFT */
`;

const Silver = css`
    background: none;
    transition: transform 0.6s ease-out;
    transform: ${(props: any) => (props?.slidedOut ? "translateX(0)" : "translateX(350px)")};
    h1.plb-title {
        text-align: left;
    }
    .plb-title-prefix {
        display: block;
    }
    .plb-c0 {
        width: 380px;
        padding-left: 30px;
        background: #f6931e;
        margin: 0 0 0 auto;
        position: relative;
        font-family: Helvetica, Arial, sans-serif;
        p {
            font-size: 0.9em;
            padding: 10px;
        }
    }
    /* IMAGE SLIDER  */
    .plb-slider-with-arrows {
        position: relative;
    }
    .plb-arrow {
        height: calc(100% - 35px);
        bottom: 35px;
        z-index: 10;
    }
    .plb-arrow-left {
        left: 0;
    }
    .plb-arrow-right {
        right: 0;
    }
    /* END IMAGE SLIDER */

    /* SERIVCE GALLERY */
    .plb-service-gallery {
        padding: 0 10px;
    }
    /* END SERIVCE GALLERY */

    /* DESKTOP LINK LEFT */
    .plb-desktop-link-left {
        color: #fff;
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        top: 50%;
        left: 14px;
        z-index: 10;
        position: absolute;
        font-size: 1.4rem;
        display: block;
    }
    .plb-c0 {
        ::after {
            content: " ";
            position: absolute;
            top: 0;
            left: -30px;
            width: 30px;
        }
    }
    /* END DESKTOP LINK LEFT */

    .plb-close {
        display: none;
    }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SProductLandingBanner = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SProductLandingBanner;
