import React, { FC } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { PlanningDateModalContextProvider } from "../../common/PlanningDateModal";
 ;
import LandingContent from "../../content/LandingContent";
// https://60631-vorschau.kuechengilde.de/
const PageLandingT18: FC = () => (
  <PlanningDateModalContextProvider>
    <Header />
    <LandingContent />
    <Footer brands />
     
  </PlanningDateModalContextProvider>
);

PageLandingT18.displayName = "PageLandingT18";

export { PageLandingT18 };
