import { StringValueNode } from "graphql";
import { IAsset } from "src/common";
import { richToTeaser } from "../../../../../../common/utils/text/teaser";

import Img from "../../../../BaseComponents/Img";
import {
  Wrapper,
  ContentWrapper,
  ImgWrapper,
  RichTextWrapper,
} from "./NewSlide.style";
interface IProps {
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  headline?: StringValueNode;
}

export const NewSlide: any = (props) => {
  const teaser = richToTeaser(props?.text);
  return (
    <Wrapper>
      {props.img && (
        <ImgWrapper>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </ImgWrapper>
      )}
      <ContentWrapper>
        <h1>{props?.title}</h1>
        {teaser != "" && (
          <RichTextWrapper>
            <p>{teaser}</p>
          </RichTextWrapper>
        )}

        <a href={props?.link}>LESEN</a>
      </ContentWrapper>
    </Wrapper>
  );
};
