import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const PageHeadingWrapper: any = styled.div<{ src: string }>`
  position: relative;
  width: 100vw;

    height: 100vh;
  :after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top:0;
    left:0;
    background-color: ${({ theme }) => theme.palette.primary};
    background-image: url("${({ src }) => src}");
    background-size: cover;
    background-repeat: norepeat;
    background-attachment: fixed;
  }
`;

export const HeaderContainer: any = styled.div<{ withBackground: boolean }>`
  position: absolute;
  bottom: 145px;
  left: 0;
  width: 100vw;

  h1 {
    vertical-align: middle;
    margin: 0 0 0 20px;
    background-color: ${({ theme }) => theme.palette.primary};
    color: #fff;
    font-size: 22px;
    font-weight: 300;

    line-height: 46px;
    padding: 6.25px;

    :before {
      display: inline-block;
      content: "»";
      color: ${({ theme }) => theme.palette.textColorSecondary};
      line-height: 46px;
      width: 54px;
      text-align: center;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    h1 {
      font-size: 40px;
      margin: 0 0 0 55px;
    }
  }
`;
