// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 15px;
  }
`;

export const ContentWrapper: any = styled.div`
    overflow: hidden;
    width: 100%;

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: ${({ theme }) => theme.palette.primary};
      margin-top: 34px;
      margin-bottom: 34px;
    }

    p, h4, h2 {
      &,span {
        font-size: 13px;
        font-weight: 300;
      }
    }

    a[data-more] {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.palette.primary};
      font-weight: 700;

      img {
        padding-right: .5rem;
        transform: scale(0.6);
      }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
    }
`;

export const ImageLink: any = styled.a`
    position: relative;
    object-fit: cover;

    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
    }

    > div {
      &,
      > img {
      height: 214px;
      }
    }

    p {
      display: block;
      left: 0;
      top: 20px;
      position: absolute;
      background-color: ${({ theme }) => theme.palette.primary};
      padding: 5px 10px;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      white-space: nowrap;
    }
`;

