import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";
import TextStyles from "src/templates/t27/constants/richtextTypography";

interface IProps {
    hasImage: boolean;
}

const Default = css<IProps>`
    position: relative;
    z-index: 1;
    /* height: 100%; */

    ${TextStyles}

    > * {
        ${({ hasImage }) =>
            hasImage
                ? css`
                      color: var(--back-2);
                  `
                : css`
                      color: var(--front-2);
                  `}
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        :first-of-type {
            color: var(--primary-100);
            padding-right: 25px;
        }
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        :first-of-type {
            padding-right: 29px;
        }
    }
`;

const Diamond = css``;

export const SLandingBannerLeftRichTextWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
