import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../../constants/mediaquerys";

const Default = css`
padding: 12px 0;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
padding: 19px 0;
`;

const Diamond = css``;

export const SSliderItemImageWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
