import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const SLandingContainer: any = styled.div`
  padding: 0 25px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0 30px;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0 60px;
  }
`;
