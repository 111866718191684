import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import { FurtherAwardsWrapper, ImageWrapper, Wrapper } from "./FurtherAwards.style";
import { Img } from "../../../../../common";

const FurtherAwards: FunctionComponent = () => 
{
  const props = useContext(Context);
  const furtherAwards = props?.CompanyInformationPrivate?.furtherAwards;

  return (
    <Wrapper>
      <FurtherAwardsWrapper>
        {furtherAwards?.map((item, i) => (
          <ImageWrapper key={i}>
            <Img quality={1600} key={i} src={[{ src: item?.src }]  as any} alt={item?.title ?? "image"}/>
          </ImageWrapper>
        ))}
      </FurtherAwardsWrapper>
    </Wrapper>
  );
};

export default FurtherAwards;
