import React, { FC } from "react";
import SlickSlider from "react-slick";
import { IAsset } from "src/common";
import { Img } from "src/common/components/Img";
import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";

import RichTextGeneral from "../RichText";
import {
  ProductContainer,
  ItemDetails,
  ItemDescription,
  ItemPrice,
  ItemPicture,
  wrapper,
} from "./PromotionalOffertSlider.style";
export interface IsContent
{
  carouselContent: object;
}

export interface ISlider
{
  items: IAsset[];
}

const sliderSettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 700,
  arrows: true,
  dots: true,
};

const PromotialOffertSlider: FC<{ items: any[] }> = props => 
{
  console.log(props.items);
  return (
    <div css={`${SSlickBase}${SSlickTheme}${wrapper}`}>
      <SlickSlider {...sliderSettings}>
        {props.items.map((element, i) => (
          <div key={`promotional-offer-${i}`}>
            <ProductContainer>
              <ItemDetails>
                <h3><a href={`/produkt/${element.slug}`}>{element.title}</a></h3>
                <ItemDescription><RichTextGeneral textContent={element.text} /></ItemDescription>
                <ItemPrice>
                  <span>{element.ourPrice} Euro</span>
                  <span>{element.normalPrice}</span>
                </ItemPrice>
              </ItemDetails>
              <ItemPicture>
                <Img quality={1600} key={i} src={element?.images} alt={element?.productDescription ?? "image"}/>
              </ItemPicture>
            </ProductContainer>
          </div>
        ))}

      </SlickSlider>
    </div>
  );
};

export default PromotialOffertSlider;

