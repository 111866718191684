import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../../../constants/mediaquerys";

export const Wrapper: any = styled.div<{ withImg: string }>`
  ${({ withImg }) => !withImg && "justify-content: flex-start"};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 16px;
  @media (${BREAKPOINTS_DM.under_silver_768}) {
    div > img {
      height: calc((100vw - 70px) * 0.7);
      object-fit: cover;
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 174px;
  }
`;

export const Contentrapper: any = styled.div<{
  counterColor: string;
  contentColor: string;
}>`
  padding: 6px 0;
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  .counter {
    margin-bottom: 7px;
    font-size: 13px;
    color: ${({ counterColor }) => (counterColor ? counterColor : "white")};
  }

  .content {
    color: ${({ contentColor }) => (contentColor ? contentColor : "white")};
    line-height: 140%;
    overflow: hidden;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    h3 {
      font-size: inherit;
      margin-bottom: 6px;
      font-weight: bold;
      text-decoration: inherit;
      color: inherit;
    }
    p {
      font-size: inherit;
      color: inherit;
      text-decoration: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
  }
`;

export const img: any = css`
  object-fit: cover;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    margin-right: 25px;
  }
`;
