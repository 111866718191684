import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255,0.4);
  padding: 12px;
  border-radius: 16px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
    flex-direction: row;
    height: 205.33px;
  }
`;

export const ContentWrapper: any = styled.div`
    padding: 0 0 0 60px;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    max-height: 196px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
    }
`;

export const ImgWrapper: any = styled.a`
    object-fit: cover;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 30.13%;
      margin-right: 20px;
      height: 205px;
    }
`;

export const counter: any = css`
    margin-bottom: 15px;
    font-size: 13px;
`;

export const content: any = css`
    color: black;
    line-height: 140%;
    overflow: hidden;
    h3 {
      font-size: 26px;
      margin-bottom: 21px;
      font-weight: 400;
      line-height: 26px;
      color: inherit;
    }
    h2{
      text-decoration: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 40px 0 43px;
      span {
        color: #777878;
        font-size: 16px;
        line-height: 21px;
        font-weight: 300;
      }
    }
`;

export const Button: any = styled.a`
    display: flex;
    align-items: center;
    background-color: #94C03E;
    padding: 5px 8px;
    width: fit-content;
    color: #fff;
    p.button {
      font-weight: 300;
      line-height: 17.0625px;
      font-size: 13px;
      margin: 0 10px 0 0;
    }
    &:hover {
      background-color: #000;
    }
`;
