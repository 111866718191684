import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  ${({ theme }) => theme.box}
  margin-bottom: 21px;

  > h2 {
    font-size: 34px;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 11px;
  }

  .slick-dots {
    bottom: 5.5% !important;
    left: 0 !important;

    li {
      width: 14px !important;
      height: 14px !important;

      & > button {
        width: 14px !important;
        height: 14px !important;

        &:before {
          width: 14px !important;
          height: 14px !important;

          color: transparent !important;
          opacity: 1 !important;
          background-color: white;
          border: 1px solid ${(props) => props.theme.palette.textColor} !important;
          border-radius: 50%;
        }
      }

      &.slick-active > button:before {
        background-color: ${(props) =>
          props.theme.palette.textColor} !important;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      left: -36.5% !important;
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
      bottom: 6.5% !important;
      left: -40.5% !important;
    }
  }
`;
