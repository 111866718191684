import styled from "styled-components";

import SSlickBase from "src/common/utils/slick/SSlickBase";
import SSlickTheme from "src/common/utils/slick/SSlickTheme";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  ${SSlickBase};
  ${SSlickTheme};
  background: #d8e9c9;
  margin: 0;
  width: 100%;
  height: 274px;
  .slick-dots {
    bottom: 15px;
    left: -45px;
    width: 250px;
    opacity: 1;

    li {
      width: 13px;
      height: 13px;
      opacity: 1;

      & > button {
        width: 16px;
        height: 16px;
        opacity: 1;

        &:before {
          width: 16px;
          height: 16px;
          color: transparent;
          opacity: 1;
          background-color: #fff;
          border-radius: 50%;
        }
      }

      &.slick-active > button:before {
        background-color: #d8e9c9;
        opacity: 1;
      }
    }
  }
`;
