import styled, { css } from "styled-components";

export const landingWrapper: any = css`
  position: relative;
`;

export const ProductLandingBannerWrapper: any = styled.div<{background: string; titleColor: string;color: string; primary: string}>`
  background: pink;
  .plb-c10,
  .plb-desktop-link-left,
  .pbl-link {
    color: ${({ titleColor }) => (titleColor ? titleColor : "white")};
    h1.plb-title {
      color: ${({ titleColor }) => (titleColor ? titleColor : "white")};
      background-color: ${({ background }) => background ? background : "#d40046"};
      span {
        color: inherit;
      }
    }
    hr {
      border-top: 1px solid ${({ primary }) => primary ? primary : "D40046"};
    }
    .plb-price-badge {
      background-color: ${({ background }) => background ? background : "#d40046"};
      color: ${({ titleColor }) => (titleColor ? titleColor : "white")};
      :after {
        background-color: inherit;
      }
      :before {
        background-color: inherit;
      }
    }
    p {
      color: ${({ color }) => color ? color : "#222"};
    }
  }
  .plb-c0 {
    background-color: ${({ background }) => background ? background : "#d40046"};
  }
  div {
    &:hover,
    &:focus {
      z-index: 216;
    }
  }
`;
