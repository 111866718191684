import React, { FC, useContext } from "react";
import {
  Context,
  IAsset,
  ISlider
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import { Section } from "../../../BaseComponents/Grid";
import Img from "../../../BaseComponents/Img";
import BrandBannerVertical from "../../common/BrandBannerVertical";
import SubNavigation from "../../common/SubNavigation";
import { SectionWrapper, BrandBannerVerticalWrapper } from "./LandingContent.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={342}
        />
      );
    }
  }
  return imageOrGallery;
};

const LandingContent: FC = () =>
{
  const props = useContext(Context);
  return (
    <SectionWrapper>
      <Section>
        <Hero />
        <BrandBannerVerticalWrapper>
          <h2>Unsere Marken</h2>
          <BrandBannerVertical/>
        </BrandBannerVerticalWrapper>

        <SubNavigation />
      </Section>
    </SectionWrapper>
  );
};

export default LandingContent;
