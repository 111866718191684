import { FC, useContext } from "react";
import { Context, IAsset, ISlider, ITextElement, Img } from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import {
  FadeSliderWrapper,
  HeroWrapper,
  HeadDesktop,
  HeadMobile,
} from "./Hero.style";
import HeroLinks from "./HeroLinks";
import { css } from "styled-components";

const Hero: FC = () => {
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(
    (e) => e.__typename === "Asset"
  ) as IAsset[];
  const slider = props.PageLanding?.elements?.filter(
    (e) => e.__typename === "Slider"
  ) as ISlider[];
  const title = props?.PageLanding?.elements?.find(
    (element) => element.__typename === "TextElement"
  ) as ITextElement;

  let imageOrGallery;
  if (slider && !!slider?.[0]?.elements) {
    imageOrGallery = (
      <FadeSliderWrapper>
        <FadeSlider
          elements={slider?.[0]?.elements}
          options={{ arrows: true }}
        />
      </FadeSliderWrapper>
    );
  } else if (imgArray?.[0]) {
    if (imgArray.length > 1) {
      imageOrGallery = (
        <FadeSliderWrapper>
          <FadeSlider
            elements={[...imgArray?.map((f) => f)]}
            options={{ arrows: true }}
          />
        </FadeSliderWrapper>
      );
    } else if (imgArray?.length > 1) {
      imageOrGallery = (
        <FadeSliderWrapper>
          <FadeSlider elements={imgArray} options={{ arrows: true }} />
        </FadeSliderWrapper>
      );
    } else {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.description}
          css={css`background-color: ${imgArray?.[0]?.dominantColor}};`}
          src={imgArray}
          quality={1920}
          lazyload={false}
        ></Img>
      );
    }
  }

  return (
    <HeroWrapper>
      <HeadDesktop>
        <HeroLinks key="HeroLinksDesktop" />
      </HeadDesktop>
      {imageOrGallery}
      {title?.headline && <h1 className="title">{title?.headline}</h1>}
      <HeadMobile>
        <HeroLinks key="HeroLinksMobile"/>
      </HeadMobile>
    </HeroWrapper>
  );
};

export default Hero;
