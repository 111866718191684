import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
  margin-bottom: 21px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const EventItem: any = styled.div`
  ${({ theme }) => theme.box}
  width: 100%;
  margin-bottom: 21px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    > img {
      width: 100%;
      height: 280px;
      object-fit: cover;
      display: block;
      margin: 0 auto;
    }

    &:nth-child(4) {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;

      > img {
        width: 50%;
        height: auto;
        object-fit: cover;
        margin: auto;
      }

      a {
        margin: 0;
        padding: 0;
      }

      > a {
        order: -1;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;

        h4 {
          font-weight: 400;
          font-size: 34px;
          line-height: 37px;
          margin: 0;
        }
      }
    }

    &:nth-child(5) {
      grid-column: 1 / -1;
      text-align: center;
      > a > div > h1 {
        text-align: center !important;
      }
      > img {
        height: auto;
        object-fit: cover;
      }
    }

    &:nth-child(6) {
      grid-column: 1 / -1;
      display: flex;

      > img {
        padding-right: 20px;
      }

      > a {
        > h4 {
          font-weight: 400;
          font-size: 34px;
          line-height: 37px;
        }

        > div {
          > h3 {
            font-weight: 400;
            font-size: 25px;
          }

          > h4 > strong {
            font-weight: 500;
          }
        }
      }
    }

    @media (${BREAKPOINTS_DM.gold_1024}) {
      &:nth-child(4) {
        grid-column: 1 / span 2;
      }

      &:nth-child(5) {
        grid-column: 3 / 3;
      }
    }
  }
`;

export const EventIcon: any = styled.img`
  width: 100%;
`;

export const LinkWrapper: any = styled.a`
  display: block;
  padding: 10px 0;
  color: ${(props) => props.theme.palette.textColor};

  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  div {
    h1 {
      font-weight: 400;
      font-size: 25px;
      line-height: 28px;
    }

    p {
      color: ${(props) => props.theme.palette.textColor};
      display: inline;
      font-size: 14px;
      font-weight: 300;
      > span {
        font-weight: 300;
      }
    }
  }

  > a {
    display: inline-block;
    margin-top: 30px;
    font-weight: 300;
    font-size: 14px;
    img {
      margin-right: 7.5px;
    }
  }
`;
