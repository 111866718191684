import { H3_1366, H3_360, H3_768, P_1366_Regular, P_360_Regular, P_768_Regular } from "src/templates/t27/constants/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../../constants/mediaquerys";

const Default = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  transition: opacity 0.3s ease-in;

  .headline {
    color: var(--primary-100);
    ${H3_360}
  }

  .teaser {
    color: var(--front-2);
    ${P_360_Regular}
  }
`;

const Silver = css`
  .headline {
    ${H3_768}
  }

  .teaser {
    ${P_768_Regular}
  }
`;

const Gold = css``;

const Platinum = css`
  gap: 19px;
  padding-bottom: 19px;

  .headline {
    ${H3_1366}
  }

  .teaser {
    ${P_1366_Regular}
  }
`;

const Diamond = css``;

export const SSliderItemTextWrapper = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
