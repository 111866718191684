// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  display: flex;
  flex-direction: column;
  ${({ withImage }) => withImage && "justify-content: flex-start;"}

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 220px;
  }
`;

export const ContentWrapper: any = styled.div`
  padding: 6px 0;
  width: 100%;
  max-height: 196px;

  overflow: hidden;

  &:hover {
  }

  h3 {
    font-size: 25px;
    font-weight: 400;
    padding-bottom: 8px;
  }

  p,
  h2 {
    &,
    p,
    span {
      font-weight: 300;
      font-size: inherit;
      color: inherit;
      text-decoration: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  > a > a {
    display: inline-block;
    position: absolute;
    bottom: 20px;
    > img {
      display: inline-block !important;
      margin-right: 7.5px;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3 * 2);
  }
`;

export const ImageWrapper: any = styled.a`
  object-fit: cover;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 3);
    margin-right: 25px;
  }
`;

export const counter: any = css`
  font-size: 13px;
`;

export const Content: any = styled.div``;
