import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: 160px auto 0;
  h2.title {
    color: #000;
    margin: 110px 0 40px 25px;
    font-size: 19.5px;
    line-height: 22px;
    font-weight: 300;
  }
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding: 20px;
  div {
    h1 {
    color: #FA8800;
    font-weight: 400;
    letter-spacing: .02em;
    }
    P {
      padding-left: 50px;
      margin: 0 0 24px;
      span {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }
    }
  }


  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
`;


export const sideBar: any = css`
    width: 100%;
    max-width: initial;

    @media (${BREAKPOINTS_DM.silver_768})
    {
        width: 50%;
        margin-left: auto;
        max-width: 500px;
        padding-left: 21px;
    }
`;

export const mainText: any = css`
    width: 100%;
`;

export const BannerHorizontalWrapper: any = styled.div`
    background-color: rgba(255,255,255,.5);
    margin: 15px 0 0;
`;

export const BrandBannerVerticalWrapper: any = styled.div`
  width: 46%;
  margin-top: 80px;
  padding: 25px;

  > h2 {
    font-weight: 400;
    color: #333;
    font-size: 20px;
    margin-bottom: 38px;
  }
  > div {
    height: 210.5px;
  }

`;
