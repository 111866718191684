import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";

import { Grid } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";
import { ProductLandingBannerWrapper } from "../../content/LandingContent/LandingContent.style";

// https://61089-vorschau.dk-das-kuechenhaus.de/
const PageLandingT10: FC = () => (
  <>
    <Header />
    <Grid>
      <LandingContent />
      <ProductLandingBannerWrapper>
        <ProductLandingBanner/>
      </ProductLandingBannerWrapper>
    </Grid>
    <Footer />
     
    <LandingBannerLeft />
  </>
);

PageLandingT10.displayName = "PageLandingT10";

export { PageLandingT10 };
