import React from "react";

import MainNavigation from "../../common/MainNavigation/MainNavigation";
import { LandingContent } from "../../common/LandingContent/LandingContent";
import { Banner } from "../../Banner/Banner";
import { Footer } from "../../common/footer/Footer";
import { LandingBannerLeft } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";

const PageLandingT4 = () => {
    return (
        <div id="PageLandingT4100">
            <div id="PageLandingT41">
                 
            </div>
            <div>
                <ProductLandingBanner />
            </div>
            <div id="PageLandingT42">
                <MainNavigation />
            </div>
            <div id="PageLandingT43">
                <LandingContent />
            </div>
            <div id="PageLandingT44">
                <Banner />
            </div>
            <div id="PageLandingT45">
                <LandingBannerLeft />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
};
PageLandingT4.displayName = "PageLandingT4";
export { PageLandingT4 };
