import { Context, Img, RichText, getIfNewsShouldBeDisplayByDate } from "src/common";
import { IGenLandingEvent } from "src/common/types_dealer_gen";
import { getDownloadLinkFromConnection, getLinkFromConnection } from "src/common/utils/getLinkFromConnection";

import Link from "next/link";
import React, { FunctionComponent, ReactNode } from "react";

import { SLandingEvent } from "./Styles/SLandingEvent";
import { SLandingEventHeader } from "./Styles/SLandingEventHeader";
import { SLandingEventHeadline } from "./Styles/SLandingEventHeadline";
import { SLandingEventIcon } from "./Styles/SLandingEventIcon";
import { SLandingEventTextWrapper } from "./Styles/SLandingEventTextWrapper";

interface Props {
    event: IGenLandingEvent;
    className?: string;
}

export const LandingEvent: FunctionComponent<Props> = ({ event, className }) => {
    const shouldLandingEventBeDisplay = getIfNewsShouldBeDisplayByDate({
        startDate: event?.startDate,
        endDate: event?.endDate,
    });

    const link = getLinkFromConnection(event?.linksTo);
    const downloadLink = getDownloadLinkFromConnection(event?.downloadLink);

    if (!shouldLandingEventBeDisplay || !event) {
        return null;
    }

    const Content = (
        <>
            <SLandingEventHeader>
                {!!(event.icon?.[0] as any)?.src && (
                    <SLandingEventIcon>
                        <Img
                            lazyload={false}
                            contain
                            quality={500}
                            // @ts-ignore
                            src={event.icon?.[0]?.src}
                            alt="logo"
                        />
                    </SLandingEventIcon>
                )}
                {event.headline && <SLandingEventHeadline>{event.headline}</SLandingEventHeadline>}
            </SLandingEventHeader>
            {event.text && (
                <SLandingEventTextWrapper hasCustomParagraphTokenRichtext>
                    <RichText textContent={event.text} fragment />
                </SLandingEventTextWrapper>
            )}
        </>
    );

    const Wrapper = ({ children }: { children: ReactNode }): JSX.Element => {
        if (link.valid) {
            return (
                <Link href={link.href}>
                    <a className="landing__content">{children}</a>
                </Link>
            );
        } else if (downloadLink.valid) {
            return (
                <a
                    className="landing__content"
                    href={downloadLink.href}
                    download
                    rel={downloadLink.rel}
                    target={downloadLink.target}
                >
                    {children}
                </a>
            );
        } else {
            return <div className="landing__content">{children}</div>;
        }
    };

    return (
        <SLandingEvent isClickable={link.valid || downloadLink.valid} className={className}>
            <Wrapper>{Content}</Wrapper>
        </SLandingEvent>
    );
};
