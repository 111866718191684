import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import { Slide, ImageWrapper, ContentWrapper } from "./NewSlide.style";
import { richToTeaser } from "../../../../../../common/utils/text/teaser";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  teaser?: string;
}

function truncateWithEllipsis(s,maxLength) {
  if (s.length > maxLength) {
     return s.substring(0, maxLength) + '...';
  }
  return s;
};

const NewSlide: FunctionComponent<IProps> = props =>
{
  const teaser = props.teaser ? props.teaser : props?.text && richToTeaser(props.text);
  return (
    <Slide withImage={!!props.img}>
      {props.img && (
        <ImageWrapper href={props?.link}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
        </ImageWrapper>
      )}
      <ContentWrapper>
        <a href={props?.link}>
          {props.title && <h3>{props.title}</h3>}
          {teaser && (<p>{truncateWithEllipsis(teaser, 200)}</p>)}

          <a><img src="/images/kg_icon_link_arrow.jpeg" />mehr</a>
        </a>
      </ContentWrapper>
    </Slide>
  );
};

export default NewSlide;
