import { css } from "styled-components";

export const wrapper: any = css`
    margin-top: 20px;
    margin-bottom: 40px;
    .slick-dots {
        bottom: -35px;
        li button:before {
          // background-color: #d0c5c8;
          border-radius: 50%;
        }
    }
`;
