import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ImageWrapper: any = styled.div<{ count: number}>`
  width: 100%;
  margin-bottom: 21px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / ${({ count }) => count} - 10px);
  }
`;
