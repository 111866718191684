import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const wrapper: any = css`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  margin: auto;
  background-color: transparent;
`;

export const contentWrapper: any = css`
  display: flex;
  flex-direction: column;
  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {

    padding: 0px 10px;
    flex-direction: row;
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
`;

export const leftContent: any = css`
  width: 100%;
  padding: 10px;
  @media (${BREAKPOINTS_DM.silver_768}){
    width: 50%;
  }
  h1 {
    font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif;
    color: #fff;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: .02em;
    font-size: 24px;
  }
  p {
    span {
      font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif;
      color: #fff;
      font-size: 15px;
    }
  }
`;

export const sideBar: any = css`
    width: 100%;
    max-width: initial;

    @media (${BREAKPOINTS_DM.silver_768})
    {
      padding: 0px 11px;
        width: 50%;
        margin-left: auto;
        max-width: 500px;
    }
`;

export const mainText: any = css`
    width: 100%;
`;

export const bannerHorizontal: any = css`
    margin-bottom: -20px;
`;

export const heroWrapper: any = css`
    margin: 123px 0 -170px;
    padding: 21px 10px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      margin: 0;
      padding: 21px;
    }
`;

export const FurterAwards: any = styled.div`
  width: 100%;
  display: flex;
  justifyContent: space-between;
`;
export const FurterAwardsContent: any = styled.div`
  width: 33.3%;
  padding: 70px 10.5px 50px;
  div {
    width: 80px;
    height: auto;
  }
`;
