import styled, { keyframes } from "styled-components";

export const FadeSliderWrapper: any = styled.div`
  width: 100%;
  div {
    border: none;
  }
`;

export const HeroWrapper: any = styled.div`
  width: 100%;
  max-height: 460px;
`;

const fadeIn: any = keyframes`
  0% { opacity: 1;}
  35% {opacity:0;}
  100% {opacity:1;}
`;

export const TextWrapper: any = styled.div`
  z-index: 1;
  position: relative;
  max-width: 570px;
  background: #1d1d1b;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;

  /* animation-name: ${fadeIn}; */
  /* animation-duration: 6000ms; */
  /* animation-iteration-count: infinite; */

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 8px;
  }
  h3:after {
    content: " ";
    display: block;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid #1d1d1b;
  }
`;
