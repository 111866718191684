import React, { FC, useContext } from "react";
import Slider, { Settings } from "react-slick";
import { Context, IAsset } from "src/common";

import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import * as styles from "./NewSlider.style";
import NewSlide from "./slide/NewSlide";

const sliderSettings: Settings = {
  dots: true,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: false,
};

const NewSlider: FC<{newsCustom?: boolean}> = ({ newsCustom }) => 
{
  const p = useContext(Context);
  const news = newsCustom ? p?.PageLanding?.NewsCustom : p.PageLanding?.News;
  const newsSlug = p.NewsSlug;
  if(!news || news.length <= 0) 
  {
    return null;
  }

  const newsToRender = news?.filter(item => 
  {
    if(!item) 
    {
      return false;
    }
    if(!item?.startDate && !item?.endDate) 
    {
      return true;
    }
    else if(!item?.startDate && item?.endDate) 
    {
      return new Date() <= new Date(item?.endDate);
    }
    else if(item?.startDate && !item?.endDate) 
    {
      return new Date() >= new Date(item?.startDate);
    }
    else 
    {
      return (
        new Date() >= new Date(item?.startDate)
        && new Date() <= new Date(item?.endDate)
      );
    }
  });

  return (
    <div css={`
            ${SSlickBase}
            ${SSlickTheme}
            ${styles.wrapper}
        `}>
      <Slider {...sliderSettings}>
        {newsToRender.map((NewsItem, i) => (
          <NewSlide
            key={i + NewsItem?.id}
            index={i}
            totalCount={newsToRender.length}
            img={(NewsItem?.extras as IAsset[])}
            imgAlt={(NewsItem?.extras as IAsset[])?.[0]?.title}
            title={NewsItem?.headline}
            text={NewsItem?.text}
            link={newsSlug && (newsSlug + "/" + NewsItem?.id)}
          />
        ))}
      </Slider>
    </div>
  );
};

export default NewSlider;
