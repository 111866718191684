import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const FadeSliderWrapper: any = styled.div`
    width: 100%;
    div {
        border: none;
    }
    .slick-prev,
    .slick-next {
        z-index: 99999;
        background-color: rgba(0, 0, 0, 0.4) !important;
        width: 40px !important;
        height: 100px !important;
        :before {
            opacity: 1;
            display: inline-block;
            content: "›" !important;
            font-size: 40px !important;
            line-height: 15px !important;
            width: 12px;
            color: #fff !important;
        }
        :hover {
            background-color: rgba(0, 0, 0, 0.8) !important;
        }
    }

    .slick-next {
        right: 0 !important;
    }

    .slick-prev {
        left: 0 !important;
        :before {
            content: "‹" !important;
        }
    }
`;

export const HeroWrapper: any = styled.div`
    h1.title {
        width: 100%;
        pointer-events: none;
        max-width: 1170px;
        text-align: center;
        position: relative;
        bottom: 280px;
        color: #fff;
        font-size: 34px;
        font-weight: 500;
        line-height: 63.6px;
        margin: 0 auto -669.578px;
        text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
        height: 668.578px;
    }
    div {
        img {
            min-width: 100%;
            height: 100%;
            height: max(595px, 54vw);
            filter: grayscale(49.5%) brightness(70.3%);
        }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        h1.title {
            font-size: 53px;
            bottom: 280px;
        }
        div {
            img {
                height: max(668.578px, 54vw);
            }
        }
    }

    @media (${BREAKPOINTS_DM.diamond_1920}) {
        h1.title {
            max-width: 100%;
        }
    }
`;

export const HeadDesktop: any = styled.div`
    display: flex;
    display: none;
    align-items: center;
    color: #fff;
    width: 100%;
    font-size: 13px;
    background: #666;
    padding: 8px 10px 10px;

    @media (${BREAKPOINTS_DM.silver_768}) {
        display: flex;
        height: 668px;
        position: absolute;
        margin: 103px 0 0;
        z-index: 120;
        background: transparent;
        padding: 8px 60px 10px;
        align-items: flex-start;
    }
`;

export const HeadMobile: any = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    width: 100%;
    font-size: 13px;
    background: #666;
    padding: 8px 10px 10px;

    @media (${BREAKPOINTS_DM.silver_768}) {
        display: none;
    }
`;

export const HeadContent: any = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    font-size: 13px;
    padding: 8px 0 16px;
    h4 {
        font-family: Roboto, sans-serif;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.6px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 30px 0 10px;
    }
    a {
        cursor: pointer;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 20.8px;
        span {
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 20.8px;
            text-decoration-color: rgb(255, 255, 255);
            text-decoration-line: underline;
            text-decoration-style: solid;
        }
    }
    div.links-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;
        width: 100%;
        div.data {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 30px;
        }
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
        border-top: 2px dashed #fff;
        border-bottom: 2px dashed #fff;
        flex-direction: row;
        min-height: 124px;
        h4 {
            margin: 5px 0 10px;
        }
        div.links-data {
            flex-direction: row;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            div.data {
                text-align: left;
            }
        }
    }
`;
