import styled from "styled-components";

const MIN_HEIGHT = 420;

export const Wrapper: any = styled.div<{ open?: boolean }>`
  position: fixed;
  display: flex;
  top: calc(50vh - ${MIN_HEIGHT/2}px);
  right: -350px;
  ${({ open }) => open && "transform: translateX(-350px)"};

  height: ${MIN_HEIGHT}px;
  min-width: 30px;

  background-color: ${({ theme }) => theme.palette.primary};
  transition: transform 1s;
`;

export const SideBanner: any = styled.div`
  position: relative;
  height: 100%;
  width: 30px;
  cursor: pointer;

  > a {
    color: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    top: 50%;
    left: 50%;
    position: absolute;
    font-size: 1.4rem;
    display: block;
  }
`;

export const OfferContainer: any = styled.div`
  width: 350px;
  height: 100%;
  background-color: white;
`;

export const OfferItem: any = styled.div`
  width: 100%;
  height: 100%;

  > h3 {
    position: relative;
    background: #f9b000;
    color: #fff;
    font-size: 2.2rem;
    line-height: 1.1;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    cursor: pointer;
  }
`;

export const ImageWrapper: any = styled.div`
  height: 200px;
`;

export const Description: any = styled.div`
  position: relative;

  > hr {
    margin-top: 12px;
    margin-bottom: 20px;
    outline: 0;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.palette.primary};
  }

  > h4 {
    margin-left: 7px;
    margin-bottom: 7px;
    font-size: 1.333rem;
    color: #f9b000;
    font-weight: 700;
  }

  > p {
    padding: 0 7px 20px 7px;
  }
`;

export const Price: any = styled.div`
  position: absolute;
  top: -80px;
  left: 20px;
  background-color: ${({ theme }) => theme.palette.primary};
  color: white;

  z-index: 99;
  font-size: 1.4em;
  font-weight: 700;
  padding: 8px;
  transform: rotate(-10deg);
  box-shadow: rgb(0 0 0 / 40%) 1px 1px 10px;


  :before {
    content: " ";
    height: 10px;
    width: 10px;
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 20px;
    position: absolute;
    left: -5px;
    top: calc(50% - 5px);
  }
`;
