import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

export const wrapper: any = css`
  padding: 20px 10px 0 10px;
  width: 100%;
  max-width: calc(100vw - 20px);

  .slick-dots {
    top: -32px;
    height: 50px;
    li {
      width: 15px;
      height: 15px;

      & > button {
        width: 15px;
        height: 15px;

        &:before {
          width: 15px;
          height: 15px;

          color: transparent;
          opacity: 1;
          background-color: #d0d2d3;
          border-radius: 50%;
        }
      }

      &.slick-active > button:before {
        background-color: ${(props) => props.theme.palette.primary};
      }
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
    margin: 40px -15px 40px -15px;
    max-width: 340px;
    .slick-dots {
      top: 180px;
    }

    .slick-slide img {
      max-height: 214px;
      max-width: 310px;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0;
    margin: 40px -15px 40px -15px;
  }
`;
