import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Banner from "../../common/Banner";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import PlanningDateModal from "../../common/PlanningDateModal";
 ;
import LandingContent from "../../content/LandingContent";
import { LandingBannerLeftWrapper } from "../../content/LandingContent/LandingContent.style";

// https://60738-vorschau.gk-gute-kuechen.de/
const PageLandingT16: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Banner />
    <Footer />
     
    <LandingBannerLeftWrapper>
      <LandingBannerLeft />
    </LandingBannerLeftWrapper>
    <PlanningDateModal/>
  </>
);

PageLandingT16.displayName = "PageLandingT16";

export { PageLandingT16 };
