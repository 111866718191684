import styled from "styled-components";
import { Container } from "../Grid";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FurtherAwardsWrapper: any = styled.div`
  height: 90px;
  display: flex;
  width: 320px;
`;

export const ImageWrapper: any = styled.div`
  width: 100%;
  padding: 0 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 50%;
    padding: 0 5px;
  }
`;

