import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Img, Context } from "src/common";

import * as styles from "./LogoBannerHorizontal.style";
import { SLogoBannerHorizontalWrapper } from "./Styles/SLogoBannerHorizontalWrapper";

const LogoBannerHorizontal: FunctionComponent = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;
  if (allBrands.length <= 0) {
    return null;
  }

  const companies = allBrands.map((company, i) => {
    if (!company?.logo) {
      return null;
    }

    return (
      company?.logo.length >= 1 && (
        <div key={i} css={styles.imgWrapper}>
          <Img
            src={company?.logo}
            quality={200}
            alt={company?.name || ""}
            lazyload={false}
          />
        </div>
      )
    );
  });


  return (
    <SLogoBannerHorizontalWrapper>
      {companies}
    </SLogoBannerHorizontalWrapper>
  );
};

export { LogoBannerHorizontal };
