import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../../BaseComponents/Img/Img";
import RichText from "../../../../../BaseComponents/RichText/RichText";
import * as styles from "./NewSlide.style";

interface IProps
{
  index: number;
  totalCount: number;
  img?: IAsset;
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
}

export const NewSlide: FunctionComponent<IProps> = props => 
{
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find(p => p.type.includes("heading"));
  if(elementToShowAsSubtitle)
  {
    text = { content: [elementToShowAsSubtitle] };
  }
  return (
    <div css={styles.wrapper} style={ !props.img ? { justifyContent: "flex-start" } : null }>
      <div css={styles.contentWrapper}>
        {props.title && <h4>{props.title}</h4>}
        {text && (
          <div css={styles.richText}>
            <RichText textContent={text}></RichText>
          </div>
        )}
        {props.link && <a href={props.link}>Erfahren Sie mehr</a>}
      </div>
      {props.img && (
        <a href={props.link} css={styles.img}>
          <Img quality={600} src={props.img?.src} alt={props.imgAlt}/>
        </a>
      )}
    </div>
  );
};

