import React, { FunctionComponent } from "react";
import { IAsset } from "src/common";

import Img from "../../../../BaseComponents/Img/Img";
import RichText from "../../RichText/RichText";
import SRichTextGeneral from "../../RichText/RichText.style";
import * as styles from "./NewSlide.style";
import { Slide, ContentWrapper, ImageLink } from "./NewSlide.style";

interface IProps {
  index: number;
  totalCount: number;
  img?: IAsset[];
  imgAlt?: string;
  title?: string;
  text?: any;
  link?: string;
  teaser?: string;
}

const NewSlide: FunctionComponent<IProps> = (props) => {
  let text: any;
  const elementToShowAsSubtitle = props.text?.content?.find((p) =>
    p.type.includes("heading")
  );
  if (elementToShowAsSubtitle) {
    text = { content: [elementToShowAsSubtitle], type: "doc" };
  }

  return (
    <Slide withImage={!!props.img}>
      {props.img && (
        <ImageLink href={props?.link}>
          <Img quality={600} src={props.img} alt={props.imgAlt} />
          <p css={styles.counter}>
            KÜCHENNACHRICHT {props?.index + 1} / {props?.totalCount}
          </p>
        </ImageLink>
      )}
      <ContentWrapper>
        <a href={props?.link}>
          {props.title && <h3>{props.title}</h3>}
          {props.teaser && <p> {props.teaser} </p>}
        </a>
        <a href={props?.link} data-more>
          <img src="/images/kw_icon_arrow_right.png" />
          mehr
        </a>
      </ContentWrapper>
    </Slide>
  );
};

export default NewSlide;
