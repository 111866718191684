import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

import colors from "../../../constants/colors";

export const landingImageWrapper: any = css`
    padding: 0;
`;

export const landingImage: any = css`
    width: 100%;
    height: auto;
`;

export const ctaBox: any = css`
    background-color: ${colors.yellow};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    a {
        margin: 20px 0;
        color: ${colors.yellow};
        padding: 8px;
        max-width: 360px;
        width: 100%;
        text-align: center;
        background: white;
        font-size: 24px;
        display: block;
    }
`;

export const galleryWrapper: any = css`
    > div {
      background-color: #e0e0e0;
      overflow: hidden;
      * {
        color: white;
      }
      &:first-child, &:nth-child(2), &:nth-child(3) {
        background-color: ${colors.cloudBlue};
      }
      &:nth-child(2), &:nth-child(3) {
        padding: 0 0 6px;
      }
    }
    h4 {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 14px;
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      > div {
        &:nth-child(4) {
          display: initial;
          background-color: ${colors.cloudBlue};
        }
      }
    }
`;

export const galleryItemIcon: any = css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      object-fit: contain !important;
      height: auto !important;
    }
`;

export const galleryItemText: any = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    line-clamp: 9;
`;

export const brands: any = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    img {
      filter: grayscale(1);
      height: 20px !important;
      width: auto !important;
      margin: 0 12px 17px 0 !important;
    }
`;
