import React, { FunctionComponent, useContext } from "react";
import Slider from "react-slick";
import { getIfNewsShouldBeDisplayByDate, Context, IAsset } from "src/common";

import { Wrapper } from "./NewSlider.style";
import { NewSlide } from "./slide/NewSlide";

export const NewSlider: FunctionComponent<{ newsCustom?: boolean }> = ({
  newsCustom,
}) => {
  const p = useContext(Context);
  const palette = p?.Settings;
  const newsSlug = p?.NewsSlug;

  const currentNews = newsCustom
    ? p?.PageLanding?.NewsCustom
    : p?.PageLanding?.News;
  const news = currentNews?.filter((n) =>
    getIfNewsShouldBeDisplayByDate({
      startDate: n?.startDate,
      endDate: n?.endDate,
    })
  );

  if (!news || news?.length <= 0) {
    return null;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Wrapper
      className={`news-slider${newsCustom ? " custom" : " root"}`}
      background={palette.themeBackgroundColourIsland}
      dotColors={palette.themeTextColourIsland}
    >
      <Slider {...sliderSettings}>
        {news?.map((newsItem: any, i) => {


          const link = (newsItem as any)?.externalLink ? `${(newsItem as any).externalLink}` : newsSlug && `/${newsSlug}/${newsItem?.id}`;
          
          return (
            <NewSlide
              key={i}
              index={i}
              teaser={(newsItem as any)?.teaser}
              totalCount={news?.length}
              img={
                (
                  newsItem?.extras?.find(
                    (e) => e?.__typename === "Asset"
                  ) as IAsset
                )?.src
              }
              imgAlt={
                (
                  newsItem?.extras?.find(
                    (e) => e?.__typename === "Asset"
                  ) as IAsset
                )?.title
              }
              title={newsItem?.headline}
              text={newsItem?.text}
              link={link}
            />
          )
        })}
      </Slider>
    </Wrapper>
  );
};
