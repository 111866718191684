import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import { css } from "styled-components";

export default css`
  .slick-prev,
  .slick-next {
    z-index: 1;
    position: absolute;
    width: 21px;
    height: 21px;
    padding: 5px;
    top: 37%;
    transform: translate(0, -37%);
    cursor: pointer;
    outline: 2px solid transparent;
    color: var(--back-1);
    border-radius: 50%;
    transition: outline-color 0.3s ease-in-out;

    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 23px;
      height: 23px;
      top: 38%;
      transform: translate(0, -38%);
    }

    @media (${BREAKPOINTS_DM.platinum_1366}) {
      width: 28px;
      height: 28px;
      top: 36%;
      transform: translate(0, -36%);
    }
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline-color: var(--back-3);
    background-color: var(--front-2);
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  /* Dots */

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    order: -1;

    @media (${BREAKPOINTS_DM.platinum_1366}) {
      gap: 18px;
    }
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    @media (${BREAKPOINTS_DM.platinum_1366}) {
      width: 18px;
      height: 18px;
    }
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    background-color: var(--front-2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    text-align: center;
  }

  .slick-dots li.slick-active button:before {
    background-color: var(--primary-100);
  }
`;
