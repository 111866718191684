import React, { FC } from "react";
import { LandingBannerLeft } from "src/common";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
 ;
import LandingContent from "../../content/LandingContent";

// https://61101-vorschau.gk-gute-kuechen.de //
const PageLandingT11: FC = () => (
  <>
    <Header />
    <LandingContent />
    <Footer />
     
  </>
);

PageLandingT11.displayName = "PageLandingT11";

export { PageLandingT11 };
