// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Slide: any = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  ${({ withImage }) => withImage && 'justify-content: flex-start;'}
  height: 340px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    height: 150px;
  }
`;

export const contentWrapper: any = css`
    padding: 6px 0;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    max-height: 196px;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
    }
`;

export const img: any = css`
    object-fit: cover;
    width: 100%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 50%;
      margin-right: 25px;
    }
`;

export const counter: any = css`
    margin-bottom: 15px;
    font-size: 13px;
`;

export const content: any = css`
    color: white;
    line-height: 140%;
    overflow: hidden;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    h3 {
      font-size: inherit;
      margin-bottom: 10px;
      font-weight: 400;
      text-decoration: inherit;
      color: inherit;
    }
    h2 {
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: inline;
      font-family: franklin_gothic_fs_bookRg, Helvetica, Arial, sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 21px;
    }
    p {
      font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif;
      color: #fff;
      font-size: 15px;
      text-decoration: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }
`;
