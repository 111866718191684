import React, { Fragment } from "react";
import SlickSlider from "react-slick";
import { IAsset } from "src/common";

import Img from "../../../BaseComponents/Img";
import {
  TextWrapper,
  LastTextContainer,
  TextContainer,
  textLight,
  textLightLast,
  ButtonContainer,
  Button,
  TextButton,
  PromotionalOffertHeading,
  HeadingText,
  ImgWrapper,
} from "./PromotionalOffertSlider.style";
export interface IsContent {
  carouselContent: object;
}

export interface ISlider {
  items: IAsset[];
}

const PromotialOffertSlider: any = props => {
  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 700,
    arrows: true,
  };
  return (
    <div>
      <PromotionalOffertHeading>
        <HeadingText>
          Ausstellungsküchen zum Abverkauf
        </HeadingText>
      </PromotionalOffertHeading>
      <SlickSlider {...sliderSettings}>
        {props.items.map((element, i) => {
          console.log(` element`, element);
          return (
            <Fragment key={`promotional-offer-${i}`}>
              <TextWrapper>
                <TextContainer>
                  {element.title}
                </TextContainer>
                <TextContainer>
                  <div css={textLight}>Voriger Preis:&nbsp;&nbsp;</div>{element.normalPrice}
                </TextContainer>
                <LastTextContainer>
                  <div css={textLightLast}>Jetzt für nur:&nbsp;&nbsp;</div>{element.ourPrice}
                </LastTextContainer>
              </TextWrapper>
              <ImgWrapper>
                <Img quality={1600} key={i} src={element?.images} alt={element?.description ?? "image"} height={840} />
              </ImgWrapper>
              <ButtonContainer key={`button-key-${i}`}>
                <Button>
                  <TextButton href={(element?.__typename?.toLowerCase().includes("product") ? '/produkt' : '') + "/" + element?.slug} className={"test"}>
                    Zum Angebot
                </TextButton>
                </Button>
              </ButtonContainer>
            </Fragment>
          )
        })}

      </SlickSlider>
    </div >
  );
};

export default PromotialOffertSlider;

