export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#575756",
  textColorSecondary: "#00628a",
  textColorLight: "#adadad",
  textColorInverted: "#aaaaaa",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#81c7dc",
  secondary: "#f6931e",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#81c7dc",
  bgInverted: "#6b6b6b",
  bgLight: "#FEE580",
  brown: "#816456",
  gray: "#666",
};
