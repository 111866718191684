import React, { FC } from "react";
import {
  IAsset,
  ISlider,
  Img,
  IPage
} from "src/common";
import {
  IPageGuestbook, IPageRepairInquiry, IPageDateInquiry, IPageContact, IPageBrand, IPageBrandOverview, IPageLanding, IPageImprint, IPageNews, IPageCourse 
} from "src/common/childTypes";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import ImageWithAspectRatio from "../../../BaseComponents/ImageWithAspectRatio";

import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import { HeroContainer } from "./Hero.style";

const OVERRIDE_STYLE = `
  ${SSlickBase}
  ${SSlickTheme}
`;

const SLIDER_OPTIONS = {
  arrows: true,
  dots: true,
  autoplaySpeed: 4000,
  fade: false,
};

const Hero: FC<{
  page: IPageGuestbook | IPage | IPageRepairInquiry | IPageDateInquiry | IPageContact | IPageBrand | IPageBrandOverview | IPageLanding | IPageImprint | IPageNews | IPageCourse;
}> = ({ page }) => 
{
  // @ts-ignore
  const images = page?.elements?.filter(e => e.__typename === "Asset") as IAsset[];
  // @ts-ignore
  const slider = page?.elements?.filter(e => e.__typename === "Slider") as ISlider[];

  let component = null;

  if(slider && slider?.[0]?.elements) 
  {
    component = <FadeSlider
      overrideStyle={OVERRIDE_STYLE}
      elements={slider?.[0]?.elements}
      options={SLIDER_OPTIONS}
    />;

  }
  else if(images?.[0]) 
  {
    if(images.length > 1) 
    {
      component = <FadeSlider
        overrideStyle={OVERRIDE_STYLE}
        elements={[...images?.map(f => f)]}
        options={SLIDER_OPTIONS}
      />;
    }
    else if(images?.length > 1) 
    {
      component = <FadeSlider
        overrideStyle={OVERRIDE_STYLE}
        elements={images}
        options={SLIDER_OPTIONS}
      />;
    }
    else 
    {
      component = (
        <ImageWithAspectRatio
          alt={images?.[0]?.title}
          src={images}
          placeholderHeight="580px"
          ratio={48.4}
        />
      );
    }
  }

  return <HeroContainer>{component}</HeroContainer>;
};

export default Hero;
