import styled from "styled-components";

import colors from "../../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../../constants/mediaquerys";

export const ContactWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-intems: center;
  height: auto;
  `;
  
export const ContentWrapper: any = styled.div`
  width: 33.3%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
  };
`;

export const Content: any = styled.div`
  position: relative; 
  bottom: 220px;
  margin-bottom: -190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  width: 300%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    bottom: 230px;
    margin-bottom: -190px;
  };
`;

export const Title: any = styled.h4`
  text-shadow: 0 0 3px #030303;
  color: ${colors.white};
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 400;
  font-family: Roboto,sans-serif;
  padding: 20px 0px 30px;
`;

export const Button: any = styled.button`
  border: 2px solid ${colors.white};
  border-radius: 4px;
  padding: 6px 18px;
  color: ${colors.white};
  a {
    color: ${colors.white};
  }
  &:hover {
    a {
      color: ${colors.green};
      opacity: 1;
    }
    background-color: ${colors.white};
    color: ${colors.light_green}
  }
`;

export const ButtonText: any = styled.a`
  font-family: Roboto,sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
`;  
