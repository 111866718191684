import React, { FunctionComponent, useState } from "react";
import Img from "../../../../BaseComponents/Img/Img";
import * as styles from "./LandingGridItem.style";
import ColorThief from "colorthief/dist/color-thief";

interface IProps {
  index: number;
  icon: string;
  link: string;
  label: string;
  target?: string;
}

const LandingGridItem: FunctionComponent<IProps> = (props) => {
  const [bg, setBg] = useState("transparent");

  const getBackroundColor = () => {
    try {
      const img: any = document
        .querySelector(".gridItem-" + (props.index + 1))
        .querySelector("img");
      img.crossOrigin = "Anonymous";
      const colorThief = new ColorThief();
      const calc = () => {
        let index = 0;
        let currMax = 0;

        const palette = colorThief.getPalette(img, 5);
        palette?.map((p, i) => {
          const sum = p[0] + p[1] + p[2];
          if (sum > currMax) {
            index = i;
            currMax = sum;
          }
        });
        palette && setBg(`rgb(${palette[index].join(",")})`);
      };
      if (img?.complete) {
        if (img.width > 0 && img.height > 0) {
          calc();
        }
      } else {
        img?.addEventListener("load", function () {
          if (img.width > 0 && img.height > 0) {
            calc();
          }
        });
      }
    } catch (err) {
      console.error(`getBackroundColor err`, err);
    }
  };

  return (
    <a
      className={"gridItem-" + (props.index + 1)}
      style={{ backgroundColor: bg }}
      css={styles.wrapper}
      href={props.link}
      target={props.target}
    >
      <Img
        src={props.icon}
        onLoad={() => getBackroundColor()}
        css={`
          ${styles.img}
        `}
        quality={640}
      />
      <span
        css={`
          ${styles.span}
        `}
      >
        {props.label}
      </span>
    </a>
  );
};

export default LandingGridItem;
