import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    flex-direction: row;
    > div {
      width: 50%;
      padding: 10px 15px;
    }
  }
`;

export const ItemDetails = styled.div`
  h3 {
    font-size: 18px;
    color: #222;
    line-height: 1.4;
    margin-bottom: 10px;
    margin-top: 5px;
    font-weight: 400;
  }
`;

export const ItemDescription = styled.div`
  * {
    font-size: 13px;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    font-weight: 400;
    overflow-x: hidden;
    color: #666;
  }
`;

export const ItemPrice = styled.div`
  span:first-child {
    font-size: 13px;
    line-height: 1.4;
    display: inline-block;
    padding: 3px 20px;
    margin-bottom: -3px;
    margin-right: 8px;
    background: #bbb;
    font-weight: 700;
    color: #fff;
  }
  span:last-child {
    text-decoration: line-through;
  }
`;

export const ItemPicture = styled.div`
  margin-top: 10px;
`;

export const wrapper: any = css`
  .slick-dots {
      position: static;
      margin-top: 20px;
      li {
        margin: 3px;
        width: 12px;
        height: 12px;
        & > button {
          width: 12px;
          height: 12px;
          &:before {
            opacity: 1;
            width: 8px;
            height: 8px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #ddd;
            border: 2px solid #ddd;
            color: transparent;
            border-radius: 12px;
          }
        }

        &.slick-active > button:before  {
          border-color: #adadad;
          background-color: #fff;
        }
      }
  }
  .slick-prev,
  .slick-next {
    z-index: 9;
    background-color: rgba(255,255,255,.5) !important;
    width: 30px !important;
    height: 30px !important;
    :before {
      opacity: 1;
      display: inline-block;
      content: "" !important;
      background-image: url(/images/sprite-sa3ca3ca63e.png);
      background-repeat: no-repeat;
      background-position: 0 -172px;
      height: 18px;
      width: 12px;
      color: transparent !important;
    }

    :hover {
      background-color: rgba(255,255,255,.5);
    }
  }


  .slick-next {
    right: 0 !important;
    top: 60%;
  }

  .slick-prev {
    left: 0 !important;
    top: 60%;
    :before {
      transform: rotate(180deg);
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    .slick-next {
      right: -25px !important;
      top: 50%;
    }
  
    .slick-prev {
      left: -25px !important;
      top: 50%;
      :before {
        transform: rotate(180deg);
      }
    }
  }
`;
