import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const PromotionalOfferButtom: any = styled.div<{active: boolean}>`
  position: absolute;
  left: ${({ active }) => active ? "560px" : "0"};
  top: 930px;
  width: 45px;
  height: 410px;
  background-color: #F19024;
  align-items: center;
  p {
    transform: rotate(90deg);
    font-size: 22.4px;
    line-height: 22.4px;
    color: #fff;
    text-align: center;
    margin: -48px
  }
  display: none;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: flex;
    justify-content: flex-start;
  }
`;

export const PromotionalOfferContentWrapper: any = styled.div<{active: boolean}>`
position: absolute;
display: none;
left: 0;
top: 930px;
background-color:  #F19024;
${({ active }) => active
    && `
    @media(${BREAKPOINTS_DM.gold_1024}) {
      width: 560px;
      height: 410px;
    }
  `
}
@media (${BREAKPOINTS_DM.gold_1024}) {
  display: flex;
  justify-content: flex-start;
}
`;

export const PromotionalOfferContent: any = styled.div`
  width: 560px;
  height: 380px;
  padding: 30px 0 0px 20px;
  p {
    font-size: 16px;
    position: absolute;
    color: #fff;
    top: 5px;
    font-weight: 100;
    letter-spacing: 0.5px;
    right: 6px;
  }
`;

export const PromotionalOfferButtomMobile: any = styled.a`
  color: #5E5E5D;
  text-decoration: underline;
  text-align: center;
  margin: -80px 0 50px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 50px 0 50px;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {

    display: none;
  }
  @media (${BREAKPOINTS_DM.platinum_1366}) {
    display: none;
  }
`;
