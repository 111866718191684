import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

export const Wrapper: any = styled.div`
`;

export const FurtherAwardsWrapper: any = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-top: 10px;
`; 
export const ImageWrapper: any = styled.div`
  @media (${BREAKPOINTS_DM.silver_768}) {
  }
`;
