import React, { FC, useContext } from "react";
import { Context, ITextElement, IRichLinkItem } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";
import { DachseiteSearch } from "../../common/DachseiteSearch/DachseiteSearch";
import FurtherAwards from "../../common/FurtherAwards";
import { LogoBannerHorizontal } from "../../common/LogoBannerHorizontal";
import { MusterkuechenLink } from "../../common/MusterkuechenLink/DachseiteSearch";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import Hero from "./Hero";
import * as styles from "./LandingContent.style";
import {
  SectionWrapper,
  BannerHorizontalWrapper,
  ContentWrapper,
  LeftContent,
  RightContent,
  InKitchenCompass,
  RichLinkContent,
  RichLinkContainer,
  RichLinkWrapper,
  RichLinkAnchor,
  RichLinkImage,
  RichLinkImageText,
  RichTextWrapper,
  ProductLandingBannerWrapper,
  NewsWrapper,
  RichLinkAnchorWrapper,
} from "./LandingContent.style";
import { SLandingContainer } from "./SLandingContainer.style";
import { getLinkFromConnection } from "../../../../../common/utils/getLinkFromConnection";

const LandingContent: FC = () => {
  const props = useContext(Context);
  const { PageLanding: page } = props;
  const richLink = page?.elements?.filter(
    (e) => e?.__typename === "RichLinkItem"
  ) as IRichLinkItem[];
  const isNewsCustom = page?.NewsCustom?.length > 0;

  return (
    <SectionWrapper>
      <Hero
        headline={
          (
            props.PageLanding?.elements?.filter(
              (e) => e?.__typename === "TextElement"
            ) as ITextElement[]
          ).find((h) => h.headline)?.headline
        }
      />
      <ProductLandingBannerWrapper>
        <ProductLandingBanner />
      </ProductLandingBannerWrapper>
      <SLandingContainer>
        <ContentWrapper>
          <LeftContent>
            {(
              props.PageLanding?.elements?.filter(
                (e) => e?.__typename === "TextElement"
              ) as ITextElement[]
            )?.map((t, i) => (
              <RichTextGeneral
                key={`rt0-${i}`}
                textContent={t.text}
                css={styles.mainText}
              >
                {/* <h1>{t?.headline}</h1> */}
              </RichTextGeneral>
            ))}
            <InKitchenCompass>
              {!props.dealer?.dachseite &&
              props?.CompanyInformationPrivate?.showInKitchenCompass ? (
                <a
                  href={"https://www.kuechenkompass.com/"}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img src={"/images/kompass.png"} alt={"Küchenkompass"} />
                </a>
              ) : null}
            </InKitchenCompass>
          </LeftContent>
          <RightContent>
            {props.dealer?.dachseite ? (
              <DachseiteSearch></DachseiteSearch>
            ) : (
              <FurtherAwards />
            )}
          </RightContent>
        </ContentWrapper>
        {richLink?.map((richLinkItem, ind) => {
          if (richLinkItem?.richDesc?.content[0]?.type === "heading") {
            console.log(` richLinkItem?.linkTo`, richLinkItem?.linkTo);
            const link = getLinkFromConnection(richLinkItem?.linkTo);
            console.log(` link`, link);
            return (
              <RichLinkAnchorWrapper key={`richlink-${ind}`}>
                <RichLinkAnchor {...link}>
                  <RichLinkImage
                    src={richLinkItem?.image?.[0].src}
                  ></RichLinkImage>
                  <RichLinkImageText>
                    <div>
                      <h3>{richLinkItem?.headline}</h3>
                      <h4>
                        {richLinkItem.richDesc.content[0].content[0]?.text}
                      </h4>
                    </div>
                  </RichLinkImageText>
                </RichLinkAnchor>
              </RichLinkAnchorWrapper>
            );
          }
          return (
            <RichLinkWrapper key={`richlink-${ind}`}>
              <RichLinkContent src={richLinkItem?.image?.[0].src}>
                <RichLinkContainer>
                  <div>
                    <h3>{richLinkItem?.headline}</h3>
                    <a href={richLinkItem?.linkTo?.[0]?.slug}>
                      <span>mehr</span>
                    </a>
                  </div>
                </RichLinkContainer>
              </RichLinkContent>
              <RichTextWrapper text={richLinkItem?.richDesc && true}>
                <RichTextGeneral textContent={richLinkItem?.richDesc} />
              </RichTextWrapper>
            </RichLinkWrapper>
          );
        })}
      </SLandingContainer>
      <SLandingContainer>
        <NewsWrapper>
          <NewSlider />
        </NewsWrapper>
        {isNewsCustom && (
          <NewsWrapper>
            <NewSlider newsCustom={isNewsCustom} />
          </NewsWrapper>
        )}
      </SLandingContainer>
      {props.dealer?.dachseite ? (
        <SLandingContainer>
          <MusterkuechenLink />
        </SLandingContainer>
      ) : null}
      <SLandingContainer>
        <BannerHorizontalWrapper>
          <LogoBannerHorizontal shadowOverlayVisible={true} />
        </BannerHorizontalWrapper>
      </SLandingContainer>
    </SectionWrapper>
  );
};

export default LandingContent;
