import styled from "styled-components";

import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";

export const Wrapper: any = styled.div<{isLast: boolean}>`
    ${SSlickBase};
    ${SSlickTheme};
    margin-bottom: 20px;
    background-color: rgb(255, 209, 97);
    padding-bottom: 25px;
    ${({ isLast }) => isLast && "margin-top: -19px;"}
    .slick-dots {
        bottom: -20px;
        li button:before {
          // background-color: #d0c5c8;
          border-radius: 50%;
        }
    }
`;
