import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: auto;
  color: #85b2c3;
  p {
    span {
      font-family: "sofia_pro_extralightregular",Helvetica,Arial,sans-serif;
      font-weight: 300;
      font-size: 15px;
      box-sizing: border-box;
      line-height: 21px;
    }
  }
  p:first-child {
    margin-bottom: 21px;
  } 

 `;

export const mainText: any = css`
    width: 100%;
`;

export const RichLinkWrapper: any = styled.div<{third: boolean; half: boolean; full: boolean; src: string; withOutImage: boolean}>`
  width: 100%;
  height: 475px;
  margin: 1px;

  div.parent {
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  div.rich-link-with-out-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e67c19;
    height: 475px;
  }

  div.rich-link-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('${({ src }) => src}');
    ${({ third }) => third && "background-size: 200% 100%"};
    ${({ half }) => half && "background-size: 100% 100%"};
    ${({ full }) => full && "background-size: 100% 230%"};
    background-position: center;
    object-fit: cover;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    height: 475px;
  }

  div.rich-link-content:before { 
    filter: grayscale(50%) brightness(10%);
    background-color: rgba(76,61,66,0.45);
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  } 

  .rich-link-content:hover, .rich-link-content:focus  {
    ${({ third }) => third && "background-size: 240% 120%"};
    ${({ half }) => half && "background-size: 120% 120%"};
    ${({ full }) => full && "background-size: 120% 286%"};
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    ${({ third }) => third && "width: 33.1%"};
    ${({ half }) => half && "width: 49.8%"};
    ${({ full }) => full && "width: 100%"};
  }
  
  @media (${BREAKPOINTS_DM.diamond_1920}) {
    height: 533.594px;
  
    div.rich-link-with-out-image {
      height: 533.594px;
    }
    div.rich-link-content {
      height: 533.594px;
    }
  }
`;

export const RichLinkContainer = styled.div`
  z-index: 9;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  font-size: 13px;
  div.button {
    margin: 10px auto 0;
    padding: 12px 18px;
    border: 2px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    width: fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    span {
      letter-spacing: 1px;
      line-height: 22px;
      color: #fff;
      font-size: 13px;
      font-weight: 100;
    }
    &:hover {
      background-color: #fff;
      span {
        color: #e67c19;
      }
    }
  }
  div.content {
    padding: 0 15px;
    text-align: center;

    > h3 {
      font-size: 30px;
      line-height: 42px;
      padding: 5px 0 20px;
      font-weight: 300;
      padding-bottom: 20px;
      color: #fff;
    }

    > a {
      margin: 10px auto 0;
      padding: 12px 18px;
      background: #e67c19;
      border-radius: 2px;
      color: #fff;
      font-size: 13px;
      width: fit-content;
      display: flex;
      align-items: center;
      text-align: center;
      span {
        letter-spacing: 1px;
        line-height: 22px;
        color: #fff;
        font-size: 13px;
        font-weight: 100;
      }
      &:hover {
        background-color: #fa902d;
      }
    }
  }
`;

export const RichTextWrapper: any = styled.div<{text: boolean}>`
  width: fit-content;
  margin: ${({ text }) => text ? "-25px auto 0" : "0 auto"};
`;

export const RichLinkSection: any = styled.div`
  margin: 1px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const Bottom: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
  background-color: #e67c19;
  div.left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      margin: 5px 0 10px;
      text-size-adjust 100%

    }
  }
  div.right-side {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a.button-bottom {
      border: 2px solid #fff;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 16px;
      padding: 17px 25px;
      color: #fff;
      &:hover {
        color: #e67c19;
        background-color: #fff;
      }
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    div.left-side { 
      width: 60%;
      padding-left: 50px;
      justify-content: flex-start;
    }
    div.right-side {
      width: 40%;
    }
  }
`;
