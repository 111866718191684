import React, { FC, useContext, Fragment } from "react";
import {
  Context,
  LogoBannerHorizontal,
  ITextElement,
  IAsset,
  ISlider
} from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";

import { Section } from "../../../BaseComponents/Grid";
import Img from "../../../BaseComponents/Img";
import NewSlider from "../../common/NewSlider";
import { RichTextGeneral } from "../../common/RichText";
import TeaserRight from "../../common/TeaserRight";
import * as styles from "./LandingContent.style";
import {
  FurterAwards,
  FurterAwardsContent,
} from "./LandingContent.style";

const Hero: FC = () =>
{
  const props = useContext(Context);
  const imgArray = props.PageLanding?.elements?.filter(e => e?.__typename === "Asset") as IAsset[];
  const slider = props.PageLanding?.elements?.filter(e => e?.__typename === "Slider") as ISlider[];
  let imageOrGallery;
  if(slider && !!slider?.[0]?.elements)
  {
    imageOrGallery = <FadeSlider elements={slider?.[0]?.elements} />;
  }
  else if(imgArray?.[0])
  {
    if(imgArray.length > 1)
    {
      imageOrGallery = <FadeSlider elements={[...imgArray?.map(f => f)]} />;
    }
    else if(imgArray?.length > 1)
    {
      imageOrGallery = <FadeSlider elements={imgArray} />;
    }
    else
    {
      imageOrGallery = (
        <Img
          alt={imgArray?.[0]?.title}
          src={imgArray}
          height={449}
          quality={1080} />
      );
    }
  }
  return imageOrGallery ?? null;
};

const LandingContent: FC = () =>
{
  const props = useContext(Context);
  const awards = props?.CompanyInformationPrivate?.furtherAwards;
  const isNewsCustom = props?.PageLanding?.NewsCustom.length > 0;

  return (
    <>
      <div css={styles.wrapper}>
        <Section>
          <div css={styles.heroWrapper}>
            <Hero />
          </div>
          <div css={styles.contentWrapper}>
            <div css={styles.leftContent}>
              {(props.PageLanding?.elements?.filter(e => e?.__typename === "TextElement") as ITextElement[])?.map(
              (t, i) => (
                <RichTextGeneral
                  key={`rt0-${i}`}
                  textContent={t.text}
                  css={styles.mainText}
                >
                  <h1>{t?.headline}</h1>
                </RichTextGeneral>
              )
            )}
              {awards && (
                <FurterAwards>
                  {awards.map((item, index) => (
                    <FurterAwardsContent key={`furter-awards-${index}`}>
                      <Img
                        alt={item.title}
                        src={[{ src: item.src }]}
                        quality={1080} />

                    </FurterAwardsContent>
               
                  ))}
                </FurterAwards>
              )} 
            </div>
            
            <div css={styles.sideBar}>
              <TeaserRight />
              <NewSlider />
              {isNewsCustom && <NewSlider newsCustom={isNewsCustom}/>}
            </div>

          </div>
        </Section>
        <div css={styles.bannerHorizontal}>
          <LogoBannerHorizontal shadowOverlayVisible={true} />
        </div>
      </div>
    </>
  );
};

export default LandingContent;
