import React, { FC, useContext } from "react";
import { Context, ITextElement, IRichLinkItem } from "src/common";

import FurtherAwards from "../../common/FurtherAwards";
import { LogoBannerHorizontal } from "../../common/LogoBannerHorizontal";
import NewSlider from "../../common/NewSlider";
import { ProductLandingBanner } from "../../common/ProductLandingBanner";
import { RichTextGeneral } from "../../common/RichText";
import Search from "../../common/Search/Search";
import Hero from "./Hero";
import * as styles from "./LandingContent.style";
import {
  SectionWrapper,
  BannerHorizontalWrapper,
  ContentWrapper,
  LeftContent,
  RightContent,
  RichLiksTextWrapper,
  RichLinkContainer,
  RichLinkWrapper,
  RichTextWrapper,
  ProductLandingBannerWrapper,
  LinksContentWrapper,
} from "./LandingContent.style";
import TeaserBanner from "../../common/TeaserBanner/TeaserBanner";

const LandingContent: FC = () => {
  const props = useContext(Context);
  const { PageLanding: page } = props;
  const richLink = page?.elements?.filter(
    (e) => e?.__typename === "RichLinkItem"
  ) as IRichLinkItem[];
  // props.AllBrand.map(item => console.log(item.logo[0].src));
  const isNewsCustom = page?.NewsCustom?.length > 0;
  return (
    <SectionWrapper>
      <Hero />
      <ProductLandingBannerWrapper>
        <ProductLandingBanner />
      </ProductLandingBannerWrapper>
      <ContentWrapper>
        <LeftContent>
          {(
            props.PageLanding?.elements?.filter(
              (e) => e?.__typename === "TextElement"
            ) as ITextElement[]
          )?.map((t, i) => (
            <RichTextWrapper key={`rt0-${i}`}>
              <RichTextGeneral
                key={`rt0-${i}`}
                textContent={t.text}
                css={styles.mainText}
              >
                <h1>{t?.headline}</h1>
              </RichTextGeneral>
            </RichTextWrapper>
          ))}
        </LeftContent>
        <RightContent>
          <FurtherAwards />
          <Search />
        </RightContent>
      </ContentWrapper>
      <LinksContentWrapper>
        {richLink?.map((richLinkItem, ind) => (
          <RichLinkWrapper key={`richlink-${ind}`}>
            <img src={richLinkItem?.image?.[0]?.src} />
            <RichLinkContainer>
              <div>
                <a
                  href={richLinkItem?.linkTo[0]?.slug}
                  className="contentWrapper"
                >
                  <h3>{richLinkItem?.headline}</h3>
                </a>
                {richLinkItem?.linkTo[0]?.slug && (
                  <a className="link" href={richLinkItem?.linkTo[0]?.slug}>
                    <RichLiksTextWrapper text={richLinkItem?.richDesc && true}>
                      <RichTextGeneral textContent={richLinkItem?.richDesc} />
                    </RichLiksTextWrapper>
                    <span>weiter</span>
                  </a>
                )}
              </div>
            </RichLinkContainer>
          </RichLinkWrapper>
        ))}
        <TeaserBanner />
      </LinksContentWrapper>
      <NewSlider />
      {isNewsCustom && <NewSlider newsCustom={isNewsCustom} />}
      <BannerHorizontalWrapper>
        <LogoBannerHorizontal />
      </BannerHorizontalWrapper>
    </SectionWrapper>
  );
};

export default LandingContent;
