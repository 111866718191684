import * as React from "react";
import { getInitialPropsLanding, Context, DEFAULT_TEMPLATE } from "src/common";
import { PageLandingT1 } from "src/templates/t1/components/pages/PageLanding";
import { PageLandingT3 } from "src/templates/t3/components/pages/PageLanding";
import { PageLandingT4 } from "src/templates/t4/components/pages/PageLanding";
import { PageLandingT5 } from "src/templates/t5/components/pages/PageLanding";
import { PageLandingT6 } from "src/templates/t6/components/pages/PageLanding";
import { PageLandingT7 } from "src/templates/t7/components/pages/PageLanding";
import { PageLandingT8 } from "src/templates/t8/components/pages/PageLanding";
import { PageLandingT9 } from "src/templates/t9/components/pages/PageLanding";
import { PageLandingT10 } from "src/templates/t10/components/pages/PageLanding";
import { PageLandingT11 } from "src/templates/t11/components/pages/PageLanding";
import { PageLandingT17 } from "src/templates/t17/components/pages/PageLanding";
import { PageLandingT12 } from "src/templates/t12/components/pages/PageLanding";
import { PageLandingT13 } from "src/templates/t13/components/pages/PageLanding";
import { PageLandingT14 } from "src/templates/t14/components/pages/PageLanding";
import { PageLandingT15 } from "src/templates/t15/components/pages/PageLanding";
import { PageLandingT18 } from "src/templates/t18/components/pages/PageLanding";
import { PageLandingT19 } from "src/templates/t19/components/pages/PageLanding";
import { PageLandingT16 } from "src/templates/t16/components/pages/PageLanding";
import { PageLandingT20 } from "src/templates/t20/components/pages/PageLanding";
import { PageLandingT21 } from "src/templates/t21/components/pages/PageLanding";
import { PageLandingT22 } from "src/templates/t22/components/pages/PageLanding";
import { PageLandingT23 } from "src/templates/t23/components/pages/PageLanding";
import { PageLandingT24 } from "src/templates/t24/components/pages/PageLanding";
import { PageLandingT25 } from "src/templates/t25/components/pages/PageLanding";
import { PageLandingT26 } from "src/templates/t26/components/pages/PageLanding";
import { FullWidthBanner } from "../common/components/FullWidthBanner/FullWidthBanner";
import { CustomHtmlHandler } from "../common/components/CustomHtmlHandler/CustomHtmlHandler";
import { getPageType } from "../common/utils/getPageType";
import { PageLandingT27 } from "../templates/t27/components/pages/PageLanding";
import { PageBrandOverviewT0 } from "../templates/t0/components/pages/PageBrandOverview";

const LandingPage = ({ template }) => {
  if (template) {
    switch (template) {
      case "t0":
        return <PageBrandOverviewT0 />;
      case "t27":
        return <PageLandingT27 />;
      case "t26":
        return <PageLandingT26 />;
      case "t25":
        return <PageLandingT25 />;
      case "t23":
        return <PageLandingT23 />;
      case "t24":
        return <PageLandingT24 />;
      case "t22":
        return <PageLandingT22 />;
      case "t21":
        return <PageLandingT21 />;
      case "t20":
        return <PageLandingT20 />;
      case "t19":
        return <PageLandingT19 />;
      case "t18":
        return <PageLandingT18 />;
      case "t17":
        return <PageLandingT17 />;
      case "t16":
        return <PageLandingT16 />;
      case "t15":
        return <PageLandingT15 />;
      case "t14":
        return <PageLandingT14 />;
      case "t10":
        return <PageLandingT10 />;
      case "t13":
        return <PageLandingT13 />;
      case "t12":
        return <PageLandingT12 />;
      case "t11":
        return <PageLandingT11 />;
      case "t9":
        return <PageLandingT9 />;
      case "t8":
        return <PageLandingT8 />;
      case "t7":
        return <PageLandingT7 />;
      case "t6":
        return <PageLandingT6 />;
      case "t5":
        return <PageLandingT5 />;
      case "t4":
        return <PageLandingT4 />;
      case "t3":
        return <PageLandingT3 />;
      default:
        return <PageLandingT1 />;
    }
  }
  return null;
};

const landingPage = (props): React.ReactElement => {
  props = {
    ...props,
    Settings: {
      ...props.Settings,
    },
  };

  let template = props.Settings?.template || DEFAULT_TEMPLATE;

  if (typeof window !== "undefined") {
    console.log(` props`, { props });
    console.log(
      ` template=${props.Settings?.template} pageType=${getPageType(props)}`
    );

  }
  return (
    <Context.Provider value={props as any}>
      <CustomHtmlHandler {...props} />

      {/* <FullWidthBanner {...props} />
            <LandingPage template={template}></LandingPage> */}
      <FullWidthBanner {...props}>
        <LandingPage template={template}></LandingPage>
      </FullWidthBanner>
    </Context.Provider>
  );
};

export const getServerSideProps = getInitialPropsLanding;

export default landingPage;
