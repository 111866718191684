import React, { FunctionComponent, useContext } from "react";
import { Img, Context } from "src/common";

import { Wrapper, ImageWrapper, BorderTop, BorderBottom, ContentWrapper } from "./LogoBannerHorizontal.style";

const LogoBannerHorizontal: FunctionComponent<any> = () =>
{
  const props = useContext(Context);
  const allBrands = props.AllBrand;
  if(allBrands.length <= 0)
  {
    return null;
  }


  const shuffledBrands = allBrands.filter(logo => !!logo?.logo?.[0]?.src ).sort(() => 0.5 - Math.random());
  const selectedBrands = shuffledBrands.length <= 12 ? shuffledBrands : shuffledBrands.slice(0, 12);

  return (
    <>
      <BorderTop/>
      <Wrapper>
        <ContentWrapper>
          {selectedBrands.map((logo, ind) =>
            <ImageWrapper key={ind}>
              <img src={logo?.logo?.[0]?.src} alt={logo?.name || logo?.logo?.[0].description } />
            </ImageWrapper>
          )}
        </ContentWrapper>

      </Wrapper>
      <BorderBottom/>
    </>
  );
};

export default LogoBannerHorizontal;
