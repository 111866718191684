import { createContext, FC, useContext, useState } from "react";

export interface IModalProviderContext {
    activeModals: string[];
    setModalActive: (value: string) => void;
    setModalInactive: (value: string) => void;
}


const ModalProviderContext = createContext<any>({});

export const ModalProvider = ({ children }) => {
  const [activeModals, setActiveModals] = useState<string[]>([]);

  const setModalActive = (modalName: string) => {
    setActiveModals([...activeModals, modalName]);
  }

  const setModalInactive = (modalName: string) => {
    setActiveModals(activeModals.filter((item) => item !== modalName));
  }

  return (
    <ModalProviderContext.Provider
    value={{
      activeModals,
      setModalActive,
      setModalInactive
    }}
    >
      {children}
    </ModalProviderContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalProviderContext) as IModalProviderContext;
};