import React, { FC, Fragment, useContext, useState } from "react";
import { Context, ITextElement } from "src/common";
import { css } from "styled-components";
import Spacer from "../../../../../common/BaseComponents/Spacer/Spacer";
import UniversalComponentsRenderer from "../../../../../common/BaseComponents/UniversalCompoentRenderer/UniversalComponentsRenderer";

import { Section } from "../../../BaseComponents/Grid";
import Footer from "../../common/Footer";
import FurtherAwards from "../../common/FurtherAwards";
import Header from "../../common/Header";
import NewSlider from "../../common/NewSlider";
import PromotionalOffer from "../../common/PromotionalOffer";
import { RichTextGeneral } from "../../common/RichText";
import Hero from "./Hero";
import * as styles from "./LandingContent.style";
import {
  WhiteStripe,
  FurterAwardsWrapper,
  Wrapper,
  HeroWrapper,
  Image1,
  Image2,
  ContentTextWrapper,
  TextContent,
  IconWrapper,
  PhoneNumberWrapper,
  PhoneNumberContent,
  TitleWrapper,
  ContactUsWrapper,
  ContactUsContent,
  ContactUsContentCard,
  Icon,
  TextAndButton,
  AddressForm,
  ContentWrapper,
} from "./LandingContent.style";
import LogoBannerHorizontal from "../../common/LogoBannerHorizontal";

const LandingContent: FC = () => {
  const props = useContext(Context);
  const awards = props?.CompanyInformationPrivate?.furtherAwards;
  const [promotionalOffer] = props?.PageLanding?.promotionalOffer;
  const phoneNumber = props?.CompanyInformationPublic?.phone;
  const [isMenuActive, toggleMenuActive] = useState(false);
  const isNewsCustom = props?.PageLanding?.NewsCustom?.length > 0;
  const showInKitchenCompass =
    !!props.CompanyInformationPrivate?.showInKitchenCompass;

  return (
    <Wrapper onClickCapture={() => toggleMenuActive(false)}>
      <Header
        onToggleMenuActive={toggleMenuActive}
        isMenuActive={isMenuActive}
      />
      <Spacer bronze={125}></Spacer>
      <HeroWrapper>
        <Hero />
        <div className="middle" />
      </HeroWrapper>

      <Section>
        <ContentTextWrapper>
          <PromotionalOffer promotionalOffer={promotionalOffer} />
          <ContentWrapper row>
            <Image1 />
            <TextContent>
              <IconWrapper>
                <img src="/images/h1_pointer_t21.png" />
              </IconWrapper>

              {props.PageLanding?.elements?.map((element: any, i: number) => {
                switch (element.__typename) {
                  case "TextElement":
                    return (
                      <Fragment key={`${element.id}${i}`}>
                        <RichTextGeneral
                          textContent={element.text}
                          css={styles.mainText}
                        >
                          <h1>{element?.headline}</h1>
                        </RichTextGeneral>
                      </Fragment>
                    );
                  case "HtmlEmbedding":
                    return (
                      <div key={`${element.id}${i}`}>
                        <UniversalComponentsRenderer
                          items={[element]}
                        ></UniversalComponentsRenderer>
                      </div>
                    );
                  default:
                    return null;
                }
              }) ?? null}
              {showInKitchenCompass ? (
                <a href="https://www.kuechenkompass.com" target="_blank">
                  <img
                    style={{ marginTop: 20 }}
                    src="/images/kk.png"
                    alt="empfohlen von Küchenkompass"
                  />
                </a>
              ) : null}
            </TextContent>
            {props.PageContact?.slug && (
              <Image2>
                <a href={`/${props.PageContact?.slug}`} />
              </Image2>
            )}
          </ContentWrapper>
        </ContentTextWrapper>
      </Section>

      <WhiteStripe>
        <ContentWrapper>
          <PhoneNumberWrapper>
            <PhoneNumberContent>
              <h1>
                Haben Sie Fragen? <br /> Rufen Sie uns an.
              </h1>
              <h3>{props?.CompanyInformationPublic?.phone}</h3>
            </PhoneNumberContent>
            <img src="/images/callus.png"></img>
          </PhoneNumberWrapper>
        </ContentWrapper>
      </WhiteStripe>

      <Section>
        {awards && awards.length >= 1 ? (
          <FurterAwardsWrapper>
            <ContentWrapper>
              <TitleWrapper>
                <img src="/images/h1_pointer_t21.png" />
                <h1> Auszeichnungen</h1>
              </TitleWrapper>
              <div className="spacer" />
              <FurtherAwards awards={awards} />
            </ContentWrapper>
          </FurterAwardsWrapper>
        ) : null}
      </Section>

      <WhiteStripe>
        <Section>
          <TitleWrapper>
            <img src="/images/h1_pointer_t21.png" /> <h1>Küchennachrichten</h1>
          </TitleWrapper>

          <NewSlider />
          {isNewsCustom ? <NewSlider newsCustom={isNewsCustom} /> : null}
        </Section>
      </WhiteStripe>

      <div>
        <div
          css={css`
            background-color: white;
          `}
        >
          <Section>
            <TitleWrapper>
              <img src="/images/h1_pointer_t21.png" />
              <h1> Unsere Marken</h1>
            </TitleWrapper>
          </Section>
        </div>
        <div css={styles.bannerHorizontal}>
          <LogoBannerHorizontal shadowOverlayVisible={true} />
        </div>
      </div>

      <WhiteStripe>
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />

        <Section>
          <TitleWrapper dark>
            <img src="/images/h1_pointer_t21.png" />
            <h1> Wir sind für Sie da</h1>
          </TitleWrapper>
          <div className="spacer" />
          <ContactUsWrapper>
            <ContactUsContent>
              <ContactUsContentCard>
                <Icon>
                  <i className="fa fa-exclamation-circle" />
                </Icon>
                <TextAndButton>
                  <h2>Der Weg zur neuen Küche.</h2>
                  {props.PageContact?.slug && (
                    <a href={`/${props.PageContact?.slug}`}>
                      <i className="fas fa-arrow-right" /> {"SO GEHT'S"}
                    </a>
                  )}
                </TextAndButton>
              </ContactUsContentCard>
            </ContactUsContent>

            <ContactUsContent>
              <ContactUsContentCard>
                <Icon>
                  <i className="fa fa-phone-alt" />
                </Icon>
                <h2>
                  Rufen Sie uns an. <br /> {phoneNumber}
                </h2>
              </ContactUsContentCard>
            </ContactUsContent>

            <ContactUsContent>
              <ContactUsContentCard>
                <Icon>
                  <i className="fa fa-map-marker-alt" />
                </Icon>
                <TextAndButton>
                  <h2>So finden Sie uns.</h2>
                  <AddressForm>
                    <input type="text" placeholder="Ihre PLZ/Ort"></input>
                    {props.PageContact?.slug && (
                      <a href={`/${props.PageContact?.slug}`}>
                        <i className="fas fa-arrow-right" /> ROUTE BERECHNEN
                      </a>
                    )}
                  </AddressForm>
                </TextAndButton>
              </ContactUsContentCard>
            </ContactUsContent>
          </ContactUsWrapper>
        </Section>
      </WhiteStripe>
      <Footer />
    </Wrapper>
  );
};

export default LandingContent;
