import React, { FunctionComponent, useContext } from "react";
import { Context, LandingBannerLeft, IAsset } from "src/common";
import { ProductLandingBanner } from "src/common/components/ProductLandingBanner";

import { Banner } from "../../common/Banner/Banner";
import { FooterT3 } from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import LandingImage from "../../common/landingImage/LandingImage";
import * as styles from "./Landing.page.style";
import { ProductLandingBannerWrapper } from "./Landing.page.style";
import PlanningDateModal from "../../common/PlanningDateModal/PlanningDateModal";
import { SiteContent } from "./siteContent/SiteContent";

const PageLandingT3: FunctionComponent = () => 
{
  const props = useContext(Context);
  const palette = props?.Settings;
  const landingImage = props?.PageLanding?.elements?.find(e => e?.__typename === "Asset") as IAsset;
  return (
    <div css={styles.landingWrapper}>
      <div>
        <Header />
      </div>
      <ProductLandingBannerWrapper 
        background={palette.themeBackgroundColourIsland}
        titleColor={palette.themeTextColourIsland}
        color={palette.themeTextColor}
        primary={palette.themeColorPrimary}
      >
        <ProductLandingBanner />
      </ProductLandingBannerWrapper>
      <div>
        <Banner />
      </div>
      <div>
        <PlanningDateModal />
      </div>
      <div>
        <LandingBannerLeft />
      </div>
      <div>{landingImage && <LandingImage img={landingImage?.src} />}</div>
      <div>
        <SiteContent />
      </div>
      <FooterT3 />
    </div>
  );
};

PageLandingT3.displayName = "PageLandingT3";
export { PageLandingT3 };
